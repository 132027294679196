import React, {useState, useEffect} from "react";
import {Button, Col, Collapse, Form, Input, Layout, Row, Spin, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";


import {successAlert, errorAlert, confirmAlert} from "../../utils/helper";
import {
    useGetPageQuery,
    useCreatePageMutation,
    useUpdatePageMutation,
    useDeletePageDetailMutation,
} from '../../redux/api/services/page';
import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import DetailAction from "./detailAction";


const PageAction = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [showModel, setShowModel] = useState(false);
    const [action, setAction] = useState(params.action);
    const [detailAction, setDetailAction] = useState(null);
    const [id, setId] = useState(params.id);
    const [details, setDetails] = useState([]);
    const [detail, setDetail] = useState(null);
    const [form] = Form.useForm();
    const [loadingDelete, setLoadingDelete] = useState([]);


    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreatePageMutation();
    const [update] = useUpdatePageMutation();
    const [remove, {isLoading: loading}] = useDeletePageDetailMutation();



    const {data, isLoading: loadingPage, error} = useGetPageQuery(id, {
        skip: action === 'add'
    });

    function saveAction(action, detail = null) {
        setDetail(detail);
        setDetailAction(action);
    }

    function resetAction(c) {
        setShowModel(c);
        setDetailAction(null);
    }


    const deletePageDetail = (detailId) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove({id : detailId, pageId : id });
                    successAlert('Deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    const onFinish = async (data) => {

        try {
            let response = null;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: id, data}).unwrap();
            successAlert(response?.message);

            navigate('/page');

        } catch (e) {
            errorAlert(e);
        }
    }

    const genExtra = (item) => (
        <Row gutter={[20, 10]} align={'middle'}>
            <Col span={6}>
               {item.id}
            </Col>
            <Col span={6}>
                <EditOutlined
                    onClick={(event) => {
                        event.stopPropagation();
                        setDetail(item);
                        setDetailAction('edit')
                    }}
                />
            </Col>
            <Col span={6}>
                <DeleteOutlined
                    loading={loadingDelete[item.id] ?? false}
                    onClick={() => deletePageDetail(item.id)}
                />
            </Col>
        </Row>
    );


    useEffect(() => {
        if (detailAction)
            setShowModel(true);
        else
            setShowModel(false);
    }, [detailAction]);

    useEffect(() => {
        if (data && id) {
            form.setFieldsValue(data.data);
            setAction(params.action);
            setId(params.id);
            setDetails(data.data.details);
        }
    }, [data]);


    return (
        <Layout.Content className="container">
            <Typography.Title level={3}>Page</Typography.Title>

            <Form form={form} onFinish={onFinish} layout="vertical">
                <Spin spinning={loadingPage}>

                    <Row gutter={[10, 10]}>
                        <Form.Item name="id" hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item name="slug" label="Slug" className={id ? "event-none" : ""} >
                                <Input placeholder="Slug"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="name" label="Name" className={id ? "event-none" : ""}>
                                <Input placeholder="Name"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>

                            <Button key="sub" type="primary"
                                    onClick={() => saveAction('add')}>
                                Add Detail
                            </Button>
                        </Col>

                        <Col span={24}>
                            <Collapse>
                                {details.map((item, index) => {
                                    return (
                                        <Collapse.Panel header={item.title} key={index} extra={genExtra(item)}>
                                            <div dangerouslySetInnerHTML={{__html: item?.description}}>
                                            </div>
                                        </Collapse.Panel>)

                                })
                                }
                            </Collapse>
                        </Col>
                    </Row>

                    {detailAction !== null &&
                        <DetailAction pageId={id} open={showModel} action={detailAction} detail={detail}
                                      onClose={resetAction}/>}

                </Spin>
            </Form>
        </Layout.Content>
    );
}

export default PageAction;
