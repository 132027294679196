import {api} from "../index"

const path = "/auth"

const AuthService = api.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (data) => ({
                url: `${path}/register`,
                method: "POST",
                body: data,
            }),
        }),
        login: builder.mutation({
            query: (data) => ({
                url: `${path}/login`,
                method: "POST",
                body: data,
            }),
        }),
        me: builder.query({
            query: () => ({
                url: `${path}/me`,
            }),
            providesTags: ["User"],
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${path}/logout`,
                method: "POST",
            }),
        }),
    }),
})

export const {useRegisterMutation, useLoginMutation, useMeQuery, useLogoutMutation} = AuthService
