import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Select, Skeleton, Table, Typography, DatePicker} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";


import {
    useSellerSalesQuery,
    useDownloadTopSellerMutation,
} from '../../redux/api/services/dashboard';
import {useListSellerQuery} from "../../redux/api/services/seller";

import SearchWidget from "../../widgets/SearchWidget";
import {
    convertTableParams,
    formatPrice,
    lastRangePresetsArray,
    displayDateFormat,
    convertDayjsToDate, errorAlert
} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";
import AddWidget from "../../widgets/AddWidget";


const {RangePicker} = DatePicker;

const TopSeller = () => {

    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [sellerOptions, setSellerOptions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [date, setDate] = useState(null);

    const [tableParams, setTableParams] = useState({
        callApi: false,
        searchOne: null,
        pagination: {
            current: 1,
            pageSize: 10
        },
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchOrders
    } = useSellerSalesQuery(convertTableParams(tableParams));


    const {
        data: sellers, isLoading: loadingSellers, error: errorSellers, isFetching: fetchingSellers,
    } = useListSellerQuery();

    const [downloadTopSeller] = useDownloadTopSellerMutation();


    const columns = [
        {
            title: 'Id',
            dataIndex: ['seller', 'id'],
            key: 'seller.id',
            align: "center",
        },
        {
            title: 'Seller',
            dataIndex: ["seller", "name"],
            key: "seller.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.seller?.image ? <Avatar src={record?.seller?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.seller?.name}</p>
                        <p>{record?.seller?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (v, record) => {
                return <span>{formatPrice(v)}</span>
            }
        }
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            searchOne: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {

        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };


    const downloadCSV = () => {

        let dlData = {};
        if (date !== null) {
            dlData = convertFromToDate(date);
        }

        downloadTopSeller(dlData)
            .unwrap()
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'top-seller.csv');
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                errorAlert(err?.data?.error)
            })
    }



    const changeSeller = (value) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            filters: {
                seller_id: value,
            },
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const convertFromToDate = (date) => {

        const filterBW = {};
        if (date) {
            const termStartTimestamp = [];
            date.forEach((item, index) => {
                termStartTimestamp[index] = convertDayjsToDate(item);
            });
            filterBW['created_at'] = termStartTimestamp;
        }

        return filterBW;
    }

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);



    useEffect(() => {
        if (data) {
            setOrders(data.data.sales_reports);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (sellers) {
            setSellerOptions(sellers.data.sellers.map(seller => ({
                label: seller.name,
                value: seller.id
            })));
        }
    }, [sellers]);

    useEffect(() => {

        setTableParams({
            ...tableParams,
            callApi: true,
            filtersBW: convertFromToDate(date),
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });


    }, [date]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchOrders();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Top Sellers</Typography.Title>

            <Row>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'seller'} label={'Select Seller'}>
                        {
                            loadingSellers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select showSearch optionFilterProp="label"
                                        placeholder="Seller Name" allowClear options={sellerOptions}
                                        onChange={changeSeller}/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Date"}>
                        <RangePicker presets={lastRangePresetsArray} value={date}
                                     onChange={(v) => setDate(v)}
                                     format={displayDateFormat}
                                     disabledDate={(current) => current && current > dayjs().endOf('day')}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <AddWidget onClick={() => downloadCSV()} label='Download CSV'/>
                </Col>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>


            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={orders}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default TopSeller;
