import {Col, Layout, Spin, Form, List, Avatar, Row} from 'antd'
import React from 'react'
import {convertDateTime} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";


const AccountInformation = ({data}) => {


    return (
        <Layout.Content className='container' style={{padding: '0px'}}>
            <Spin spinning={false}>
                <div className='accountInformCtr accountInformCtrTab'>
                    <Row>
                        <Col span={18}>
                            <List
                                itemLayout="horizontal"
                                dataSource={[{
                                    name: data?.name,
                                    email: data?.email,
                                    image: data?.image
                                }
                                ]}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                item.image ? <Avatar size={60} src={item.image}/> :
                                                    <Avatar size={60} icon={<UserOutlined/>}/>
                                            }
                                            title={<a href="#">{item.name}</a>}
                                            description={item.email}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>

                    <p>Mobile : {data?.mobile} </p>
                    <p>Status : {data?.status ? 'Active' : 'Inactive'} </p>
                    <p>Mail verified : {data?.email_verified_at ? 'Yes' : 'No'}</p>
                </div>
            </Spin>
        </Layout.Content>
    )
}

export default AccountInformation
