import {api} from "../index";

const path = "/sellers";


const SellerService = api.injectEndpoints({
    endpoints: (builder) => ({
        listSeller: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Seller']
        }),
        getSeller: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
            providesTags : ['Seller']
        }),
        createSeller: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Seller']
        }),
        updateSeller: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Seller']
        }),
        patchSeller: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Seller']
        }),
        paypalDisconnect: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}/disconnect`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Seller']
        }),
        paypalPartner: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: {
                    paypal_partner: true
                },
            }),
            invalidatesTags: ['Seller']
        }),
        deleteSeller: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Seller']
        }),
        listSellerListing: builder.query({
            query: (id, filters = '') => `${path}/${id}/listings?${filters}`,
        }),
    }),
});


export const {
    useListSellerQuery,
    useGetSellerQuery,
    useCreateSellerMutation,
    useUpdateSellerMutation,
    usePatchSellerMutation,
    usePaypalPartnerMutation,
    useDeleteSellerMutation,
    usePaypalDisconnectMutation,
} = SellerService;



