import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    }
});



const baseQueryWithReAuth = async (args, api, extraOptions = {}) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result?.meta?.response) {
        if (result?.error?.status === 401 || result?.error?.status === 403) {
            window.location.href = `${window.location.origin}/login`;
        }
        return result;
    }
    return result;

};



export const api = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Address','Seller', 'Banner', 'Category', 'Size', 'Style', 'Appearance', 'Measurement', 'Designer', 'Plan', "Masters", 'CategoryDetail', 'NewsLetter', 'Slider', 'Testimonial'],
    endpoints: (builder) => ({}),
});
