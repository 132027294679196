import {api} from "../index";

const path = "/files";


const FileService = api.injectEndpoints({
    endpoints: (builder) => ({
        uploadFile: builder.mutation({
            query: (data) => ({
                url: `${path}/upload`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});


export const {
    useUploadFileMutation,
} = FileService;
