import React from "react";
import { Avatar, Button, Card, List, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { UserOutlined, RightOutlined } from "@ant-design/icons";

const SellerThreshold = ({ data, loading }) => {

    const navigate = useNavigate();


    return (
        <Card style={{ flex: 1,border: '1px solid #d9d9d9' }} loading={loading}>
            <Typography.Text strong>Seller Threshold</Typography.Text>

            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                item?.seller?.image ? <Avatar src={item?.seller?.image} /> :
                                    <Avatar icon={<UserOutlined />} />
                            }
                            title={<a href="#">{item?.seller?.name}</a>}
                            description={item?.seller?.email}
                        />
                        <div>{item.count} Listings</div>
                    </List.Item>
                )}
            />

            <div className='viewMore'>
                <Button style={{
                    padding: 0,
                    color: 'rgb(86, 74, 53)'
                }} type="link" onClick={() => navigate('/reports/seller-listing')}
                    icon={<RightOutlined />}> VIEW MORE</Button>
            </div>

        </Card>

    );
}

export default SellerThreshold;
