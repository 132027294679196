import {api} from "../index";

const path = "/information-request";

const DontSellService = api.injectEndpoints({
    endpoints: (builder) => ({
        listDontSell: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getDontSell: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
    }),
});


export const {
    useListDontSellQuery,
    useGetDontSellQuery,
} = DontSellService;



