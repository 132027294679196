import {api} from "../index";

const path = "contacts";

const AddressService = api.injectEndpoints({
    endpoints: (builder) => ({
        listAddress: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}/address?${filters}`,
            providesTags : ['Address']
        }),
        getAddress: builder.query({
            query: (id, addressId = '') => `${path}/${id}/address/${addressId}`,
            providesTags : ['Address']
        }),
        createAddress: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}/address`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Address']
        }),
        updateAddress: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}/address/${data.id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Address']
        }),
        patchAddress: builder.mutation({
            query: ({id, addressId, data}) => ({
                url: `${path}/${id}/address/${addressId}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Address']
        }),
        deleteAddress: builder.mutation({
            query: ({id, addressId}) => ({
                url: `${path}/${id}/address/${addressId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Address']
        }),
    }),
});


export const {
    useListAddressQuery,
    useGetAddressQuery,
    useCreateAddressMutation,
    useUpdateAddressMutation,
    usePatchAddressMutation,
    useDeleteAddressMutation,
} = AddressService;



