import {Avatar, Button, Col, Image, Layout, Row, Spin, Typography, List, Divider, Form, Tag} from 'antd'
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useGetListingQuery} from "../../redux/api/services/listing";
import {ucFirst} from "../../utils/helper";


const ListingView = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [listing, setListing] = useState({});
    const [visible, setVisible] = useState(false);
    const {data, isLoading, error} = useGetListingQuery(params.id);
    const [normalVideos, setNormalVideos] = useState([]);
    const [youtubeVideos, setYoutubeVideos] = useState([]);


    useEffect(() => {
        if (data) {
            setListing(data.data);

            const videos = data.data?.videos;

            if (videos.length) {
                const youtubeVideos = videos.filter((item) => item.source === "youtube");
                const otherVideos = videos.filter((item) => item.source !== "youtube");
                setNormalVideos(otherVideos);
                setYoutubeVideos(youtubeVideos);
            }

        }
    }, [data]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>
                Listing Details

                <Button style={{
                    padding: 0,
                    color: '#CDA96D',
                    float: 'right'
                }} type="link" onClick={() => navigate(-1)}> Back</Button>

            </Typography.Title>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={10}>
                        <Row>
                            <Col span={12}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={[{
                                        name: listing.seller?.name,
                                        email: listing.seller?.email
                                    }
                                    ]}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar src={item.image}/>}
                                                title={<a href="#">{item.name}</a>}
                                                description={item.email}
                                            />
                                        </List.Item>
                                    )}
                                />
                                <Divider style={{margin: 0, marginBottom: 10}}/>
                            </Col>
                        </Row>

                        <Form
                            layout="horizontal"
                            labelAlign="left"
                            labelCol={{span: 8}}>
                            <h5>Details</h5>
                            <Col span={24}>
                                <Form.Item label="Plan" className="labelDy">
                                    : {listing.plan?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Dress Name" className="labelDy">
                                    : {listing.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Size" className="labelDy">
                                    : {listing.label}
                                </Form.Item>
                            </Col>
                            {listing.alternative_size && (
                                <Col span={24}>
                                    <Form.Item label="Alternate Size" className="labelDy">
                                        : {listing.alternative_size}
                                    </Form.Item>
                                </Col>)
                            }
                            {
                                listing.weight && (<Col span={24}>
                                    <Form.Item label="Weight" className="labelDy">
                                        : {listing.weight}
                                    </Form.Item>
                                </Col>)
                            }
                            {
                             listing.price && (<Col span={24}>
                                    <Form.Item label="Retail Price" className="labelDy">
                                        : {listing.price}
                                    </Form.Item>
                                </Col>)
                            }
                            {
                                listing.discount_price > 0 ? <Col span={24}>
                                    <Form.Item label="Offer Price" className="labelDy">
                                        : {listing.discount_price}
                                    </Form.Item>
                                </Col> : null
                            }
                            <Col span={24}>
                                <Form.Item label="Neck" className="labelDy">
                                    : {listing.neckline?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Silhouette" className="labelDy">
                                    : {listing.silhouette?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Back" className="labelDy">
                                    : {listing.back?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Sleeve" className="labelDy">
                                    : {listing.sleeve?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Length" className="labelDy">
                                    : {listing.length?.name}
                                </Form.Item>
                            </Col>
                            {
                                listing.color && ( <Col span={24}>
                                    <Form.Item label="Color" className="labelDy">
                                        : {listing.color}
                                    </Form.Item>
                                </Col>)
                            }
                            {listing.year && (
                                <Col span={24}>
                                    <Form.Item label="Year" className="labelDy">
                                        : {listing.year}
                                    </Form.Item>
                                </Col>
                            )}

                            <Col span={24}>
                                <Form.Item label="Designer" className="labelDy">
                                    : {listing.designer?.name}
                                </Form.Item>
                            </Col>

                            {
                                listing.ethnic?.name && ( <Col span={24}>
                                    <Form.Item label="Ethnic" className="labelDy">
                                        : {listing.ethnic?.name}
                                    </Form.Item>
                                </Col>)
                            }

                            <Col span={24}>
                                <Form.Item label="Status" className="labelDy">
                                    : {listing.status === "active" ?
                                    <Tag color={'green'}>{ucFirst(listing.status)}</Tag> :
                                    <Tag color={'red'}>{ucFirst(listing.status)}</Tag>}
                                </Form.Item>
                            </Col>

                        </Form>
                    </Col>

                    <Col span={14}>
                        <Row gutter={16}>
                            <Col span={10}>
                                <h3 style={{marginBottom: 10}}>Images ({listing?.images?.length ?? 0})</h3>
                                {
                                    listing?.images && listing?.images.length ? (
                                        <Image preview={{
                                            visible: false,
                                        }} src={listing?.images[0].image}
                                               onClick={() => setVisible(true)}
                                        />
                                    ) : null
                                }

                                <div
                                    style={{
                                        display: 'none',
                                    }}>
                                    <Image.PreviewGroup
                                        preview={{
                                            visible,
                                            onVisibleChange: (vis) => setVisible(vis),
                                        }}>
                                        {
                                            listing?.images && listing?.images.map((item, index) => {
                                                return (
                                                    <Image
                                                        preview={{
                                                            visible: false,
                                                        }}
                                                        width={200} src={item.image} onClick={() => setVisible(true)}
                                                    />
                                                )
                                            })
                                        }
                                    </Image.PreviewGroup>
                                </div>

                            </Col>
                            <Col span={14}>
                                <Col>
                                    <h3 style={{marginBottom: 10}}>Videos ({
                                        normalVideos?.length ?? 0
                                    })</h3>
                                    <Row gutter={[8, 8]}>
                                        {normalVideos?.map((item, index) => {
                                            return (
                                                <Col span={12}>
                                                    <video
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                        controls>
                                                        <source src={item.video}/>
                                                    </video>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                                <Col>
                                    <h3 style={{marginTop: 10, marginBottom: 10}}>Youtube Videos ({
                                        youtubeVideos?.length ?? 0
                                    })</h3>
                                    <Row gutter={[5, 5]}>
                                        {youtubeVideos?.map((item, index) => {
                                            return (
                                                <Col span={24}>
                                                    <iframe src={item.video} width={'100%'} height={150}
                                                            allowFullScreen title='video'/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </Col>
                        </Row>

                    </Col>

                </Row>


            </Spin>
        </Layout.Content>
    )
}

export default ListingView
