import {api} from "../index";

const path = "/feedback";

const FeedbackService = api.injectEndpoints({
    endpoints: (builder) => ({
        listFeedback: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getFeedback: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
    }),
});


export const {
    useListFeedbackQuery,
    useGetFeedbackQuery,
} = FeedbackService;



