import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Select, Skeleton, Table, Typography, Space, Tag, Button} from 'antd'
import {format} from "date-fns";
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useApproveListingMutation,
    useDeleteListingMutation,
    useListListingQuery, usePatchListingMutation, usePayListingMutation
} from '../../redux/api/services/listing';
import {useListSellerQuery} from "../../redux/api/services/seller";
import {useListPlanQuery} from "../../redux/api/services/plan";

import AddWidget from "../../widgets/AddWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {
    confirmAlert,
    convertTableParams,
    errorAlert,
    formatPrice,
    successAlert,
    ucFirst
} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";
import EditWidget from '../../widgets/EditWidget'
import DeleteWidget from '../../widgets/DeleteWidget'
import EyeWidget from '../../widgets/EyeWidget'
import SwitchWidget from "../../widgets/SwitchWidget";
import {ListStatus} from "../../utils/enums";
import ApproveWidget from "../../widgets/ApproveWidget";

const Listing = () => {

    const navigate = useNavigate();
    const [listings, setListings] = useState([]);
    const [sellerOptions, setSellerOptions] = useState([]);
    const [planOptions, setPlanOptions] = useState([]);
    const [loadingSwitch, setLoadingSwitch] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadingPayment, setLoadingPayment] = useState([]);
    const [loadingApproval, setLoadingApproval] = useState({});
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        include: 'seller;plan',
        filters: {
            is_direct_sold: 0,
        },
        searchByOr: ['name', 'code', 'seller.name', 'seller.email', 'plan.name'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchListings
    } = useListListingQuery(convertTableParams(tableParams));

    const [remove, {isLoading: removeLoading}] = useDeleteListingMutation()

    const {
        data: sellers, isLoading: loadingSellers, error: errorSellers, isFetching: fetchingSellers,
    } = useListSellerQuery('select=id,name,email');

    const {
        data: plans, isLoading: loadingPlans, error: errorPlans, isFetching: fetchingPlans,
    } = useListPlanQuery();


    const [patch, {isLoading: patchLoading}] = usePatchListingMutation();

    const [payList, {isLoading: payLoading}] = usePayListingMutation();

    const [approveList, {isLoading: approveLoading}] = useApproveListingMutation();

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Seller Name',
            dataIndex: ["seller", "name"],
            key: "seller.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.seller?.image ? <Avatar src={record?.seller?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.seller?.name}</p>
                        <p>{record?.seller?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Plan',
            dataIndex: ["plan", "name"],
            key: 'plan.name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (v, record) => (
                record?.discount_price > 0 ? (<span>{formatPrice(record?.discount_price)}</span>) : <span>{formatPrice(v)}</span>
            )
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => {
                return format(new Date(created_at), 'yyyy-MM-dd HH:mm:ss');
            }
        },
        {
            title: 'Featured',
            dataIndex: 'is_featured',
            key: 'is_featured',
            width: "10%",
            filters: [{
                text: 'Yes',
                value: 1,
            }, {
                text: 'No',
                value: 0,
            }],
            render: (v, record) => (
                <SwitchWidget loading={loadingSwitch[record.id] ?? false}
                              disabled={record.status === "sold" || record.status === "draft"}
                              value={v} onChange={(v) => changeSwitch(v, record)}/>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: ListStatus,
            render: (v, record) => (
                <>{v === "active" ?
                    <Tag color={'green'}>{ucFirst(v)}</Tag> :
                    (v === "draft" && record.admin_created ?
                        <Button type={'primary'} size={'small'} loading={loadingPayment[record.id] ?? false}
                                onClick={() => payListing(record.id)}>Pay</Button> :
                        <Tag color={'red'}>{ucFirst(v)}</Tag>)}
                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            width: "10%",
            render: (_, record) => (
                <Space>
                    {record.status !== "sold" &&
                     record.status !== "sold process" &&
                     record.status !== "active" &&
                     record.status !== "draft" ? <ApproveWidget loading={loadingApproval[record.id] ?? false}
                                      onClick={() => approveListing(record.id)}/> : null}
                    <EyeWidget onClick={() => navigate(`/listing/view/${record.id}`)}/>
                    {record.status !== "draft" && record.status !== 'sold' ?
                        <EditWidget onClick={() => navigate(`/listing/edit/${record.id}`)}/> : null}
                    {record.status !== "sold" ? <DeleteWidget loading={loadingDelete[record.id] ?? false}
                                                              onClick={() => deleteListing(record.id)}/> : null}
                </Space>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {

        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters: {
                ...tableParams.filters,
                ...filters
            },
            ...sorter,
        });
    };


    const changeSeller = (value) => {

        const listParams = {
            ...tableParams,
            callApi: true,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        }
        listParams.filters = {
            ...listParams.filters,
            seller_id: value
        }

        setTableParams(listParams);
    }

    const changePlan = (value) => {

        const listParams = {
            ...tableParams,
            callApi: true,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        }
        listParams.filters = {
            ...listParams.filters,
            plan_id: value
        }

        setTableParams(listParams);
    }


    async function changeSwitch(v, record) {

        setLoadingSwitch({...loadingSwitch, [record.id]: true});

        try {
            const res = await patch({id: record.id, data: {is_featured: v}});

            successAlert(res?.data?.message);

        } catch (e) {
            errorAlert(e);
        } finally {
            setLoadingSwitch({...loadingSwitch, [record.id]: false});
        }
    }


    const payListing = (id) => {
        confirmAlert(
            async () => {
                setLoadingPayment({...loadingPayment, [id]: true});
                try {
                    const res = await payList({id}).unwrap();
                    window.location.href = res?.data?.paypal.payer_action_url;
                } catch (e) {
                    errorAlert(e);
                    console.log(e);
                } finally {
                    setLoadingPayment({...loadingPayment, [id]: false});
                }
            }, "", "Are you sure you want to pay for this listing?");
    };

    const deleteListing = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    successAlert('Listing deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    const approveListing = async (id) => {
        setLoadingApproval({...loadingApproval, [id]: true});
        try {
            await approveList(id);
            successAlert('Listing successfully approved');
        } catch (e) {
            errorAlert(e);
            console.log(e);
        } finally {
            setLoadingApproval({...loadingApproval, [id]: false});
        }
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setListings(data.data.listings);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (sellers) {
            setSellerOptions(sellers.data.sellers.map(seller => ({
                label: seller.name+" ("+seller.email+")",
                value: seller.id
            })));
        }
    }, [sellers]);

    useEffect(() => {

        if (plans) {
            setPlanOptions(plans.data.plans.map(plan => ({
                label: plan.name,
                value: plan.id
            })));
        }

    }, [plans])

    useEffect(() => {
        if (tableParams.callApi)
            fetchListings(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Listings</Typography.Title>


            <Row>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'seller'} label={'Seller'}>
                        {
                            loadingSellers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select placeholder="Seller Name" showSearch allowClear options={sellerOptions}
                                        optionFilterProp="label"
                                        onChange={changeSeller}/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'plan'} label={'Plan'}>
                        {
                            loadingPlans ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select placeholder="Plan" showSearch allowClear options={planOptions}
                                        optionFilterProp="label"
                                        onChange={changePlan}/>
                        }
                    </Form.Item>

                </Col>

                <Col span={24}>
                    <AddWidget onClick={() => navigate(`/listing/add`)} label='Add Listing'/>
                </Col>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL LISTING COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={listings}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Listing;
