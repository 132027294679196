import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Select, Skeleton, Table, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListFeedbackQuery,
} from '../../redux/api/services/feedback';
import {useListBuyerQuery} from "../../redux/api/services/buyer";


import SearchWidget from "../../widgets/SearchWidget";
import {
    convertDate,
    convertTableParams,
    feedbackSmiley,
} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";
import EyeWidget from '../../widgets/EyeWidget';

const Feedback = () => {

    const navigate = useNavigate();
    const [feedback, setFeedback] = useState([]);
    const [buyerOptions, setBuyerOptions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        include: 'buyer',
        searchByOr: ['buyer.name', 'buyer.email'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchFeedback,
    } = useListFeedbackQuery(convertTableParams(tableParams));


    const {
        data: buyers, isLoading: loadingBuyers, error: errorBuyers, isFetching: fetchingBuyers,
    } = useListBuyerQuery();


    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (v) => (
                <span>{convertDate(v)}</span>
            )
        },
        {
            title: 'Buyer',
            dataIndex: ["buyer", "name"],
            key: "buyer.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.buyer?.image ? <Avatar src={record?.buyer?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.buyer?.name}</p>
                        <p>{record?.buyer?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Q1',
            dataIndex: 'q1_count',
            key: 'q1_count',
            render: (_, record) => feedbackSmiley(record?.q1_count)
        },
        {
            title: 'Q2',
            dataIndex: 'q2_count',
            key: 'q2_count',
            render: (_, record) => feedbackSmiley(record?.q2_count)

        },
        {
            title: 'Q3',
            dataIndex: 'q3_count',
            key: 'q3_count',
            render: (_, record) => feedbackSmiley(record?.q3_count)

        },
        {
            title: 'Q4',
            dataIndex: 'q4_count',
            key: 'q4_count',
            render: (_, record) => feedbackSmiley(record?.q4_count)

        },
        {
            title: 'Q5',
            dataIndex: 'q5_count',
            key: 'q5_count',
            render: (_, record) => feedbackSmiley(record?.q5_count)

        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (

                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EyeWidget onClick={() => navigate(`/feedback/${record.id}`)}/>
                    </Col>
                </Row>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };



    const changeBuyer = (value) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            filters: {
                buyer_id: value
            },
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setFeedback(data.data.feedback);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (buyers) {
            setBuyerOptions(buyers.data.buyers.map(buyer => ({
                label: buyer.name,
                value: buyer.id
            })));
        }
    }, [buyers]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchFeedback();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Feedback</Typography.Title>
            <Row>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'buyer'} label={'Select Buyer'}>
                        {
                            loadingBuyers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select showSearch optionFilterProp="label"
                                        placeholder="Buyer Name" allowClear options={buyerOptions}
                                        onChange={changeBuyer}/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>


                </Col>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL FEEDBACK COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={feedback}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default Feedback;
