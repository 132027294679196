import {Typography, Layout, Col, Form, Spin} from 'antd'
import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import {useGetDontSellQuery} from "../../redux/api/services/dont-sell";

const DontSellInfoView = () => {

    const params = useParams();

    const [dontSell, setDontSell] = useState({});


    const {data, isLoading, error} = useGetDontSellQuery({id: params.id});

    useEffect(() => {
        if (data) {
            setDontSell(data.data);
        }
    }, [data]);


    return (
        <Layout.Content className='container'>

            <Spin spinning={isLoading}>

                <div style={{marginBottom: 40}}>
                    <Typography.Title level={3}> DONT SELL INFO</Typography.Title>
                </div>



                    <div className='ordersummaryCtr'>

                        <div className='detailsCtr' style={{marginLeft: 40, marginTop: 5}}>
                            <Form
                                layout="horizontal"
                                labelAlign="left"
                                labelCol={{span: 4}}>

                                <Col span={24}>
                                    <Form.Item label="First Name" className="labelDy labelQuestion">
                                        :  {dontSell?.first_name}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Last Name" className="labelDy labelQuestion">
                                        :  {dontSell?.last_name}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Email" className="labelDy labelQuestion">
                                        :  {dontSell?.email}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Mobile" className="labelDy labelQuestion">
                                        :  {dontSell?.phone_no}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Address" className="labelDy labelQuestion">
                                        :  {dontSell?.address}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Request" className="labelDy labelQuestion">
                                        :  {dontSell?.request}
                                    </Form.Item>
                                </Col>
                            </Form>

                        </div>
                    </div>

            </Spin>
        </Layout.Content>
    )
}

export default DontSellInfoView
