import {api} from "../index";

const path = "masters/designers";

const DesignerService = api.injectEndpoints({
    endpoints: (builder) => ({
        listDesigner: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Designer']
        }),
        getDesigner: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`
        }),
        createDesigner: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Designer']
        }),
        updateDesigner: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Designer']
        }),
        patchDesigner: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Designer']
        }),
        deleteDesigner: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Designer']
        }),
    }),
});


export const {
    useListDesignerQuery,
    useGetDesignerQuery,
    useCreateDesignerMutation,
    useUpdateDesignerMutation,
    usePatchDesignerMutation,
    useDeleteDesignerMutation,
} = DesignerService;



