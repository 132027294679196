import {Button, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import React from "react";

const DeleteWidget = ({onClick, loading = false}) => {

    return (
        <Tooltip title="Delete">
            <Button loading={loading} type="link" onClick={onClick}
                    icon={<DeleteOutlined className="icon"/>}/>
        </Tooltip>
    )
}

export default DeleteWidget;
