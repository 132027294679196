import {Switch} from "antd";


const SwitchWidget = ({value, loading = false, disabled = false, onChange}) => {

    return (
        <Switch loading={loading} checked={value} disabled={disabled} onChange={onChange}/>
    )
}

export default SwitchWidget;
