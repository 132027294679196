import React from "react";
import { Col, Row } from "antd";
import { SwapLeftOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";



const ListingSuccess = () => {
    const params = useParams();


    return (
        <section className="page-section page-listing-success">
            <Row className="align-items-start">
                <Col md={{ span: 6, offset: 3 }} lg={{ span: 12, offset: 6 }} className="listingPopup">
                    <section className="paypal-status-success">
                        <CheckCircleOutlined />
                        <h3>We have successfully processed your payment.</h3>
                        <p>Wait a moment, we'll create your customized listing.</p>
                        <Link to={"/listing/view/" + params.id}
                              className='nav-link-filled'><SwapLeftOutlined /> Back to Listings </Link>
                    </section>
                </Col>
            </Row>
        </section>

    );
}

export default ListingSuccess;