import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from "redux-persist"
import storage from "redux-persist/lib/storage"

import {api} from '../api';
import authReducer from '../slice/authSlice';

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: [api.reducerPath],
}
const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(api.middleware),
})

export const persistor = persistStore(store)
