import {Col, Row, Table, Typography, Layout, Avatar, Form, Skeleton, Select} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import ViewWidget from "../../widgets/ViewWidget";
import {useNavigate} from "react-router-dom";

import {
    useListMessageQuery,
} from '../../redux/api/services/message';
import {useListSellerQuery} from "../../redux/api/services/seller";
import {useListBuyerQuery} from "../../redux/api/services/buyer";


import {UserOutlined} from "@ant-design/icons";
import {convertTableParams} from "../../utils/helper";
import {debounce} from "lodash";
import SearchWidget from "../../widgets/SearchWidget";

const ChatBox = () => {

    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [sellerOptions, setSellerOptions] = useState([]);
    const [buyerOptions, setBuyerOptions] = useState([]);


    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        include: 'seller;buyer;listing:id,name',
        searchByOr: ['listing.name', 'seller.name', 'seller.email', 'buyer.name', 'buyer.email'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    })


    const {
        data, isLoading, error, isFetching,
        refetch: fetchMessages
    } = useListMessageQuery(convertTableParams(tableParams));

    const {
        data: sellers, isLoading: loadingSellers, error: errorSellers, isFetching: fetchingSellers,
    } = useListSellerQuery();
    const {
        data: buyers, isLoading: loadingBuyers, error: errorBuyers, isFetching: fetchingBuyers,
    } = useListBuyerQuery();

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center"
        },
        {
            title: 'Seller',
            dataIndex: 'from',
            key: 'from',
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.seller?.image ? <Avatar src={record?.seller?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.seller?.name}</p>
                        <p>{record?.seller?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Buyer',
            dataIndex: 'to',
            key: 'to',
            render: (_, record) => {
                let buyer = record?.buyer;
                if (record?.type === 'contact') {
                    buyer = {
                        name: record?.name,
                        email: record?.email,
                    }
                }
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {buyer?.image ? <Avatar src={buyer?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{buyer?.name}</p>
                        <p>{buyer?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Description',
            dataIndex: ['listing', 'name'],
            key: 'listing.name',
        },
        {
            title: 'Status',
            key: 'Status',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <ViewWidget onClick={() => {
                            navigate(`/chat-box/${record.id}`)
                        }}/>
                    </Col>
                </Row>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };


    const changeSeller = (value) => {
        applyFilter('seller', value);
    }
    const changeBuyer = (value) => {
        applyFilter('buyer', value);
    }

    const applyFilter = (type, value) => {

        const listParams = {
            ...tableParams,
            callApi: true,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        }
        if (type === 'seller') {
            listParams.filters = {
                ...listParams.filters,
                seller_id: value
            }
        } else if (type === 'buyer') {
            listParams.filters = {
                ...listParams.filters,
                buyer_id: value
            }
        }
        setTableParams(listParams);
    }


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setMessages(data.data.message);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (sellers) {
            setSellerOptions(sellers.data.sellers.map(seller => ({
                label: seller.name,
                value: seller.id
            })));
        }
    }, [sellers]);

    useEffect(() => {
        if (buyers) {
            setBuyerOptions(buyers.data.buyers.map(buyer => ({
                label: buyer.name,
                value: buyer.id
            })));
        }
    }, [buyers]);


    useEffect(() => {
        if (tableParams.callApi)
            fetchMessages();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Chat Box</Typography.Title>
            <Row>
                <Col span={12}>
                    <Form.Item wrapperCol={{span: 15}} name={'seller'} label={'Select Seller'}>
                        {
                            loadingSellers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select showSearch optionFilterProp="label"
                                        placeholder="Seller Name" allowClear options={sellerOptions}
                                        onChange={changeSeller}/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item wrapperCol={{span: 15}} name={'buyer'} label={'Select Buyer'}>
                        {
                            loadingBuyers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select showSearch optionFilterProp="label"
                                        placeholder="Buyer Name" allowClear options={buyerOptions}
                                        onChange={changeBuyer}/>
                        }
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL CHAT COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={messages}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>

        </Layout.Content>
    )
}

export default ChatBox
