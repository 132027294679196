import {createSlice} from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null
    },
    reducers: {
        setUser: (state, action) => {
            state.token = action.payload.token
        },
        logOut: (state) => {
            state.token = null
        }
    }
})

export const {setUser, logOut} = authSlice.actions

export default authSlice.reducer
