import {api} from "../index";

const path = "news-letter";

const NewsLetterService = api.injectEndpoints({
    endpoints: (builder) => ({
        listNewsLetter: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['NewsLetter']
        }),
        getNewsLetter: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`,
            invalidatesTags: ['NewsLetter']
        }),
        patchNewsLetter: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['NewsLetter']
        }),
        deleteNewsLetter: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['NewsLetter']
        }),
    }),
});


export const {
    useListNewsLetterQuery,
    useGetNewsLetterQuery,
    usePatchNewsLetterMutation,
    useDeleteNewsLetterMutation,
} = NewsLetterService;



