import {Typography, Layout, Row, Col, Form, Spin, List, Avatar} from 'antd'
import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import {useGetShippingTransactionQuery} from "../../../redux/api/services/shipping-transaction";
import {formatPrice, ucFirst} from "../../../utils/helper";

const ShippingTransactionView = () => {

    const params = useParams();

    const [transaction, setTransaction] = useState({});

    const filters = 'include=buyer;seller;order:id;order.detail:id,order_id,name,amount&filter[is_admin_amount]=0';


    const {data, isLoading, error} = useGetShippingTransactionQuery({id: params.id, filters: filters});

    useEffect(() => {
        if (data) {
            setTransaction(data.data);
        }
    }, [data]);


    return (
        <Layout.Content className='container'>

            <Spin spinning={isLoading}>

                <div style={{marginBottom: 40}}>
                    <Typography.Title level={3}>Transaction ID : {
                        transaction?.ship_gateway_txn_id
                    }</Typography.Title>
                </div>
                <Row>
                    <Col span={12}>
                        <Typography.Title level={5}>Buyer</Typography.Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: transaction.buyer?.name,
                                email: transaction.buyer?.email,
                                image: transaction.buyer?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image}/>}
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={12}>
                        <Typography.Title level={5}>Seller</Typography.Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: transaction?.seller?.name,
                                email: transaction?.seller?.email,
                                image: transaction?.seller?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image}/>}
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <Form*/}
                    {/*        layout="horizontal"*/}
                    {/*        labelAlign="left"*/}
                    {/*        labelCol={{span: 8}}>*/}
                    {/*        <Col span={24}>*/}
                    {/*            <Form.Item label="Order Id" className="labelDy">*/}
                    {/*                : {transaction?.order_id}*/}
                    {/*            </Form.Item>*/}
                    {/*        </Col>*/}
                    {/*        <Col span={24}>*/}
                    {/*            <Form.Item label="Payment Details" className="labelDy">*/}
                    {/*                : {formatPrice(transaction?.ship_amount)}*/}
                    {/*            </Form.Item>*/}
                    {/*        </Col>*/}
                    {/*    </Form>*/}
                    {/*</Col>*/}
                </Row>

                <div className='ordersummaryCtr'>
                    <h4>Order Summary</h4>
                    <div className='detailsCtr' style={{marginLeft: 40, marginTop: 40}}>
                        <Form
                            layout="horizontal"
                            labelAlign="left"
                            labelCol={{span: 4}}>
                            <Col span={24}>
                                <Form.Item label="Order Id" className="labelDy">
                                    : {transaction?.order_id}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Payment Details" className="labelDy">
                                    : {formatPrice(transaction?.ship_amount)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Dress Name" className="labelDy">
                                    : {transaction?.order?.detail?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Price" className="labelDy">
                                    : {formatPrice(transaction?.order?.detail?.amount)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Paid" className="labelDy status-label-1">
                                    : <span>{ucFirst(transaction?.status  || 'unpaid')}</span>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Payment Method" className="labelDy">
                                    : PayPal
                                </Form.Item>
                            </Col>
                        </Form>
                    </div>
                </div>
            </Spin>
        </Layout.Content>
    )
}

export default ShippingTransactionView
