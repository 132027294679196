import {api} from "../index"

const path = "listings"

const ListingService = api.injectEndpoints({
    endpoints: (builder) => ({
        listListing: builder.query({
            query: (filters = "") => `${path}?${filters}`,
            providesTags: ["Listing"],
        }),
        getListing: builder.query({
            query: (id, filters = "") => `${path}/${id}?${filters}`,
            providesTags: ["Listing"],
        }),
        createListing: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Listing"],
        }),
        updateListing: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Listing"],
        }),
        patchListing: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: "PATCH",
                body: data,
            }),
            invalidatesTags: ["Listing"],
        }),
        PayListing: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}/pay`,
                method: "PATCH",
                body: data,
            }),
            invalidatesTags: ["Listing"],
        }),
        ApproveListing:builder.mutation({
            async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {
                try {
                    // First, approve the listing
                    const approveResponse = await fetchWithBQ({
                        url: `${path}/${id}/approve`,
                        method: 'PATCH',
                    });

                    if (approveResponse.error) throw approveResponse.error;

                    // Then, invalidate the cache
                    const invalidateCacheResponse = await fetchWithBQ({
                        url: `${path}/invalidate-cache`,
                        method: 'GET',
                    });

                    if (invalidateCacheResponse.error) throw invalidateCacheResponse.error;

                    return { data: { ...approveResponse.data, ...invalidateCacheResponse.data } };
                } catch (error) {
                    return { error };
                }
            },
            invalidatesTags: ['Listing'],
        }),
        deleteListing: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Listing"],
        }),
    }),
})

export const {
    useListListingQuery,
    useGetListingQuery,
    useCreateListingMutation,
    useUpdateListingMutation,
    usePatchListingMutation,
    usePayListingMutation,
    useApproveListingMutation,
    useDeleteListingMutation,
} = ListingService
