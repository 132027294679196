import {api} from "../index";

const path = "/popular-search";

const PopularSearchService = api.injectEndpoints({
    endpoints: (builder) => ({
        listPopularSearch: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
    }),
});


export const {
    useListPopularSearchQuery,
} = PopularSearchService;



