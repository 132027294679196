import {Button, Tooltip} from "antd";
import React from "react";

const ViewWidget = ({onClick, loading = false}) => {

    return (
        <Tooltip title="View">
            <Button loading={loading} type="link" onClick={onClick}
            > View</Button>
        </Tooltip>
    )
}

export default ViewWidget;
