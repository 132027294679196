import { Col, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import TotalCard from "./total-card";
import SalesReport from "./sales-report";
import SellerReport from "./seller-report";
import TopCustomers from "./top-customers";
import TopCategories from "./top-categories";
import SellerMostSales from "./seller-most-sales";
import SellerThreshold from "./seller-threshold";
import RecentActivity from "./recent-activity";
import { useListCardsQuery } from "../../redux/api/services/dashboard";

const Dashboard = () => {

    const [cardData, setCardData] = useState({
        customers: {
            total: 0,
            label: 'Total Sellers',
            link: '/seller'
        },
        orders: {
            total: 0,
            label: 'Total Orders',
            link: '/order'
        },
        sales: {
            total: 0,
            label: 'Total Sales',
            link: '/payment/plan',
            amount : true
        },
        listings: {
            total: 0,
            label: 'Total Listings',
            link: '/listing'
        }
    });

    const [topCustomers, setTopCustomers] = useState([]);
    const [topSellers, setTopSellers] = useState([]);
    const [topCategories, setTopCategories] = useState([]);
    const [sellerThreshold, setSellerThreshold] = useState([]);
    const [recentActivity, setRecentActivity] = useState([]);

    const { data, isLoading, error } = useListCardsQuery();

    useEffect(() => {
        if (data) {

            const result = data.data;

            cardData.customers.total = result.count.customers;
            cardData.orders.total = result.count.orders;
            cardData.sales.total = result.count.sales;
            cardData.listings.total = result.count.listings;
            setCardData({ ...cardData });

            setTopCustomers(result.top_customers);
            setTopSellers(result.top_sellers);
            setSellerThreshold(result.seller_threshold);
            setTopCategories(result.top_categories);
            setRecentActivity(result.recent_activities)

        }
    }, [data]);


    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Dashboard</Typography.Title>

            <Col span={24} style={{ marginBottom: 30 }}>
                <TotalCard data={cardData} loading={isLoading} />
            </Col>
            <Col span={24}>
                <Typography.Title level={4}>Reports</Typography.Title>
                <Row gutter={[10, 30]}>
                    <Col span={8} className='listingCard'>
                        <SalesReport />
                    </Col>
                    <Col span={8} className='listingCard'>
                        <SellerReport />
                    </Col>
                    <Col span={8} className='listingCard'>
                        <TopCustomers data={topCustomers} loading={isLoading} />
                    </Col>
                </Row>
            </Col>


            <Row gutter={[10, 30]}>
                <Col span={24}>
                    <Typography.Title level={4}>Listings</Typography.Title>
                    <Row gutter={[20, 0]}>
                        <Col span={8} className='listingCard'>
                            <TopCategories data={topCategories} loading={isLoading}/>
                        </Col>
                        <Col span={8} className='listingCard'>
                            <SellerMostSales data={topSellers} loading={isLoading} />
                        </Col>
                        <Col span={8} className='listingCard'>
                            <SellerThreshold data={sellerThreshold} loading={isLoading} />
                        </Col>
                    </Row>
                    <Col span={24}>
                        <RecentActivity data={recentActivity} loading={isLoading} />
                    </Col>
                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Dashboard
