import React, {useState, useEffect, useCallback} from 'react'
import {Col, Layout, Row, Space, Switch, Table, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListNewsLetterQuery, usePatchNewsLetterMutation, useDeleteNewsLetterMutation
} from '../../redux/api/services/news-letter';


import SearchWidget from "../../widgets/SearchWidget";
import {
    confirmAlert,
    convertDate,
    convertTableParams, errorAlert, successAlert,
} from "../../utils/helper";
import DeleteWidget from "../../widgets/DeleteWidget";

const NewsLetter = () => {

    const navigate = useNavigate();
    const [newsLetter, setNewsLetter] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadingSwitch, setLoadingSwitch] = useState([]);
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['email'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchNewsLetter,
    } = useListNewsLetterQuery(convertTableParams(tableParams));

    const [patch] = usePatchNewsLetterMutation();
    const [remove] = useDeleteNewsLetterMutation();

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (v) => (
                <span>{convertDate(v)}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Mail Verified',
            dataIndex: 'email_verified_at',
            key: 'email_verified_at',
            render: (v) => (
                <span>{v ? 'Yes' : 'No'}</span>
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Space>

                    <Switch
                        checkedChildren={'Subscribed'}
                        unCheckedChildren={'Unsubscribed'}
                        loading={loadingSwitch[record.id] ?? false} checked={record.is_subscribe}
                        onChange={(v) => changeSwitch(v, record)}/>

                    <DeleteWidget loading={loadingDelete[record.id] ?? false}
                                  onClick={() => deleteNewsLetter(record.id)}/>


                </Space>
            ),
        },
    ];

    async function changeSwitch(v, record) {

        setLoadingSwitch({...loadingSwitch, [record.id]: true});

        try {
            const res = await patch({id: record.id, data: {is_subscribe: v}});
            successAlert(res?.data?.message);
        } catch (e) {
            errorAlert(e);
        } finally {
            setLoadingSwitch({...loadingSwitch, [record.id]: false});
        }
    }

    const deleteNewsLetter = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    successAlert('Deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setNewsLetter(data.data.news_letter);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchNewsLetter();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>NewsLetter</Typography.Title>
            <Row>
                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL NEWSLETTER COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={newsLetter}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default NewsLetter;
