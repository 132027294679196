import React from "react";
import { Avatar, Button, Card, List, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { RightOutlined, UserOutlined } from "@ant-design/icons";

const TopCustomers = ({ data, loading }) => {

    const navigate = useNavigate();

    return (
        <Card style={{
            background: "#CDA96D",
            flex: 1
        }}
        loading={loading}>
            <Typography.Title level={4} style={{
                color: "black"
            }}>Top Customers</Typography.Title>

            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                item?.buyer?.image ? <Avatar src={item?.buyer?.image} /> :
                                    <Avatar icon={<UserOutlined />} />
                            }
                            title={<span>{item.buyer.name}</span>}
                            description={item.buyer.email}
                        />
                        <div>{item.count}</div>
                    </List.Item>
                )}
            />


            <div className='viewMore'>
                <Button style={{
                    padding: 0,
                    color: 'rgb(86, 74, 53)'
                }} type="link" onClick={() => navigate('/reports/top-customer')}
                    icon={<RightOutlined />}> SEE ALL CUSTOMERS</Button>
            </div>

        </Card>

    );
}

export default TopCustomers;
