import React, {useState} from "react";

import {
    useUploadFileMutation
} from "../redux/api/services/file";
import {errorAlert} from "../utils/helper";
import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import {Modal, Upload} from 'antd';

const ImageUploadComponent = ({url = '', label = '' ,className = '', onChange, tag = ''}) => {

    const [fileList, setFileList] = useState(url ? [{
        uid: url,
        name: url,
        status: 'done',
        url: url
    }] : []);

    const [loading, setLoading] = useState(false);
    const [previewFile, setPreviewFile] = useState({open: false, title: '', url: ''});

    const [uploadFile] = useUploadFileMutation();

    const handleUpload = async (options) => {
        const {onSuccess, onError, file} = options;

        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append("tag", tag);
            const result = await uploadFile(formData);
            const response = result?.data?.data;
            setFileList([{
                uid: response?.name,
                name: response?.name,
                status: 'done',
                url: response?.path,
            }]);
            onSuccess('Ok');
            onChange(response?.name);

        } catch (e) {
            onError(e);
            errorAlert(e.response ? e : 'Invalid file');
        } finally {
            setLoading(false);
        }
    };


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
        if (!isJpgOrPng) {
            errorAlert('You can only upload JPG/PNG or WEBP file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            errorAlert('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };


    const handlePreview = async (file) => {
        setPreviewFile({
            open: true,
            title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
            url: file.url
        });
    };

    const handleDelete = async (file) => {
        try {
            setFileList([]);
            onChange('');
        } catch (e) {
            errorAlert(e);
        }
    }

    const handleCancel = () => {
        setPreviewFile({
            open: false,
            title: '',
            url: ''
        });
    };

    const props = {
        name: "avatar",
        listType: label ? label : "picture-circle",
        className: "avatar-uploader",
        maxCount: 1,
        multiple: false,
        customRequest: handleUpload,
        onPreview: handlePreview,
        onRemove: handleDelete,
        beforeUpload: beforeUpload,
        fileList
    };


    return (
        <>
            <Upload {...props} className={className}>
                {fileList.length >= 1 ? null :
                    <div>
                        {loading ? <LoadingOutlined/> : <UploadOutlined/>}
                        <div style={{marginTop: 0}}>
                            {loading ? 'Uploading' : 'Upload'}
                        </div>
                    </div>}
            </Upload>
            <Modal open={previewFile.open} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{width: '100%'}} src={previewFile.url}/>
            </Modal>
        </>
    );
}

export default ImageUploadComponent;
