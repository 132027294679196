import {api} from "../index";

const path = "masters/styles";

const StyleService = api.injectEndpoints({
    endpoints: (builder) => ({
        listStyle: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Style']
        }),
        getStyle: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`
        }),
        createStyle: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Style']
        }),
        updateStyle: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Style']
        }),
        patchStyle: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Style']
        }),
        deleteStyle: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Style']
        }),
    }),
});


export const {
    useListStyleQuery,
    useGetStyleQuery,
    useCreateStyleMutation,
    useUpdateStyleMutation,
    usePatchStyleMutation,
    useDeleteStyleMutation,
} = StyleService;



