import {api} from "../index";

const path = "/categories/details";

const CategoryDetailService = api.injectEndpoints({
    endpoints: (builder) => ({
        listCategoryDetail: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['CategoryDetail']
        }),
        getCategoryDetail: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`,
            invalidatesTags: ['CategoryDetail']
        }),
        createCategoryDetail: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['CategoryDetail', 'Category']
        }),
        updateCategoryDetail: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['CategoryDetail', 'Category']
        }),
        patchCategoryDetail: builder.mutation({
            query: (id, data) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['CategoryDetail', 'Category']
        }),
        deleteCategoryDetail: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CategoryDetail', 'Category']
        }),
    }),
});


export const {
    useListCategoryDetailQuery,
    useGetCategoryDetailQuery,
    useCreateCategoryDetailMutation,
    useUpdateCategoryDetailMutation,
    usePatchCategoryDetailMutation,
    useDeleteCategoryDetailMutation,
} = CategoryDetailService;



