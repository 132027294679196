import {api} from "../index";

const path = "/invoices";

const InvoiceService = api.injectEndpoints({
    endpoints: (builder) => ({
        listInvoice: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getInvoice: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
        download: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}/pdf`,
                method: 'POST',
                body: {},
                responseHandler: async (res) => {
                    if (res.status === 200) {
                        return await res.blob();
                    } else {
                        return await res.json();
                    }
                },
            })
        }),
    }),
});


export const {
    useListInvoiceQuery,
    useGetInvoiceQuery,
    useDownloadMutation,
} = InvoiceService;



