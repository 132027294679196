import React from "react";
import {Col, Row} from "antd";
import {CheckCircleOutlined, SwapLeftOutlined} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";


const OnboardSuccess = () => {

    const params = useParams();


    return (
        <section className="page-section page-onboarding">
            <Row className="align-items-start">
                <Col md={{span: 6, offset: 3}} lg={{span: 12, offset: 6}} className="listingPopup">
                    <section className="paypal-status-success">
                        <CheckCircleOutlined/>
                        <h3>Your PayPal account has been successfully linked to the seller's account. </h3>
                        <p>Allow the connection to establish for a moment. </p>
                        <Link to={"/seller/view/"+params.id} className='nav-link-filled'><SwapLeftOutlined /> Back to Seller </Link>
                    </section>

                </Col>
            </Row>
        </section>
    );
}

export default OnboardSuccess;
