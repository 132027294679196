import {Button} from "antd";
import React from "react";


const ModalFooterWidget = ({loading, onCancelClick, onOkClick, label='Save'}) => {

        return (
            <>
                 <Button key="back" type="primary" onClick={onCancelClick}>
                     Cancel
                 </Button>
                 <Button key="sub"
                         type="primary"
                         loading={loading}
                         onClick={onOkClick}>
                     {label}
                 </Button>
            </>

        )
}

export default ModalFooterWidget;
