import {Button} from "antd";
import React from "react";


const AddWidget = ({onClick, label = "Add", loading = false}) => {

    return (
        <Button type='primary' onClick={onClick}>
            {label}
        </Button>
    )
}

export default AddWidget;
