import React, {useEffect, useState} from "react"

import {useUploadFileMutation} from "../redux/api/services/file"
import {errorAlert} from "../utils/helper"
import { ReloadOutlined, LoadingOutlined, UploadOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import {Checkbox, Button, Modal, Upload} from "antd"

const ImageMultiUpload = ({label = "", className = "", onChange, maxCount, url, tag=''}) => {
    const [fileList, setFileList] = useState([])

    const [loading, setLoading] = useState(false)
    const [previewFile, setPreviewFile] = useState({
        open: false,
        title: "",
        url: "",
        rotation: 0, // Initialize rotation
    });

    const [uploadFile] = useUploadFileMutation()

    const handleUpload = async (options) => {
        const {onSuccess, onError, file} = options;
        const dat = {
            file: file,
            type: "image",
            tag: "listing", // For image resize utility
        };

        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("file", file)
            formData.append("tag", tag)
            const result = await uploadFile(formData).unwrap()
            const response = result?.data

            let imageObject = {
                uid: response?.name,
                name: response?.name,
                status: "done",
                url: response?.path,
                is_default: false,
                image: response?.name,
                rotation: 0, // Initialize rotation
            }

            setFileList((state) => {
                if (state.length === 0) {
                    imageObject.is_default = true;
                }
                return [
                    ...state,
                    imageObject
                ]

            })
            onSuccess("Ok")
            onChange(imageObject, 'upload')
        } catch (e) {
            onError(e)
            errorAlert(e.response ? e : "Invalid file")
        } finally {
            setLoading(false)
        }
    }

    const beforeUpload = (file) => {
        const fileType = file.type.toString().toLowerCase();
        const fileName = file.name.toString().toLowerCase();
        const isJpgOrPng =
            fileType === "image/jpeg" ||
            fileType === "image/jpg" ||
            fileType === "image/png" ||
            fileType === "image/webp" ||
            fileName.endsWith(".heic");
        if (!isJpgOrPng) {
            errorAlert(file.name + " - You can only upload JPG/JPEG/WEBP/PNG/HEIC files!");

        }
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            errorAlert(file.name + "Image must be smaller than 20MB!");
        }
        return isJpgOrPng && isLt20M;
    };

    const handlePreview = async (file) => {
        setPreviewFile({
            open: true,
            title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
            url: file.url,
            type: file.type,
            rotation: file.rotation || 0, // Set rotation for preview
        });
    };

    const handleDelete = async (file) => {
        try {
            setFileList((state) => {
                return state.filter((item) => item.name !== file.name)
            })
            onChange(file.name, "delete")
        } catch (e) {
            errorAlert(e)
        }
    }

    const changeDefault = async (e, file) => {

        let newFileList = [];
        let selectedIndex = '';
        fileList.forEach((item, index) => {
            if (item.name === file.name) {
                selectedIndex = index;
                item.is_default = e.target.checked;
            }
            newFileList.push(item);
        });
        if (e.target.checked) {
            newFileList.forEach((item, i) => {
                if (i !== selectedIndex) {
                    item.is_default = false;
                }
            });
        }

        setFileList(newFileList);
        onChange(newFileList, "update")
    }

    const handleCancel = () => {
        setPreviewFile({
            open: false,
            title: "",
            url: "",
            rotation: 0, // Reset rotation
            type: "",
        })
    }
    const handleRotate = (file) => {
        const newFileList = fileList.map((item) =>
            item.uid === file.uid ? { ...item, rotation: (item.rotation + 90) % 360 } : item
        );
        setFileList(newFileList);

        // Update the preview rotation if the rotated file is being previewed
        if (previewFile.open && previewFile.uid === file.uid) {
            setPreviewFile({
                ...previewFile,
                rotation: (previewFile.rotation + 90) % 360,
            });
        }
        onChange(newFileList, "update");
    };

    const props = {
        name: "avatar",
        listType: label ? label : "picture-circle",
        className: "avatar-uploader",
        maxCount: maxCount,
        multiple: true,
        accept: ".heic,.jpg,.jpeg,.png,.webp",
        itemRender: (field, file) => {
            return <>
                <div style={{position: 'relative'}}>
                    <div style={{marginBottom: '5px'}}>
                        {/*
                        <Button onClick={() => handlePreview(file)} icon={<EyeOutlined/>} style={{marginRight: '5px'}}>
                            View
                        </Button>
                        */}
                        <Button className="w-100" onClick={() => handleDelete(file)} icon={<DeleteOutlined/>} danger>
                            Delete
                        </Button>
                    </div>

                    {React.cloneElement(field, {
                        style: {
                            transform: `rotate(${file.rotation || 0}deg)`,
                            transition: 'transform 0.3s',
                        },
                    })}
                    <Checkbox style={{fontSize: 12, marginBottom:10}} checked={file.is_default} defaultChecked={true}
                              onChange={(e) => changeDefault(e, file)}>
                        Is Primary
                    </Checkbox>
                    <Button onClick={() => handleRotate(file)} className="w-100" style={{marginTop: "5px"}}>
                        <ReloadOutlined/> Rotate
                    </Button>
                </div>
            </>
        },
        customRequest: handleUpload,
        onPreview: handlePreview,
        onRemove: handleDelete,
        beforeUpload: beforeUpload,
        fileList,
    }

    useEffect(() => {
        if (url.length >= 1) {
            const array = url.map((item) => {
                return {
                    uid: item.image,
                    name: item.image,
                    image: item.image,
                    status: "done",
                    url: item.image,
                    is_default: item.is_default === 1 ? true : false,
                    rotation: item.rotation !== undefined ? item.rotation : 0
                }
            });
            setFileList(array);
        }
    }, [url]);

    return (
        <>
            <Upload {...props} className={'uploadDesign'} style={{
                marginBottom: 10,
            }}>
                {fileList.length >= maxCount ? null : (
                    <div>
                        {loading ? (
                            <LoadingOutlined
                                style={{
                                    color: "white",
                                }}
                            />
                        ) : (
                            <UploadOutlined
                                style={{
                                    color: "white",
                                }}
                            />
                        )}
                        <div style={{marginTop: 0, color: "white"}}>
                            {loading ? "Uploading" : "Upload"}
                        </div>
                    </div>
                )}
            </Upload>
            <Modal open={previewFile.open} footer={null} onCancel={handleCancel}>
                <img
                    alt="preview-image"
                    style={{ width: "100%", transform: `rotate(${previewFile.rotation}deg)` }}
                    src={previewFile.url}
                />
            </Modal>
        </>
    );
};

export default ImageMultiUpload;
