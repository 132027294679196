import React, {useState, useEffect, useCallback} from 'react'
import {Col, Form, Image, Layout, Row, Table, Typography} from 'antd'
import {debounce} from "lodash";


import {
    useListBannerQuery,
    useDeleteBannerMutation,
} from '../../redux/api/services/banner';
import EditWidget from "../../widgets/EditWidget";
import DeleteWidget from "../../widgets/DeleteWidget";
import AddWidget from "../../widgets/AddWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {confirmAlert, convertTableParams, successAlert} from "../../utils/helper";
import BannerAction from "./action";

const Banner = () => {

    const [banners, setBanners] = useState([]);
    const [banner, setBanner] = useState(null);
    const [action, setAction] = useState(null);
    const [showModel, setShowModel] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['name'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchBanners
    } = useListBannerQuery(convertTableParams(tableParams));
    const [remove, {isLoading: loading}] = useDeleteBannerMutation();


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Banner',
            dataIndex: 'value',
            key: 'value',
            render: (v) => {
                return <Image width={100} src={v}/>
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EditWidget onClick={() => saveAction('edit', record)}/>
                    </Col>
                    <Col>
                        <DeleteWidget loading={loadingDelete[record.id] ?? false}
                                      onClick={() => deleteSlider(record.id)}/>
                    </Col>

                </Row>
            ),
        },
    ];


    const deleteSlider = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    successAlert('Banner deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    function saveAction(action, size = null) {

        setTableParams({
            ...tableParams,
            callApi: false,
        })

        setBanner(size);
        setAction(action);
    }

    function resetAction(c) {
        if (c && action === 'add') {
            setTotalRecords(totalRecords + 1);
        }
        setShowModel(c);
        setBanner(null);
        setAction(null);
    }

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);


    useEffect(() => {
        if (action)
            setShowModel(true);
        else
            setShowModel(false);
    }, [action]);


    useEffect(() => {
        if (data) {
            setBanners(data.data.banners);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchBanners(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Banners</Typography.Title>
            <Row gutter={[10, 15]} align='middle' justify="space-between">
                <Col span={12}>
                    <div className="totalRecords">
                        TOTAL BANNER COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={12}>
                    <Row gutter={[10, 10]} justify={'end'}>
                        <Col span={24} style={{
                            width: "100%",
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'end'
                        }}>
                            <AddWidget onClick={() => saveAction('add')} label='Add New'/>

                        </Col>
                        <Col span={10}>
                            <Form.Item>
                                <SearchWidget onSearch={debouncedOnSearch}/>
                            </Form.Item>
                        </Col>
                    </Row>

                </Col>
                <Col span={24}>

                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={banners}
                        columns={columns} rowKey="id"/>

                    {action !== null &&
                        <BannerAction open={showModel} action={action} banner={banner} onClose={resetAction}/>}

                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Banner;
