import {api} from "../index";

const path = "testimonials";

const TestimonialService = api.injectEndpoints({
    endpoints: (builder) => ({
        listTestimonial: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Testimonial']
        }),
        getTestimonial: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`
        }),
        createTestimonial: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Testimonial']
        }),
        updateTestimonial: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Testimonial']
        }),
        patchTestimonial: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Testimonial']
        }),
        deleteTestimonial: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Testimonial']
        }),
    }),
});


export const {
    useListTestimonialQuery,
    useGetTestimonialQuery,
    useCreateTestimonialMutation,
    useUpdateTestimonialMutation,
    usePatchTestimonialMutation,
    useDeleteTestimonialMutation,
} = TestimonialService;



