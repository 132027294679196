import {Avatar, Button, Col, Layout, List, Row, Space, Spin, Tag} from 'antd'
import React, {useEffect, useState} from 'react'
import EditWidget from "../../widgets/EditWidget";
import SellerAction from "./action";
import {
    usePaypalPartnerMutation,
    usePaypalDisconnectMutation
} from "../../redux/api/services/seller";
import {confirmAlert, errorAlert, successAlert} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";


const AccountInformation = ({data}) => {

    const [action, setAction] = useState(null);
    const [showModel, setShowModel] = useState(false);
    const [actionUrl, setActionUrl] = useState(null);

    const [paypalPartner, {isLoading: loading}] = usePaypalPartnerMutation();
    const [patch, {isLoading: patchLoading}] = usePaypalDisconnectMutation();


    function resetAction(c) {
        setShowModel(c);
        setAction(null);
    }

    function saveAction(action, seller = null) {
        setAction(action);
    }


    const createPaypalPartner = (id) => {
        paypalPartner(id).unwrap()
            .then((response) => {
                window.location.href = response?.data?.action_url;
                //  setActionUrl(response?.data?.action_url);
            })
            .catch((err) => {
                errorAlert(err?.data?.error)
            })
    }

    const disconnectPaypal = (id) => {
        confirmAlert(
            async () => {
                try {
                    const res = await patch({id: id, data: {}});
                    successAlert(res?.data?.message);
                } catch (e) {
                    errorAlert(e);
                }
            }, "", "Disconnecting your PayPal account will prevent you from offering PayPal services and products on your website. Do you wish to continue?");
    }


    useEffect(() => {
        if (action)
            setShowModel(true);
        else
            setShowModel(false);
    }, [action]);


    return (
        <Layout.Content className='container'>
            <Spin spinning={false}>

                <div className='accountInformCtr accountInformCtrTab'>

                    <Row>
                        <Col span={18}>
                            <List
                                itemLayout="horizontal"
                                dataSource={[{
                                    name: data?.name,
                                    email: data?.email,
                                    image: data?.image
                                }
                                ]}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                item.image ? <Avatar size={60} src={item.image}/> :
                                                    <Avatar size={60} icon={<UserOutlined/>}/>
                                            }
                                            title={<a href="#">{item.name}</a>}
                                            description={item.email}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col span={6}>
                            <div style={{textAlign: 'right'}}>
                                <EditWidget onClick={() => saveAction('edit', data)}/>
                            </div>
                        </Col>
                    </Row>
                    <p>Mobile : {data?.mobile} </p>
                    <p>Street : {data?.seller_address}</p>
                    <p>State : {data?.seller_state?.name}</p>
                    <p>City : {data?.seller_city}</p>
                    <p>Zip Code : {data?.seller_zip_code} </p>
                    <p>Status : {data?.status ? 'Active' : 'Inactive'} </p>
                    <p>Paypal Status : {
                        data?.paypal_merchant_status === "verified" ?
                            <Space>
                                <Tag color={'green'}>{data?.paypal_merchant_status}</Tag>
                                <Button
                                    style={{backgroundColor: 'red', borderColor: 'red'}}
                                    type='primary' loading={patchLoading}
                                    onClick={
                                        () => disconnectPaypal(data?.id)
                                    }>
                                    Disconnect
                                </Button>
                            </Space>
                            :
                            (data?.paypal_merchant_status != null ?
                                <Tag color={'red'}>{data?.paypal_merchant_status}</Tag> :
                                <Button type='primary' loading={loading}
                                        onClick={() => createPaypalPartner(data?.id)}>
                                    connect to paypal
                                </Button>)
                    }
                    </p>


                </div>

            </Spin>

            {action !== null &&
                <SellerAction open={showModel} action={action} seller={data} onClose={resetAction}/>}

        </Layout.Content>
    )
}

export default AccountInformation
