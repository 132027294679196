import {Typography, Layout, Row, Col, Form, Spin, List, Avatar} from 'antd'
import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import {useGetFeedbackQuery} from "../../redux/api/services/feedback";
import {feedbackSmiley, formatPrice, ucFirst} from "../../utils/helper";

const FeedbackView = () => {

    const params = useParams();

    const [feedback, setFeedback] = useState({});

    const filters = 'include=buyer';


    const {data, isLoading, error} = useGetFeedbackQuery({id: params.id, filters: filters});

    useEffect(() => {
        if (data) {
            setFeedback(data.data);
        }
    }, [data]);


    return (
        <Layout.Content className='container'>

            <Spin spinning={isLoading}>

                <div style={{marginBottom: 40}}>
                    <Typography.Title level={3}>Feedback</Typography.Title>
                </div>
                <Row>
                    <Col span={12}>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: feedback.buyer?.name,
                                email: feedback.buyer?.email,
                                image: feedback.buyer?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image}/>}
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={12}>

                        <List.Item.Meta
                            title='Review'
                            description={feedback?.description}
                        />

                    </Col>

                </Row>

                <div className='ordersummaryCtr'>

                    <div className='detailsCtr' style={{marginLeft: 40, marginTop: 5}}>
                        <Form
                            layout="horizontal"
                            labelAlign="left"
                            labelCol={{span: 20}}>
                            <Col span={24}>
                                <Form.Item label={feedback?.q1} className="labelDy labelQuestion">
                                     {feedbackSmiley(feedback?.q1_count)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={feedback?.q2} className="labelDy labelQuestion">
                                     {feedbackSmiley(feedback?.q2_count)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={feedback?.q3} className="labelDy labelQuestion">
                                     {feedbackSmiley(feedback?.q3_count)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={feedback?.q4} className="labelDy labelQuestion">
                                     {feedbackSmiley(feedback?.q4_count)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={feedback?.q5} className="labelDy labelQuestion">
                                     {feedbackSmiley(feedback?.q5_count)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label='Rating' className="labelDy labelQuestion">
                                     {feedback?.rating} / 5
                                </Form.Item>
                            </Col>
                        </Form>

                    </div>
                </div>
            </Spin>
        </Layout.Content>
    )
}

export default FeedbackView
