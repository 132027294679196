import {api} from "../index";

const path = "/categories";

const CategoryService = api.injectEndpoints({
    endpoints: (builder) => ({
        listCategory: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Category']
        }),
        getCategory: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`,
        }),
        createCategory: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Category']
        }),
        updateCategory: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Category']
        }),
        patchCategory: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Category']
        }),
        deleteCategory: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Category']
        }),
    }),
});


export const {
    useListCategoryQuery,
    useGetCategoryQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    usePatchCategoryMutation,
    useDeleteCategoryMutation,
} = CategoryService;



