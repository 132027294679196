import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Select, Skeleton, Table, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListListingQuery,
} from '../../redux/api/services/listing';
import {useListSellerQuery} from "../../redux/api/services/seller";


import ViewWidget from "../../widgets/ViewWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {convertTableParams, formatPrice} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";


const Inventory = () => {

    const navigate = useNavigate();
    const [listings, setListings] = useState([]);
    const [sellerOptions, setSellerOptions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        select: 'id,code,seller_id,name,price,qty_on_hand,status',
        include: 'seller',
        searchByOr: ['name', 'code', 'seller.name', 'seller.email'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchListings
    } = useListListingQuery(convertTableParams(tableParams));

    const {
        data: sellers, isLoading: loadingSellers, error: errorSellers, isFetching: fetchingSellers,
    } = useListSellerQuery();


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Product Number',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Seller Name',
            dataIndex: ["seller", "name"],
            key: "seller.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.seller?.image ? <Avatar src={record?.seller?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.seller?.name}</p>
                        <p>{record?.seller?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            align: "right",
            render: (v) => (
                <span>{formatPrice(v)}</span>
            )
        },
        {
            title: 'Listing Availability',
            dataIndex: 'qty_on_hand',
            key: 'qty_on_hand',
            align: "center",
            render: (v, record) => {
                if (record.status === 'active') {
                    return <span>Yes</span>
                } else {
                    return <span>No</span>
                }
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <ViewWidget onClick={() => navigate(`/listing/view/${record.id}`)}/>
                    </Col>

                </Row>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters: {
                ...tableParams.filters,
                ...filters
            },
            ...sorter,
        });
    };


    const changeSeller = (value) => {
        const listParams = {
            ...tableParams,
            callApi: true,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        }
        listParams.filters = {
            ...listParams.filters,
            seller_id: value
        }

        setTableParams(listParams);
    }


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setListings(data.data.listings);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (sellers) {
            setSellerOptions(sellers.data.sellers.map(seller => ({
                label: seller.name,
                value: seller.id
            })));
        }
    }, [sellers]);


    useEffect(() => {
        if (tableParams.callApi)
            fetchListings();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Inventory</Typography.Title>

            <Row>
                <Col span={8}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'seller'} label={'Select Seller'}>
                        {
                            loadingSellers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select placeholder="Seller Name" showSearch allowClear options={sellerOptions}
                                        optionFilterProp="label"
                                        onChange={changeSeller}
                                />
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL INVENTORY COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={listings}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default Inventory;
