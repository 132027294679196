import {Typography, Layout, Row, Col, Form, Spin, List, Avatar} from 'antd'
import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import {useGetTransactionQuery} from "../../../redux/api/services/transaction";
import {formatPrice, ucFirst} from "../../../utils/helper";

const PlanTransactionView = () => {

    const params = useParams();

    const [transaction, setTransaction] = useState({});

    const filters = 'include=listing:id,name,label,price;seller;plan:id,name';


    const {data, isLoading, error} = useGetTransactionQuery({id: params.id, filters: filters});

    useEffect(() => {
        if (data) {
            setTransaction(data.data);
        }
    }, [data]);


    return (
        <Layout.Content className='container'>

            <Spin spinning={isLoading}>

                <div style={{marginBottom: 40}}>
                    <Typography.Title level={3}>Transaction ID : {
                        transaction?.gateway_txn_id
                    }</Typography.Title>
                </div>
                <Row>
                    <Col span={12}>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: transaction.seller?.name,
                                email: transaction.seller?.email,
                                image: transaction.seller?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image}/>}
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={12}>
                        <Form
                            layout="horizontal"
                            labelAlign="left"
                            labelCol={{span: 8}}>
                            <Col span={24}>
                                <Form.Item label="Plan" className="labelDy">
                                    : {transaction?.plan?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Payment Details" className="labelDy">
                                    : {formatPrice(transaction?.amount)}
                                </Form.Item>
                            </Col>
                        </Form>
                    </Col>
                </Row>

                <div className='ordersummaryCtr'>
                    <h4>Order Summary</h4>
                    <div className='detailsCtr' style={{marginLeft: 40, marginTop: 40}}>
                        <Form
                            layout="horizontal"
                            labelAlign="left"
                            labelCol={{span: 4}}>
                            <Col span={24}>
                                <Form.Item label="Dress Name" className="labelDy">
                                    : {transaction?.listing?.name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Size" className="labelDy">
                                    : {transaction?.listing?.label}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Price" className="labelDy">
                                    : {formatPrice(transaction?.listing?.price)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Paid" className="labelDy status-label-1">
                                    : <span>{ucFirst(transaction?.status  || 'unpaid')}</span>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Payment Method" className="labelDy">
                                    : PayPal
                                </Form.Item>
                            </Col>
                        </Form>
                    </div>
                </div>
            </Spin>
        </Layout.Content>
    )
}

export default PlanTransactionView
