import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Layout, Row, Table, Tag, Typography} from 'antd'
import {debounce} from "lodash";


import {
    useRecentActivityQuery,
} from '../../redux/api/services/dashboard';


import SearchWidget from "../../widgets/SearchWidget";
import {
    convertTableParams, ucFirst,
} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";

const RecentActivity = () => {

    const [recentActivity, setRecentActivity] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        searchOne: null,
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchRecentActivity
    } = useRecentActivityQuery(convertTableParams(tableParams));


    const columns = [
        {
            title: 'Name',
            dataIndex: ['contact', 'name'],
            key: 'contact.name',
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.contact?.image ?
                            <Avatar src={record?.contact?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.contact?.name}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Mail ID',
            dataIndex: ['contact', 'email'],
            key: 'contact.email',
        },
        {
            title: 'Details',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            key: 'Status',
            render: (_, record) => {
                let status = '';
                let color = 'blue';
                if (record?.listing) {
                    status = record?.listing?.status;
                } else if(record?.order) {
                    status = record?.order?.status;
                    if (status !== 'paid') color = 'orange';

                }
                if (status === 'active' || status === 'paid') color = 'green';
                return status ? <Tag color={color}>{ucFirst(status)}</Tag> : null
            },
        },
    ];



    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            searchOne: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);



    useEffect(() => {
        if (data) {
            setRecentActivity(data.data.activities);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);



    useEffect(() => {
        if (tableParams.callApi)
            fetchRecentActivity();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Recent Activity</Typography.Title>
            <Row>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={recentActivity}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default RecentActivity;
