import React, {useState} from "react";
import {Form, Input, Modal, Select, Typography} from "antd";

import {successAlert, errorAlert, dimensionsText} from "../../../utils/helper";
import ModalFooterWidget from "../../../widgets/ModalFooterWidget";
import {
    useCreateStyleMutation,
    useUpdateStyleMutation,
} from '../../../redux/api/services/style';
import ImageUploadComponent from "../../../components/ImageUploadComponent";
import {StyleTag} from "../../../utils/enums";
import StatusWidget from "../../../widgets/StatusWidget";


const StyleAction = ({open, action, style, onClose}) => {

    const [form] = Form.useForm();
    const [status, setStatus] = useState(
        action === 'add' ? true : style.status
    );

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreateStyleMutation();
    const [update] = useUpdateStyleMutation();


    const onFinish = async (data) => {

        try {
            let response = null;
            data.status = status;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: style.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
            errorAlert(e);
        }
    }

    if (action === 'add')
        form.setFieldsValue({status: true});


    return (
        <Modal title="Style" closable={false} centered={true} open={open}
               footer={[
                   <ModalFooterWidget key="footer" loading={isLoading} onCancelClick={() => onClose(false)}
                                      onOkClick={handleOk}/>
               ]}>

            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={style}>

                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>

                <Form.Item name="image">
                      <span>
                          <Typography.Text>{dimensionsText('style')}</Typography.Text>
                      </span>
                    <ImageUploadComponent
                        tag={'style'}
                        url={style?.image}
                        label={'picture-card'}
                        onChange={(v) => {
                            form.setFieldValue('image', v);
                        }}/>
                </Form.Item>

                <Form.Item name="name" label="Name" rules={[{required: true, message: 'Required!'}]}>
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item name="tag" label="Tag" rules={[{required: true, message: 'Required!'}]}>
                    <Select fieldNames={{label: 'text', value: 'value'}} allowClear={true} options={StyleTag}/>
                </Form.Item>
                <Form.Item name="status" label="Status" rules={[{required: true, message: 'Required!'}]}>
                    <StatusWidget status={status} onChange={(v) => setStatus(v)}/>
                </Form.Item>


            </Form>

        </Modal>
    );
}

export default StyleAction;
