import React, {useState, useEffect, useCallback} from 'react'
import {Col, Form, Layout, Row, Table, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListPlanQuery,
    useDeletePlanMutation,
} from '../../redux/api/services/plan';
import EditWidget from "../../widgets/EditWidget";
import DeleteWidget from "../../widgets/DeleteWidget";
import AddWidget from "../../widgets/AddWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {confirmAlert, convertTableParams, formatPrice, successAlert, ucFirst} from "../../utils/helper";

const Plan = () => {

    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['name'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'ascend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchPlans
    } = useListPlanQuery(convertTableParams(tableParams));
    const [remove, {isLoading: loading}] = useDeletePlanMutation();


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'amount',
            key: 'amount',
            render: (v) => (
                <span>{formatPrice(v)}</span>
            )
        },
        {
            title: 'Duration',
            dataIndex: 'expiry_unit',
            key: 'expiry_unit',
            render: (v, record) => (
                record.is_expiry ? (
                    <span>{v} {ucFirst(record.expiry_period)}s</span>
                ) : 'No Expiry'
            ),
        },
        {
            title: 'Photos',
            dataIndex: 'image_count',
            key: 'image_count',
        },
        {
            title: 'Video',
            dataIndex: 'video_count',
            key: 'video_count',
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EditWidget onClick={() => navigate(`/plan/edit/${record.id}`)}/>
                    </Col>
                    {/*<Col>*/}
                    {/*    <DeleteWidget loading={loadingDelete[record.id] ?? false}*/}
                    {/*                  onClick={() => deletePlan(record.id)}/>*/}
                    {/*</Col>*/}
                </Row>
            ),
        },
    ];


    const deletePlan = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    successAlert('Plan deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);


    useEffect(() => {
        if (data) {
            setPlans(data.data.plans);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchPlans(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Plans</Typography.Title>
            <Row gutter={[5, 5]} align='middle' justify="space-between">
                <Col span={12} offset={12}>
                    <Form.Item>
                        <SearchWidget onSearch={debouncedOnSearch}/>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={plans}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Plan;
