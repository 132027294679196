import React, {useEffect, useState} from "react";
import {Col, Form, Input, Skeleton, Select, Modal, Checkbox, Row} from "antd";
import {useListCountryQuery, useListStateQuery, useListCityQuery} from "../../redux/api/services/country";
import ModalFooterWidget from "../../widgets/ModalFooterWidget";
import {useCreateAddressMutation, useUpdateAddressMutation} from "../../redux/api/services/address";
import {errorAlert, successAlert} from "../../utils/helper";
import {AddressType} from "../../utils/enums";

const {TextArea} = Input;


const AddressAction = ({open, action, address, onClose, id}) => {


    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryId, setCountryId] = useState(address?.country_id || null);
    const [stateId, setStateId] = useState(address?.state_id || null);
    const [sellerId, setSellerId] = useState(id);


    const [form] = Form.useForm();

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreateAddressMutation();
    const [update] = useUpdateAddressMutation();


    const onFinish = async (data) => {

        try {
            let response = null;
            if (action === 'add')
                response = await create({id: sellerId, data}).unwrap();
            if (action === 'edit')
                response = await update({id: sellerId, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
            errorAlert(e);
        }
    }


    const {data: countryData, isLoading: loadingCountry, error: countryError} = useListCountryQuery();


    const {
        data: stateData,
        isLoading: loadingState,
        isFetching: fetchingState,
        error: stateError,
        refetch: refetchState
    } = useListStateQuery({id: countryId});

    const {
        data: cityData,
        isLoading: loadingCity,
        isFetching: fetchingCity,
        error: cityError,
        refetch: refetchCity
    } = useListCityQuery({id: stateId}, {
        skip: false
    });


    useEffect(() => {
        setStates([]);
        setCities([]);
        refetchState();
    }, [countryId]);

    useEffect(() => {
        setCities([]);
        refetchCity();
    }, [stateId]);


    useEffect(() => {
        if (countryData) {
            setCountries(countryData.data.countries.map((country) => {
                return {label: country.name, value: country.id}
            }));
        }
    }, [countryData]);


    useEffect(() => {
        if (stateData) {
            setStates(stateData.data.states.map((state) => {
                return {label: state.name, value: state.id}
            }));
        }
    }, [stateData]);

    useEffect(() => {
        if (cityData) {
            setCities(cityData.data.cities.map((city) => {
                return {label: city.name, value: city.name}
            }));
        }
    }, [cityData]);

    useEffect(() => {
        if (action === 'add')
            form.setFieldValue('is_default', false);
    }, [action]);


    return (<Modal title="Address" closable={false} centered={true} open={open}
                   footer={[
                       <ModalFooterWidget key="footer" loading={isLoading} onCancelClick={() => onClose(false)}
                                          onOkClick={handleOk}/>
                   ]}>

        <Form form={form} onFinish={onFinish} layout="vertical" initialValues={address}>
            <Row gutter={[10, 0]}>
                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>

                <Col span={24}>
                    <Form.Item name="type" label="Type" style={{width: "100%"}}
                               rules={[{required: true, message: 'Required!'}]}>
                        <Select allowClear={true} options={AddressType} placeholder='Address Type'/>
                    </Form.Item>
                </Col>

                {/*<Col span={24}>*/}
                {/*    <Form.Item name="country_id" label="Country" style={{width: "100%"}}>*/}
                {/*        {*/}
                {/*            loadingCountry ?*/}
                {/*                <div className='skeleton-width'>*/}
                {/*                    <Skeleton.Input active={true} style={{width: "100%"}}/>*/}
                {/*                </div> :*/}
                {/*                <Select showSearch placeholder="Select Country" allowClear={true} options={countries}*/}
                {/*                        onChange={(value) => setCountryId(value)}*/}
                {/*                        optionFilterProp="label"/>*/}
                {/*        }*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <Form.Item name="state_id" label="State">*/}
                {/*        {*/}
                {/*            loadingState || fetchingState ? <div className='skeleton-width'>*/}
                {/*                    <Skeleton.Input active={true} style={{width: "100%"}}/>*/}
                {/*                </div> :*/}
                {/*                <Select showSearch placeholder="Select State" allowClear={true} options={states}*/}
                {/*                        onChange={(value) => setStateId(value)}*/}
                {/*                        optionFilterProp="label"/>*/}
                {/*        }*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
                {/*<Col span={12}>*/}
                {/*    <Form.Item name="city" label="City">*/}
                {/*        {*/}
                {/*            loadingCity || fetchingCity ? <div className='skeleton-width'>*/}
                {/*                    <Skeleton.Input active={true} style={{width: "100%"}}/>*/}
                {/*                </div> :*/}
                {/*                <Select showSearch placeholder="Select City" allowClear={true} options={cities}*/}
                {/*                        optionFilterProp="label"/>*/}
                {/*        }*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
                <Col span={24}>
                    <Form.Item name="address" label="Address" rules={[{required: true, message: 'Required!'}]}>
                        <TextArea
                            placeholder="Address"
                            autoSize={{
                                minRows: 2,
                                maxRows: 6,
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="zip_code" label="Zip Code" rules={[{required: true, message: 'Required!'}]}>
                        <Input placeholder="Zip Code"/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="is_default" label={"  "} valuePropName="checked">
                        <Checkbox>Is Default</Checkbox>
                    </Form.Item>
                </Col>

            </Row>


        </Form>
    </Modal>);


}

export default AddressAction;
