import {api} from "../index";

const path = "sliders";

const SliderService = api.injectEndpoints({
    endpoints: (builder) => ({
        listSlider: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Slider']
        }),
        getSlider: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`
        }),
        createSlider: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Slider']
        }),
        updateSlider: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Slider']
        }),
        patchSlider: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Slider']
        }),
        deleteSlider: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Slider']
        }),
    }),
});


export const {
    useListSliderQuery,
    useGetSliderQuery,
    useCreateSliderMutation,
    useUpdateSliderMutation,
    usePatchSliderMutation,
    useDeleteSliderMutation,
} = SliderService;



