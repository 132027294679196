import {api} from "../index";

const path = "plans";

const PlanService = api.injectEndpoints({
    endpoints: (builder) => ({
        listPlan: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Plan']
        }),
        getPlan: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`,
            providesTags : ['Plan']
        }),
        createPlan: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Plan']
        }),
        updatePlan: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Plan']
        }),
        patchPlan: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Plan']
        }),
        deletePlan: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Plan']
        }),
    }),
});


export const {
    useListPlanQuery,
    useGetPlanQuery,
    useCreatePlanMutation,
    useUpdatePlanMutation,
    usePatchPlanMutation,
    useDeletePlanMutation,
} = PlanService;



