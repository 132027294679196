import React from "react";
import {Col, Row} from "antd";
import {SwapLeftOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const ListingError = () => {


    return (
        <section className="page-section page-listing-cancell">
            <Row className="align-items-start">
                <Col md={{span: 6, offset: 3}} lg={{span: 12, offset: 6}} className="listingPopup">
                    <section className="paypal-status-failed">
                        <CloseCircleOutlined/>
                        <h3>The payment you made failed.</h3>
                        <Link to="/listing" className='nav-link-filled'><SwapLeftOutlined/> Back to Listings </Link>
                    </section>
                </Col>
            </Row>
        </section>
    );
}

export default ListingError;
