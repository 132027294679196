import React from "react";
import {Card, List, Typography } from "antd";

const TopCategories = ({data, loading}) => {

    return (

        <Card style={{ flex: 1, border: '1px solid #d9d9d9' }} loading={loading}>
            <Typography.Text strong>Top Selling Categories</Typography.Text>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            description={
                                <div>
                                    <Typography.Text>{item.name}</Typography.Text>
                                </div>
                            }
                        />
                        <div>{item.count}</div>
                    </List.Item>
                )}
            />


        </Card>

    );
}

export default TopCategories;
