import React from "react";
import {Form, Input, Modal} from "antd";

import {successAlert, errorAlert} from "../../utils/helper";
import ModalFooterWidget from "../../widgets/ModalFooterWidget";
import {
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
} from '../../redux/api/services/category';


const CategoryAction = ({open, action, category, onClose}) => {

    const [form] = Form.useForm();

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreateCategoryMutation();
    const [update] = useUpdateCategoryMutation();


    const onFinish = async (data) => {

        try {
            let response = null;
            data.status = 1;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id : category.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
            errorAlert(e);
        }
    }


    return (
        <Modal title="Category" closable={false} centered={true} open={open}
               footer={[
                   <ModalFooterWidget key="footer" loading={isLoading} onCancelClick={() => onClose(false)}
                                      onOkClick={handleOk}/>
               ]}>

            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={category}>

                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="name" label="Name" rules={[{required: true, message: 'Required!'}]}>
                    <Input placeholder="Name"/>
                </Form.Item>

            </Form>

        </Modal>
    );
}

export default CategoryAction;
