import React, {useEffect, useState, useRef} from "react";
import {Col, Form, Input, Modal, Row, Select, Skeleton} from "antd";

import ModalFooterWidget from "../../widgets/ModalFooterWidget";
import {useCreateSellerMutation, useUpdateSellerMutation} from "../../redux/api/services/seller";
import {errorAlert, successAlert} from "../../utils/helper";
import StatusWidget from "../../widgets/StatusWidget";
import {MaskedInput} from "antd-mask-input";
import {useListStateQuery} from "../../redux/api/services/country";
import ImageUploadComponent from "../../components/ImageUploadComponent";


const SellerAction = ({open, action, seller, onClose}) => {
    let initialValues = { ...seller, isAdmin: 1 };

    const [status, setStatus] = useState(true);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [stateId, setStateId] = useState(seller?.seller_state_id || null);

    const [form] = Form.useForm();

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading: sellerLoading}] = useCreateSellerMutation();
    const [update, {isLoading: sellerUpdateLoading}] = useUpdateSellerMutation();

    const {
        data: stateData,
        isLoading: loadingState,
        isFetching: fetchingState,
        error: stateError,
        refetch: refetchState
    } = useListStateQuery({id: 192});


    const onFinish = async (data) => {
        try {
            if (data.mobile) {
                const mobile = data.mobile.replace(/_/g, '').replace(/-/g, '').replace(/ /g, '').replace(/\+/g, "");
                if (mobile.length < 11) {
                    form.setFields([{name: 'mobile', errors: ['Please enter a valid phone number']}]);
                    return;
                }
            }
            let response = null;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: seller.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
            errorAlert(e);
        }
    }

    if (action === 'add')
        form.setFieldsValue({status: true});

    useEffect(() => {
        if (stateData) {
            setStates(stateData.data.states.map((state) => {
                return {label: state.name, value: state.id}
            }));
        }
    }, [stateData]);


    return (<Modal title="Seller" closable={false} centered={true} open={open}
                   footer={[
                       <ModalFooterWidget key="footer"
                                          loading={sellerLoading || sellerUpdateLoading}
                                          onCancelClick={() => onClose(false)}
                                          onOkClick={handleOk}/>
                   ]}>

        <Form form={form} onFinish={onFinish} layout="vertical" initialValues={ initialValues }>
            <Row gutter={[5, 0]}>
                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="isAdmin" hidden={true}>
                    <Input/>
                </Form.Item>
                <Col span={24}
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Form.Item name="image">
                        <ImageUploadComponent
                            url={seller?.image}
                            label={'picture-card'}
                            onChange={(v) => {
                                form.setFieldValue('image', v);
                            }}/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="first_name" label="First Name" rules={[{required: true, message: 'Required!'}]}>
                        <Input placeholder="First Name"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="last_name" label="Last Name">
                        <Input placeholder="Last Name"/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="email" label="Email"
                               rules={[
                                   {type: 'email', message: 'The input is not valid E-mail!'},
                                   {required: true, message: 'Required!'}
                               ]}>
                        <Input placeholder="Email"/>
                    </Form.Item>
                </Col>
                {
                    action === 'add' && <Col span={12}>
                        <Form.Item name="password" label="Password"
                                   rules={[
                                       {required: true, message: 'Required!'}
                                   ]}>
                            <Input placeholder="Password"/>
                        </Form.Item>
                    </Col>
                }
                <Col span={12}>
                    <Form.Item name="mobile" label="Mobile">
                        <MaskedInput mask={'+0 000-000-0000'}/>
                    </Form.Item>
                </Col>


                <Col span={24}>
                    <Form.Item name="seller_address" label="Street">
                        <Input placeholder="Street"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="seller_state_id" label="State">
                        {
                            loadingState || fetchingState ? <div className='skeleton-width'>
                                    <Skeleton.Input active={true} style={{width: "100%"}}/>
                                </div> :
                                <Select showSearch placeholder="Select State" allowClear={true} options={states}
                                        onChange={(value) => setStateId(value)}
                                        optionFilterProp="label"/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="seller_city" label="City">
                        <Input placeholder="City"/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="seller_zip_code" label="Zip Code">
                        <Input placeholder="Zip Code"/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="status" label="Status">
                        <StatusWidget status={status} onChange={(v) => setStatus(v)}/>
                    </Form.Item>
                </Col>

            </Row>

        </Form>
    </Modal>);
}

export default SellerAction;
