import {api} from "../index";

const path = "/buyers";

const BuyerService = api.injectEndpoints({
    endpoints: (builder) => ({
        listBuyer: builder.query({
            query: (filters = '') => `${path}?${filters}`,
        }),
        getBuyer: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
    }),
});


export const {
    useListBuyerQuery,
    useGetBuyerQuery,
} = BuyerService;



