import {Typography, Layout, Row, Col, Form, Spin, List, Avatar, Button, Select} from 'antd'
import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetInvoiceQuery,
    useDownloadMutation,
} from "../../redux/api/services/invoice";
import Logo from "../../assets/Weddinggown.png"
import dayjs from "dayjs";
import {errorAlert, formatPrice} from "../../utils/helper";


const InvoiceView = () => {

    const navigate = useNavigate();

    const params = useParams();

    const [invoice, setInvoice] = useState({});

    const filters = 'include=detail.listing:id,name,label;buyer;seller';


    const {data, isLoading, isFetching, error} = useGetInvoiceQuery({
        id: params.id,
        filters: filters
    });

    const [download, {isLoading: isDownloading}] = useDownloadMutation();

    const downloadInvoice = () => {
        download(invoice.id)
            .unwrap()
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', invoice?.invoice_no + '.pdf');
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                errorAlert(err.data)
            })

    }



    useEffect(() => {
        if (data) {
            setInvoice(data.data);
        }
    }, [data]);


    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Invoice

                <Button style={{
                    paddingLeft: 20,
                    color: '#CDA96D',
                    float: 'right'
                }} type="link" onClick={() => navigate(-1)}> Back</Button>
            </Typography.Title>
            <Spin spinning={isLoading || isFetching}>
                <div className='viewheaderCtr11'>
                    <Row>
                        <Col md={24} style={{textAlign: 'right'}}>
                            <Button key="sub" type="primary" loading={isDownloading}
                                    onClick={downloadInvoice}>
                                Download PDF
                            </Button>
                        </Col>

                        <Col md={16} offset={4}>
                            <div className="wg-invoice">
                                <div className="invoice-header">
                                    <Row style={{width:'100%'}}>
                                        <Col md="4" style={{width:'20%'}}>
                                            <div className="invoice-logo">
                                                <div>
                                                    <img src={Logo} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="20" style={{width:'80%'}}>
                                            <div className="invoice-no">
                                                <p>Receipt <strong>{invoice?.invoice_no}</strong> | {dayjs(invoice?.date).format("MMMM DD, YYYY")}</p>
                                            </div>
                                            <div className="seller-address">
                                                <h4>Seller</h4>
                                                <address>
                                                    {invoice?.seller?.name}<br />
                                                    {invoice?.seller_address && (
                                                        <>
                                                            <>{invoice?.seller_address.address}<br /></>
                                                            <>{invoice?.seller_address.state?.name}<br /></>
                                                            <>{invoice?.seller_address.city}<br /></>
                                                            <>{invoice?.seller_address.zip_code}<br /></>
                                                        </>
                                                    )}
                                                </address>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{width:'100%'}}>
                                            <h4 className="invoice-address-title">
                                                <span>Billing For</span>
                                                <span>Shipping To</span>
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" style={{width:"50%"}}>
                                            <ul style={{listStyle: "none"}}>
                                                <li className="bolder">{invoice?.buyer?.name}</li>
                                                {invoice?.billing_address && (
                                                    <>
                                                        <li>{invoice?.billing_address.address}</li>
                                                        <li>{invoice?.billing_address.state?.name}</li>
                                                        <li>{invoice?.billing_address.city}</li>
                                                        <li>{invoice?.billing_address.zip_code}</li>
                                                    </>
                                                )}
                                            </ul>
                                        </Col>
                                        <Col md="12" className="text-end" style={{width:"50%"}}>
                                            <ul style={{listStyle: "none"}}>
                                                <li className="bolder">{invoice?.buyer?.name}</li>
                                                {invoice?.shipping_address && (
                                                    <>
                                                        <li>{invoice?.shipping_address.address}</li>
                                                        <li>{invoice?.shipping_address.state?.name}</li>
                                                        <li>{invoice?.shipping_address.city}</li>
                                                        <li>{invoice?.shipping_address.zip_code}</li>
                                                    </>
                                                )}
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="invoice-content">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th width="60%">PRODUCT</th>
                                            <th width="10%">QTY</th>
                                            <th width="15%">PRICE</th>
                                            <th width="15%">TOTAL</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{invoice?.detail?.name}</td>
                                            <td>{invoice?.detail?.qty}</td>
                                            <td>{formatPrice(invoice?.detail?.price)}</td>
                                            <td>{formatPrice(invoice?.detail?.amount)}</td>
                                        </tr>

                                        <tr>
                                            <th colSpan={3} className="text-end">SHIPPING</th>
                                            <td>{formatPrice(invoice?.shipping_cost)}</td>
                                        </tr>
                                        <tr>
                                            <th colSpan={3} className="text-end">TOTAL</th>
                                            <td>{formatPrice(invoice?.grand_total)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="invoice-footer">
                                    <Row>
                                        <Col>
                                            <h4 className="footer-title">Thank you for your purchase!</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4" style={{width:'25%'}}>
                                            <p>© Wedding Gown, {dayjs().format("YYYY")}</p>
                                        </Col>
                                        <Col md="20" style={{width:'75%'}}>
                                            <ul style={{listStyle: "none"}}>
                                                <li>www.weddinggown.com</li>
                                                <li>wecanhelp@weddinggown.com</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>
            </Spin>
        </Layout.Content>
    )
}

export default InvoiceView
