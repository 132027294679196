import React, {useState, useEffect, useCallback} from 'react'
import {Col, Layout, Row, Table, Typography} from 'antd'
import {debounce} from "lodash";


import {
    useListPopularSearchQuery
} from '../../redux/api/services/popular-search';


import SearchWidget from "../../widgets/SearchWidget";
import {
    convertDateTime,
    convertTableParams
} from "../../utils/helper";

const PopularSearch = () => {

    const [popularSearch, setPopularSearch] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        include: 'user',
        searchByOr: ['value', 'count', 'user.name'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'updated_at'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchSearch,
    } = useListPopularSearchQuery(convertTableParams(tableParams));


    const columns = [
        {
            title: 'Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (v) => (
                <span>{convertDateTime(v)}</span>
            )
        },
        {
            title: 'User',
            dataIndex: ['user', 'name'],
            key: 'user.name',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count'
        }
    ];


    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setPopularSearch(data.data.search);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchSearch();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Popular Search</Typography.Title>
            <Row>
                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL POPULAR SEARCH COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={popularSearch}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default PopularSearch;
