import React, { useState, useEffect, useCallback } from 'react'
import {Avatar, Col, Layout, List, Row, Table, Typography} from 'antd'
import { debounce } from "lodash";
import {useNavigate, useParams} from "react-router-dom";


import {
    useListListingQuery,
} from '../../redux/api/services/listing';
import {useGetSellerQuery} from "../../redux/api/services/seller";

import SearchWidget from "../../widgets/SearchWidget";
import { convertTableParams, formatPrice } from "../../utils/helper";
import EditWidget from '../../widgets/EditWidget'
import EyeWidget from '../../widgets/EyeWidget'

const SellerListing = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [listings, setListings] = useState([]);
    const [seller, setSeller] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        filters: {
            seller_id: params.id
        },
        include: 'plan:id,name',
        searchByOr: ['name', 'code', 'plan.name'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data: sellerData, isLoading: sellerLoading, error: sellerError, isFetching: sellerFetching,
    } = useGetSellerQuery({
        id : params.id,
        filters : 'select=id,name,email,mobile'
    }, {
        skip: !params.id
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchListings
    } = useListListingQuery(convertTableParams(tableParams));


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Product Number',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (v) => (
                <span>{formatPrice(v)}</span>
            )
        },
        {
            title: 'Plan',
            dataIndex: ['plan', 'name'],
            key: 'plan.name'
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EyeWidget onClick={() => navigate(`/listing/view/${record.id}`)} />
                    </Col>
                    <Col>
                        <EditWidget onClick={() => navigate(`/listing/edit/${record.id}`)} />
                    </Col>

                </Row>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);



    useEffect(() => {
        if (data) {
            setListings(data.data.listings);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (sellerData) {
            setSeller(sellerData.data);
        }
    }, [sellerData]);


    useEffect(() => {
        if (tableParams.callApi)
            fetchListings(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);



    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Seller Details</Typography.Title>
            <Row gutter={[10, 40]} align='middle' justify="space-between">
                <Col span={12}>
                    <List
                        itemLayout="horizontal"
                        dataSource={[{
                            name: seller?.name,
                            email: seller?.email
                        }
                        ]}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar />}
                                    title={<a href="#">{item.name}</a>}
                                    description={item.email}
                                />
                            </List.Item>
                        )}
                    />
                </Col>

                <Col span={12}>
                    <Row gutter={10} justify="end">
                        <Col span={24}>
                            <SearchWidget onSearch={debouncedOnSearch} />
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={listings}
                        columns={columns} rowKey="id" />
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default SellerListing;
