import { Route, Routes} from 'react-router-dom';
import './App.css';


import Layouts from './layouts';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Listing from './pages/listing';
import ListingAction from './pages/listing/action';
import Customer from './pages/customer';
import CustomerOverview from './pages/customer/overview';
import Seller from './pages/seller';
import Category from './pages/category';
import Inventory from './pages/inventory';
import ChatBox from './pages/chat-box';
import ChatBoxView from './pages/chat-box/view';
import PlanTransaction from './pages/payment/plan';
import PlanTransactionView from './pages/payment/plan/view';
import ShippingTransaction from './pages/payment/shipping';
import ShippingTransactionView from './pages/payment/shipping/view';
import OrderTransaction from './pages/payment/order';
import OrderTransactionView from './pages/payment/order/view';
import Plan from './pages/plan';
import PlanAction from "./pages/plan/action";
import Page from './pages/page';
import PageAction from "./pages/page/action";
import Size from "./pages/master/size";
import Measurement from "./pages/master/measurement";
import Appearance from "./pages/master/appearance";
import Designer from "./pages/master/designer";
import Style from "./pages/master/style";
import SellerAction from "./pages/seller/action";
import ListingView from "./pages/listing/view";
import SellerListing from "./pages/seller/listing";
import CategoryDetail from "./pages/category/detail";

import SellerView from "./pages/seller/overview";
import Order from "./pages/sales/order";
import OrderView from "./pages/sales/order-view";
import InvoiceView from "./pages/sales/invoice-view";
import SellerListingReport from "./pages/reports/seller-listing";
import TopCustomers from "./pages/reports/top-customer";
import TopSeller from "./pages/reports/top-seller";
import ListingSuccess from "./pages/redirect/listing-success";
import ListingError from "./pages/redirect/listing-error";
import OnboardError from "./pages/redirect/onboard-error";
import OnboardSuccess from "./pages/redirect/onboard-success";
import Feedback from "./pages/feedback";
import FeedbackView from "./pages/feedback/view";
import RecentActivity from "./pages/reports/recent-activity";
import DirectSold from "./pages/direct-sold";
import Logout from "./pages/logout";
import NewsLetter from "./pages/news-letter";
import Slider from "./pages/slider";
import ContactUs from "./pages/contact-us";
import Testimonial from "./pages/testimonial";
import DontSellInfo from "./pages/dont-sell-info";
import DontSellInfoView from "./pages/dont-sell-info/view";
import Banner from "./pages/banner";
import PopularSearch from "./pages/popular-search";



function App() {
    return (
        <div className='main_container'>
            <Routes>
                <Route path='/' element={<Login/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/logout' element={<Logout/>}/>
                <Route path="/" element={<Layouts/>}>
                    <Route path='/' element={<Dashboard/>}/>
                    <Route path='/dashboard' element={<Dashboard/>}/>
                    <Route path='/listing' element={<Listing/>}/>
                    <Route path='/direct-sold' element={<DirectSold/>}/>
                    <Route path='/listing/add' element={<ListingAction/>}/>
                    <Route path='/listing/view/:id' element={<ListingView/>}/>
                    <Route path='/direct-sold/add' element={<ListingAction/>}/>
                    <Route path='/direct-sold/view/:id' element={<ListingView/>}/>
                    <Route path='/listing/edit/:id' element={<ListingAction />}/>
                    <Route path='/order' element={<Order/>}/>
                    <Route path='/order/:id' element={<OrderView/>}/>
                    <Route path='/invoice/:id' element={<InvoiceView/>}/>
                    <Route path='/customer' element={<Customer/>}/>
                    <Route path='/customer/:id' element={<CustomerOverview/>}/>
                    <Route path='/seller/:id/listing' element={<SellerListing/>}/>
                    <Route path='/seller' element={<Seller/>}/>
                    <Route path='/seller/view/:id' element={<SellerView/>}/>
                    <Route path='/seller/:action/:id?' element={<SellerAction/>}/>
                    <Route path='/category' element={<Category/>}/>
                    <Route path='/category/:id/details' element={<CategoryDetail/>}/>
                    <Route path='/inventory' element={<Inventory/>}/>
                    <Route path='/chat-box' element={<ChatBox/>}/>
                    <Route path='/chat-box/:id' element={<ChatBoxView/>}/>
                    <Route path='/payment/plan' element={<PlanTransaction/>}/>
                    <Route path='/payment/plan/:id' element={<PlanTransactionView/>}/>
                    <Route path='/payment/shipping' element={<ShippingTransaction/>}/>
                    <Route path='/payment/shipping/:id' element={<ShippingTransactionView/>}/>
                    <Route path='/payment/order' element={<OrderTransaction/>}/>
                    <Route path='/payment/order/:id' element={<OrderTransactionView/>}/>
                    <Route path='/plan' element={<Plan/>}/>
                    <Route path='/plan/:action/:id?' element={<PlanAction/>}/>
                    <Route path='/page' element={<Page/>}/>
                    <Route path='/page/:action/:id?' element={<PageAction/>}/>
                    <Route path='/master/size' element={<Size/>}/>
                    <Route path='/master/measurement' element={<Measurement/>}/>
                    <Route path='/master/appearance' element={<Appearance/>}/>
                    <Route path='/master/style' element={<Style/>}/>
                    <Route path='/master/designer' element={<Designer/>}/>
                    <Route path='/slider' element={<Slider/>}/>
                    <Route path='/banner' element={<Banner/>}/>
                    <Route path='/feedback' element={<Feedback/>}/>
                    <Route path='/feedback/:id' element={<FeedbackView/>}/>
                    <Route path='/dont-sell-info' element={<DontSellInfo/>}/>
                    <Route path='/dont-sell-info/:id' element={<DontSellInfoView/>}/>
                    <Route path='/news-letter' element={<NewsLetter/>}/>
                    <Route path='/contact-us' element={<ContactUs/>}/>
                    <Route path='/popular-search' element={<PopularSearch/>}/>
                    <Route path='/reports/top-seller' element={<TopSeller/>}/>
                    <Route path='/reports/seller-listing' element={<SellerListingReport/>}/>
                    <Route path='/reports/top-customer' element={<TopCustomers/>}/>
                    <Route path='/reports/recent-activity' element={<RecentActivity/>}/>
                    <Route path='/testimonial' element={<Testimonial/>}/>



                    <Route path='/listing-success/:id' element={<ListingSuccess/>}/>
                    <Route path='/listing-error' element={<ListingError/>}/>
                    <Route path='/onboard-success/:id' element={<OnboardSuccess/>}/>
                    <Route path='/onboard-error' element={<OnboardError/>}/>

                </Route>
            </Routes>
        </div>
    );
}

export default App;
