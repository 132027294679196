import {api} from "../index";

const path = "/dashboard";

const DashboardService = api.injectEndpoints({
    endpoints: (builder) => ({
        listCards: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        sellerReport: builder.query({
            query: (filters = '') => `${path}/reports/seller?${filters}`,
        }),
        salesReport: builder.query({
            query: (filters = '') => `${path}/reports/sales?${filters}`,
        }),
        sellerDetailReport: builder.query({
            query: (filters = '') => `${path}/reports/seller-listing?${filters}`,
        }),
        topCustomers: builder.query({
            query: (filters = '') => `${path}/top-customers?${filters}`,
        }),
        sellerSales: builder.query({
            query: (filters = '') => `${path}/reports/top-sellers?${filters}`,
        }),
        recentActivity: builder.query({
            query: (filters = '') => `/recent-activity?${filters}`,
        }),
        downloadSellerListing: builder.mutation({
            query: (data) => ({
                url: `${path}/reports/seller-listing`,
                method: 'POST',
                body: data,
                responseHandler: async (res) => {
                    if (res.status === 200) {
                        return await res.blob();
                    } else {
                        return await res.text();
                    }
                },
            })
        }),
        downloadTopSeller: builder.mutation({
            query: (data) => ({
                url: `${path}/reports/top-sellers`,
                method: 'POST',
                body: data,
                responseHandler: async (res) => {
                    if (res.status === 200) {
                        return await res.blob();
                    } else {
                        return await res.text();
                    }
                },
            })
        }),
        downloadTopCustomer: builder.mutation({
            query: (data) => ({
                url: `${path}/reports/top-customers`,
                method: 'POST',
                body: data,
                responseHandler: async (res) => {
                    if (res.status === 200) {
                        return await res.blob();
                    } else {
                        return await res.json();
                    }
                },
            })
        }),

    }),
});


export const {
    useListCardsQuery,
    useSellerReportQuery,
    useSalesReportQuery,
    useTopCustomersQuery,
    useSellerDetailReportQuery,
    useSellerSalesQuery,
    useRecentActivityQuery,
    useDownloadSellerListingMutation,
    useDownloadTopSellerMutation,
    useDownloadTopCustomerMutation,
} = DashboardService;



