import {Button, Layout, Space} from "antd"

import {useDispatch} from "react-redux"
import {logOut} from "../redux/slice/authSlice"
import {useNavigate} from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    return (
        <Layout
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
           <div>
               <Space>
                   <h1>Are you sure you want to logout?</h1>
                   <br/>
                   <Button  type="primary" onClick={() => navigate("/dashboard")}>No</Button>
                   <Button  type="primary" onClick={() => {
                       dispatch(logOut())
                       navigate("/login")
                   }}
                   >Yes</Button>
               </Space>

           </div>
        </Layout>
    )
}
export default Logout
