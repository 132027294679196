import {api} from "../index";

const path = "/messages";

const MessageService = api.injectEndpoints({
    endpoints: (builder) => ({
        listMessage: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getMessage: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
    }),
});


export const {
    useListMessageQuery,
    useGetMessageQuery,
} = MessageService;



