import React from "react";
import {Avatar, Button, Col, Row, Table, Tag, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {UserOutlined} from "@ant-design/icons";
import {ucFirst} from "../../utils/helper";

const RecentActivity = ({data, loading}) => {

    const navigate = useNavigate();

    const columns = [
        {
            title: 'Name',
            dataIndex: ['contact', 'name'],
            key: 'contact.name',
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.contact?.image ?
                            <Avatar src={record?.contact?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.contact?.name}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Mail ID',
            dataIndex: ['contact', 'email'],
            key: 'contact.email',
        },
        {
            title: 'Details',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            key: 'Status',
            render: (_, record) => {
                let status = '';
                let color = 'blue';
                if (record?.listing) {
                    status = record?.listing?.status;
                } else if(record?.order) {
                    status = record?.order?.status;
                    if (status !== 'paid') color = 'orange';
                }
                if (status === 'active' || status === 'paid') color = 'green';
                return status ? <Tag color={color}>{ucFirst(status)}</Tag> : null
            },
        },
    ];


    return (
        <>
            <Typography.Title level={4}>Recent Activity</Typography.Title>
            <Row>
                <Col span={24}>
                    <Table columns={columns} dataSource={data} pagination={false} loading={loading}/>
                </Col>
            </Row>

            <div style={{textAlign: 'right'}}>
                <Button style={{
                    padding: 0,
                    color: 'rgb(86, 74, 53)'
                }} type="link" onClick={() => navigate('/reports/recent-activity')}> VIEW MORE</Button>
            </div>
        </>
    );
}

export default RecentActivity;
