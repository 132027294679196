import {Button, Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";
import React from "react";

const EditWidget = ({onClick, loading = false}) => {

    return (
        <Tooltip title="Edit">
            <Button loading={loading} type="link" onClick={onClick}
                    icon={<EditOutlined className="icon"/>}/>
        </Tooltip>
    )
}

export default EditWidget;
