import React, {useState} from "react";
import {Form, Input, Modal, Typography} from "antd";

import {successAlert, errorAlert, dimensionsText} from "../../utils/helper";
import ModalFooterWidget from "../../widgets/ModalFooterWidget";
import {
    useCreateBannerMutation,
    useUpdateBannerMutation,
} from '../../redux/api/services/banner';
import FileUploadComponent from "../../components/FileUploadComponent";
import StatusWidget from "../../widgets/StatusWidget";
import ImageUploadComponent from "../../components/ImageUploadComponent";


const BannerAction = ({open, action, banner, onClose}) => {

    const [form] = Form.useForm();
    const [status, setStatus] = useState(
        action === 'add' ? true : banner.status
    );

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreateBannerMutation();
    const [update] = useUpdateBannerMutation();


    const onFinish = async (data) => {

        try {
            let response = null;
            data.status = status;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: banner.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
            errorAlert(e);
        }
    }

    if(action === 'add')
        form.setFieldsValue({status: true});


    return (
        <Modal title="Banner" closable={false} centered={true} open={open}
               footer={[
                   <ModalFooterWidget key="footer" loading={isLoading} onCancelClick={() => onClose(false)}
                                      onOkClick={handleOk}/>
               ]}>

            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={banner}>

                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>

                <Form.Item name="value" >
                     <span>
                          <Typography.Text>{dimensionsText('banner')}</Typography.Text>
                      </span>
                    <ImageUploadComponent
                        tag={'banner'}
                        url={banner?.value}
                        label={'picture-card'}
                        onChange={(v) => {
                            form.setFieldValue('value', v);
                        }}/>
                </Form.Item>
                <Form.Item name="name" label="Name" rules={[{required: false, message: 'Required!'}]}>
                    <Input placeholder="Name"/>
                </Form.Item>

                <Form.Item name="status" label="Status" rules={[{required: true, message: 'Required!'}]}>
                    <StatusWidget status={status} onChange={(v) => setStatus(v)}/>
                </Form.Item>


            </Form>

        </Modal>
    );
}

export default BannerAction;
