import {Button, Tooltip} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import React from "react";

const ApproveWidget = ({onClick, loading = false}) => {

    return (
        <Tooltip title="Approve">
            <Button loading={loading} type="link" onClick={onClick}
                    icon={<CheckOutlined className="icon"/>}/>
        </Tooltip>
    )
}

export default ApproveWidget;
