import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Select, Skeleton, Table, Typography, DatePicker} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";


import {
    useTopCustomersQuery,
    useDownloadTopCustomerMutation
} from '../../redux/api/services/dashboard';
import {useListBuyerQuery} from "../../redux/api/services/buyer";

import SearchWidget from "../../widgets/SearchWidget";
import {
    convertTableParams,
    formatPrice,
    lastRangePresetsArray,
    displayDateFormat,
    convertDayjsToDate, errorAlert
} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";
import AddWidget from "../../widgets/AddWidget";


const {RangePicker} = DatePicker;

const SellerSalesReport = () => {

    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const [buyerOptions, setBuyerOptions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [date, setDate] = useState(null);

    const [tableParams, setTableParams] = useState({
        callApi: false,
        searchOne: null,
        pagination: {
            current: 1,
            pageSize: 10
        },
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchTopCustomers
    } = useTopCustomersQuery(convertTableParams(tableParams));

    const [downloadTopCustomer] = useDownloadTopCustomerMutation();


    const {
        data: buyer, isLoading: loadingBuyers, error: errorBuyers, isFetching: fetchingBuyers,
    } = useListBuyerQuery();


    const columns = [
        {
            title: 'Id',
            dataIndex: ['buyer', 'id'],
            key: 'buyer.id',
            align: "center",
        },
        {
            title: 'Buyer',
            dataIndex: ["buyer", "name"],
            key: "buyer.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.buyer?.image ? <Avatar src={record?.buyer?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.buyer?.name}</p>
                        <p>{record?.buyer?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (v, record) => {
                return <span>{formatPrice(v)}</span>
            }
        }
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            searchOne: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const downloadCSV = () => {

        let dlData = {};
        if (date !== null) {
            dlData = convertFromToDate(date);
        }

        downloadTopCustomer(dlData)
            .unwrap()
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'top-customers.csv');
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                errorAlert(err?.data?.error)
            })
    }




    const changeSeller = (value) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            filters: {
                buyer_id: value,
            },
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const convertFromToDate = (date) => {
        const filterBW = {};
        if (date) {
            const termStartTimestamp = [];
            date.forEach((item, index) => {
                termStartTimestamp[index] = convertDayjsToDate(item);
            });
            filterBW['created_at'] = termStartTimestamp;
        }

        return filterBW;
    }


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setCustomers(data.data.top_customers);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (buyer) {
            setBuyerOptions(buyer.data.buyers.map(buyer => ({
                label: buyer.name,
                value: buyer.id
            })));
        }
    }, [buyer]);

    useEffect(() => {

        setTableParams({
            ...tableParams,
            callApi: true,
            filtersBW: convertFromToDate(date),
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });


    }, [date]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchTopCustomers();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Top Customers</Typography.Title>

            <Row>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'buyer'} label={'Select Buyer'}>
                        {
                            loadingBuyers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select showSearch optionFilterProp="label"
                                        placeholder="Buyer Name" allowClear options={buyerOptions}
                                        onChange={changeSeller}/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Date"}>
                        <RangePicker presets={lastRangePresetsArray} value={date}
                                     onChange={(v) => setDate(v)}
                                     format={displayDateFormat}
                                     disabledDate={(current) => current && current > dayjs().endOf('day')}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <AddWidget onClick={() => downloadCSV()} label='Download CSV'/>
                </Col>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>


            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={customers}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default SellerSalesReport;
