import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";


const SearchWidget = ({onSearch, loading = false}) => {

    return (
        <Input placeholder="Search" onChange={onSearch}
               onPressEnter={onSearch} allowClear
               suffix={<SearchOutlined/>}/>
    )
}

export default SearchWidget;
