import {api} from "../index";

const path = "/transactions";

const TransactionService = api.injectEndpoints({
    endpoints: (builder) => ({
        listTransaction: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getTransaction: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
    }),
});


export const {
    useListTransactionQuery,
    useGetTransactionQuery,
} = TransactionService;



