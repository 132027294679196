import React, {useState, useEffect} from "react";
import {Checkbox, Col, Form, Input, InputNumber, Layout, Row, Select, Spin, Switch, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Editor} from '@tinymce/tinymce-react';


import {successAlert, errorAlert, ucFirst} from "../../utils/helper";
import {
    useGetPlanQuery,
    useCreatePlanMutation,
    useUpdatePlanMutation,
} from '../../redux/api/services/plan';
import {PeriodEnum} from "../../utils/enums";
import StatusWidget from "../../widgets/StatusWidget";
import FormActionWidget from "../../widgets/FormActionWidget";

const PlanAction = () => {

    const params = useParams();
    const navigate = useNavigate();

    const tinymce_api_key = process.env.REACT_APP_TINYMCE_API_KEY;

    const [status, setStatus] = useState(true);
    const [adminOnly, setAdminOnly] = useState(false);
    const [description, setDescription] = useState(null);
    const [isExpiry, setIsExpiry] = useState(null);
    const [action, setAction] = useState(params.action);
    const [id, setId] = useState(params.id);
    const [form] = Form.useForm();

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreatePlanMutation();
    const [update] = useUpdatePlanMutation();


    const {data, isLoading: loadingPlan, error} = useGetPlanQuery(id, {
        skip: action === 'add'
    });


    const onFinish = async (data) => {

        try {
            data.description = description;
            let response = null;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: id, data}).unwrap();
            successAlert(response?.message);

            navigate('/plan');

        } catch (e) {
            errorAlert(e);
        }
    }

    useEffect(() => {
        if (data && id) {
            form.setFieldsValue(data.data);
            setIsExpiry(data.data.is_expiry);
            setDescription(data.data.description);
            setStatus(data.data.status);
            setAdminOnly(data.data.admin_only);
            setAction(params.action);
            setId(params.id);
        }
    }, [data]);


    return (
        <Layout.Content className="container">
            <Typography.Title level={3}>Plan</Typography.Title>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Spin spinning={loadingPlan}>

                    <Row gutter={16}>
                        <Form.Item name="id" hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Col span={6}>
                            <Form.Item name="name" label="Name" rules={[{required: true, message: 'Required!'}]}>
                                <Input placeholder="Name"/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="short_name" label="Column Name" rules={[{required: true, message: 'Required!'}]}>
                                <Input placeholder="Column Name"/>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item name="short_description" label="Short Description"
                                       rules={[{required: true, message: 'Required!'}]}>
                                <Input placeholder="Short Description"/>
                            </Form.Item>
                        </Col>

                        {isExpiry ? (
                            <Col span={8}>
                                <Form.Item label="Duration">
                                    <Input.Group compact>
                                        <Form.Item name='expiry_unit' noStyle
                                                   rules={[{required: isExpiry === true, message: 'Required!'}]}>
                                            <InputNumber controls={false} style={{width: '40%'}}
                                                         placeholder="Unit"/>
                                        </Form.Item>
                                        <Form.Item name='expiry_period' noStyle
                                                   rules={[{required: isExpiry === true, message: 'Required!'}]}>
                                            <Select placeholder="Period" allowClear
                                                    style={{width: '60%'}}
                                                    options={
                                                        Object.values(PeriodEnum).map((item, index) => {
                                                            return {
                                                                label: ucFirst(item),
                                                                value: item
                                                            }
                                                        })
                                                    }>
                                            </Select>
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                        ) : null}
                        <Col span={6}>
                            <Form.Item name="amount" label="Price" rules={[{required: true, message: 'Required!'}]}>
                                <InputNumber controls={false} placeholder="Price" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="image_count" label="Photos"
                                       rules={[{required: true, message: 'Required!'}]}>
                                <InputNumber controls={false} placeholder="Photos" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="video_count" label="Videos">
                                <InputNumber controls={false} placeholder="Videos" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="narrative_length" label="Narrative Length"
                                       rules={[{required: true, message: 'Required!'}]}>
                                <InputNumber controls={false} placeholder="Narrative Length"
                                             style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="status" label="Status"
                                       rules={[{required: true, message: 'Required!'}]}>
                                <StatusWidget status={status} onChange={(v) => setStatus(v)}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="admin_only" label="Admin Only"
                                       rules={[{required: true, message: 'Required!'}]}>
                                <Switch checked={adminOnly} checkedChildren="Yes" unCheckedChildren="No"
                                        onChange={(v) => setAdminOnly(v)}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="is_expiry" label={"  "} valuePropName="checked">
                                <Checkbox checked={isExpiry} onChange={(e) => {
                                    setIsExpiry(e.target.checked);
                                }}>Is Expiry</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item  label="Description">
                                <Editor
                                    value={description}
                                    apiKey={tinymce_api_key}
                                    init={{
                                        height: 300,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist | ',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    onEditorChange={(content, editor) => {
                                        setDescription(content)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <FormActionWidget onSaveClick={handleOk} onCancelClick={
                        () => {
                            navigate(-1);
                        }
                    } isLoading={isLoading}/>
                </Spin>
            </Form>
        </Layout.Content>
    );
}

export default PlanAction;
