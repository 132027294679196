import {Select} from "antd";
import React from "react";


const DashboardFilterWidget = ({onSearch}) => {

    const handleDateTypeChange = (value) => {
        onSearch(value);
    }

    return (
            <Select
                defaultValue="last7days"
                onChange={handleDateTypeChange}
                style={{width: "100%"}}
                options={[
                    {value: 'last7days', label: 'Last 7 Days'},
                    {value: 'last-month', label: 'Last Month'},
                    {value: 'this-year', label: 'This Year'},
                    {value: 'last-year', label: 'Last Year'},
                ]}
            />

    )
}

export default DashboardFilterWidget;
