import {api} from "../index";

const path = "masters/appearances";

const AppearanceService = api.injectEndpoints({
    endpoints: (builder) => ({
        listAppearance: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Appearance']
        }),
        getAppearance: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`,
            invalidatesTags: ['Appearance']
        }),
        createAppearance: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Appearance']
        }),
        updateAppearance: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Appearance']
        }),
        patchAppearance: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Appearance']
        }),
        deleteAppearance: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Appearance']
        }),
    }),
});


export const {
    useListAppearanceQuery,
    useGetAppearanceQuery,
    useCreateAppearanceMutation,
    useUpdateAppearanceMutation,
    usePatchAppearanceMutation,
    useDeleteAppearanceMutation,
} = AppearanceService;



