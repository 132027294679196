import {Typography, Layout, Row, Col, Divider, Form, Spin, List, Avatar, Button, Select, Space} from 'antd'
import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetOrderQuery,
    useDownloadOrderLabelMutation,
    useUpdateStatusOrderMutation
} from "../../redux/api/services/order";
import {errorAlert, formatPrice, ucFirst} from "../../utils/helper";
import {orderStatus} from "../../utils/enums";

const OrderView = () => {

    const navigate = useNavigate();

    const params = useParams();

    const [order, setOrder] = useState({});

    const [status, setStatus] = useState(null);

    const [disableStatus, setDisableStatus] = useState(false);

    const filters = 'include=detail.listing:id,name,label;invoice:id,order_id';


    const {data, isLoading, isFetching, error, refetch: refetchOrder} = useGetOrderQuery({
        id: params.id,
        filters: filters
    });

    const [downloadOrderLabel, {isLoading: labelLoading}] = useDownloadOrderLabelMutation();
    const [updateStatusOrder, {isLoading: statusLoading}] = useUpdateStatusOrderMutation();


    const downloadLabel = async () => {
        downloadOrderLabel(order.id)
            .unwrap()
            .then(async (response) => {
                let one = await new Blob([response], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(one);
                window.open(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', order?.order_no + '.pdf');
                // link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                errorAlert(err.data)
            })

    }

    const changeStatus = (value) => {
        const data = {
            status: value
        }
        updateStatusOrder({id: order.id, data}).unwrap()
            .then((response) => {
                refetchOrder();

            })
            .catch((err) => {
                errorAlert(err.data)
            })
    }

    const disabledStatus = (status, oldStatus) => {

        if (oldStatus === 'confirmed') {
            if (status === 'pending')
                return true;
        } else if (oldStatus === 'pending') {
            return true;
        } else if (oldStatus === 'shipped') {
            if (status === 'pending' || status === 'confirmed')
                return true;
        } else if (oldStatus === 'in transit') {
            if (status === 'pending' || status === 'confirmed' || status === 'shipped')
                return true;
        } else if (oldStatus === 'delivered') {
            if (status === 'pending' || status === 'confirmed' || status === 'shipped' || status === 'in transit')
                return true;
        }
        return false;
    }


    useEffect(() => {
        if (data) {
            setOrder(data.data);
            setStatus(data.data.status);
            if (data.data.status === 'confirmed')
                setDisableStatus(true)
            else
                setDisableStatus(false)

        }
    }, [data]);


    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Orders Details
                <span style={{float: 'right', fontSize: '15px'}}>{order?.order_no}</span>
            </Typography.Title>

            <Spin spinning={isLoading || isFetching}>

                <Row justify="end" align="top">
                    <Space className='orderButton'>

                        <Select placeholder="Status"
                                disabled={disableStatus}
                                onChange={changeStatus}
                                loading={statusLoading}
                                style={{width: '100%'}}
                                value={status}
                                options={
                                    orderStatus.map(key => ({
                                        label: key.text,
                                        value: key.value,
                                        disabled: disabledStatus(key.value, order?.status)
                                    }))
                                }>
                        </Select>
                        {
                            disableStatus && (<Button key="sub" type="primary" loading={statusLoading}
                                                      onClick={() => changeStatus('shipped')}>
                                Generate Label
                            </Button>)
                        }

                        {order?.invoice &&
                            (<Button key="sub" type="primary"
                                     onClick={() => navigate(`/invoice/${order?.invoice?.id}`)}> Invoice</Button>
                            )}
                        {order?.tracking_url && (
                            <Button key="sub" type="primary" loading={labelLoading}
                                    onClick={downloadLabel}>
                                Download Label
                            </Button>)}
                        {order?.tracking_url && (
                            <Button key="sub" type="primary" onClick={
                                () => window.open(order?.tracking_url, '_blank')
                            }>
                                Track Order
                            </Button>)}
                    </Space>

                </Row>
                <Row justify="start" align="top">
                    <Col span={8}>
                        <p className='detailsCtr-head'>Buyer</p>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: order.buyer?.name,
                                email: order.buyer?.email,
                                image: order.buyer?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image}/>}
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={8}>
                        <p className='detailsCtr-head'>Seller</p>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: order.seller?.name,
                                email: order.seller?.email,
                                image: order.seller?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image}/>}
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={8}>
                        <div className='detailsCtr'>
                            <p className='detailsCtr-head'>Payment Details</p>
                            <Form
                                layout="horizontal"
                                labelAlign="left"
                                labelCol={{span: 8}}>
                                <Col span={24}>
                                    <Form.Item label="Amount" className="labelDy">
                                        : {formatPrice(order?.grand_total)}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Payment Status" className="labelDy">
                                        : {ucFirst(order?.paid_status)}
                                    </Form.Item>
                                </Col>

                            </Form>
                        </div>
                    </Col>
                </Row>


                <Row justify="start" align="top">
                    <Col span={8} className='detailsCtr'>
                        <p className='detailsCtr-head'>Details</p>
                        <Form
                            layout="horizontal"
                            labelAlign="left"
                            labelCol={{span: 8}}>
                            <Col span={24}>
                                <Form.Item label="Dress Name" className="labelDy">
                                    : {
                                    order?.detail?.listing?.name
                                }
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Size" className="labelDy">
                                    : {
                                    order?.detail?.listing?.label
                                }
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Price" className="labelDy">
                                    : {formatPrice(order?.amount)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Shipping Cost" className="labelDy">
                                    : {formatPrice(order?.shipping_cost)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Status" className="labelDy status-label">
                                    : <span>{ucFirst(order?.status)}</span>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Bid" className="labelDy">
                                    : {order?.is_bid_amount ? 'Yes' : 'No'}
                                </Form.Item>
                            </Col>
                        </Form>
                    </Col>

                    <Col span={8} className='detailsCtr'>
                        <p className='detailsCtr-head'>Billing Address</p>
                        <ul style={{
                            listStyle: "none",
                            textAlign: "left",
                            color: ' #667085',
                            fontSize: '14px',
                            lineHeight: '25px'
                        }}>
                            {order?.billing_address && (
                                <>
                                    <li>{order?.billing_address.address}</li>
                                    <li>{order?.billing_address.state?.name}</li>
                                    <li>{order?.billing_address.city}</li>
                                    <li>{order?.billing_address.zip_code}</li>
                                </>
                            )}
                        </ul>
                        <br/>
                        <p className='detailsCtr-head'>Shipping Address</p>
                        <ul style={{
                            listStyle: "none",
                            textAlign: "left",
                            color: ' #667085',
                            fontSize: '14px',
                            lineHeight: '25px'
                        }}>
                            {order?.shipping_address && (
                                <>
                                    <li>{order?.shipping_address.address}</li>
                                    <li>{order?.shipping_address.state?.name}</li>
                                    <li>{order?.shipping_address.city}</li>
                                    <li>{order?.shipping_address.zip_code}</li>
                                </>
                            )}
                        </ul>
                    </Col>

                    <Col span={8} className='detailsCtr'>
                        <p className='detailsCtr-head'>Seller Address</p>
                        <ul style={{listStyle: "none", color: ' #667085', fontSize: '14px', lineHeight: '25px'}}>
                            {order?.seller_address && (
                                <>
                                    <li>{order?.seller_address.address}</li>
                                    <li>{order?.seller_address.state?.name}</li>
                                    <li>{order?.seller_address.city}</li>
                                    <li>{order?.seller_address.zip_code}</li>
                                </>
                            )}
                        </ul>
                        <br/>
                        <p className='detailsCtr-head'>Shipping Details</p>
                        <ul style={{listStyle: "none", color: ' #667085', fontSize: '14px', lineHeight: '35px'}}>
                            {order?.shipping_method && (
                                <>
                                    <li>Carriers : {order?.carrier_name || 'N/A'}</li>
                                    <li>Service : {order?.shipping_method?.service_name}</li>
                                    {order?.shipping_method_response && (
                                        <li>Tracking No : {order?.shipping_method_response?.tracking_number}</li>
                                    )}
                                </>
                            )}
                        </ul>
                    </Col>


                </Row>


            </Spin>
        </Layout.Content>
    )
}

export default OrderView
