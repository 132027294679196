const StatusArray = [
    {
        text: "Active",
        value: '1'
    },
    {
        text: "Inactive",
        value: '0'
    }
];

const AddressType = [
    {
        label: "Home",
        value: "Home"
    }, {
        label: "Office",
        value: "Office"
    },
    {
        label: "Other",
        value: "Other"
    }
];
const SizeTag = [
    {
        text: "Label",
        value: "label"
    }
];

const ListStatus = [
    {
        text: "Draft",
        value: "draft"
    },
    {
        text: "Pending",
        value: "pending"
    },
    {
        text: "Active",
        value: "active"
    },
    {
        text: "In Active",
        value: "in active"
    },
    {
        text: "Expired",
        value: "expired"
    },
    {
        text: "Sold",
        value: "sold"
    }
];


const orderStatus = [
      {
        text: "Pending",
        value: "pending"
    },
    {
        text: "Confirmed",
        value: "confirmed"
    },
    {
        text: "Shipped",
        value: "shipped"
    },
    {
        text: "In Transit",
        value: "in transit"
    },
    {
        text: "Delivered",
        value: "delivered"
    },
    {
        text: "Cancelled",
        value: "cancelled"
    }
];

const orderPaidStatus = [
    {
        text: "Pending",
        value: "pending"
    },
    {
        text: "In Progress",
        value: "in progress"
    },
    {
        text: "Paid",
        value: "paid"
    },
    {
        text: "Unpaid",
        value: "unpaid"
    }
];


const MeasurementTag = [
    {
        text: "Waist",
        value: "waist"
    },
    {
        text: "Height",
        value: "height"
    },
    {
        text: "Bust",
        value: "bust"
    },
    {
        text: "Hips",
        value: "hips"
    }];

const AppearanceTag = [
    {
        text: "Color",
        value: "color"
    },
    {
        text: "Condition",
        value: "condition"
    },
    {
        text: "Fabric",
        value: "fabric"
    }
];


const StyleTag = [
    {
        text: "Neckline",
        value: "neckline"
    },
    {
        text: "Back",
        value: "back"
    },
    {
        text: "Sleeve",
        value: "sleeve"
    },
    {
        text: "Length",
        value: "length"
    },
    {
        text: "Silhouette",
        value: "silhouette"
    }
];

const PeriodEnum = Object.freeze({DAY: "day", MONTH: "month", YEAR: "year"});


const Colors = Object.freeze({
    'a': "#2f54eb",
    'b': "#faad14",
    'c': "#52c41a",
    'e': "#fa541c",
});

const StatusColors = {
    Active: 'green',
    Inactive: 'red',
    Expired: 'red',
    true: 'green',
    false: 'red',
    1: 'green',
    0: 'red',
    free: 'magenta',


    Pending: 'pink',
    Processing: 'volcano',
    Draft: 'cyan',
    Completed: 'green',
    Cancelled: 'yellow',
    Deleted: 'orange',
    Published: 'blue',
    Unpublished: 'purple',
    Unpaid: 'geekblue',
    Paid: 'magenta',
    Refunded: 'gold',
    Failed: 'lime',
}


export {
    StyleTag,
    MeasurementTag,
    AppearanceTag,
    SizeTag,
    PeriodEnum,
    AddressType,

    StatusArray,
    ListStatus,
    orderStatus,
    orderPaidStatus,

    StatusColors,
}


