import {api} from "../index";

const path = "masters";

const MastersService = api.injectEndpoints({
    endpoints: (builder) => ({
        listMasters: builder.query({
            query: () => `${path}/listing`,
            providesTags : ['Masters']
        }),
        getMasters: builder.query({
            query: (id, filters = '') => `${path}/${id}`
        }),
        // createMasters: builder.mutation({
        //     query: (data) => ({
        //         url: `${path}`,
        //         method: 'POST',
        //         body: data,
        //     }),
        //     invalidatesTags: ['Masters']
        // }),
        // updateMasters: builder.mutation({
        //     query: ({id, data}) => ({
        //         url: `${path}/${id}`,
        //         method: 'PUT',
        //         body: data,
        //     }),
        //     invalidatesTags: ['Masters']
        // }),
        // patchMasters: builder.mutation({
        //     query: ({id, data}) => ({
        //         url: `${path}/${id}`,
        //         method: 'PATCH',
        //         body: data,
        //     }),
        //     invalidatesTags: ['Masters']
        // }),
        // deleteMasters: builder.mutation({
        //     query: (id) => ({
        //         url: `${path}/${id}`,
        //         method: 'DELETE',
        //     }),
        //     invalidatesTags: ['Masters']
        // }),
    }),
});


export const {
    useListMastersQuery,
    useGetMastersQuery,
    // useCreateMastersMutation,
    // useUpdateMastersMutation,
    // usePatchMastersMutation,
    // useDeleteMastersMutation,
} = MastersService;



