import {Typography, Layout, Spin, List, Avatar, Col, Row} from 'antd'
import React, {useEffect, useState} from 'react'
import {useGetMessageQuery} from "../../redux/api/services/message";
import {useParams} from "react-router-dom";
import {displayTimeText} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";


const ChatBoxView = () => {

    const params = useParams();
    const [message, setMessage] = useState({});
    const filters = 'include=details;seller;buyer';
    const [buyer, setBuyer] = useState(null);

    const {data, isLoading, error} = useGetMessageQuery({id: params.id, filters: filters});


    useEffect(() => {
        if (data) {

            let result = data.data;
            let buyerTmp = result.buyer;

            if (result.type === 'contact') {
                if (result.buyer_id === null) {
                    buyerTmp = {
                        name: result.name,
                        email: result.email,
                        image: null
                    }
                }
            }
            setBuyer(buyerTmp);
            setMessage(result);
        }
    }, [data]);


    return (
        <Layout.Content className='container'>

            <Typography.Title level={3}>{message.type === 'contact' ? 'Contact Seller' : 'Bid Offer'} </Typography.Title>


            <Spin spinning={isLoading}>

                <Row>

                    <Col span={12}>
                        <Typography.Title level={5}>Seller</Typography.Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: message.seller?.name,
                                email: message.seller?.email,
                                image: message.seller?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            item.image ? <Avatar src={item.image}/> :
                                                <Avatar icon={<UserOutlined/>}/>
                                        }
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>

                    <Col span={12}>
                        <Typography.Title level={5}>Buyer</Typography.Title>
                        <List
                            itemLayout="horizontal"
                            dataSource={[{
                                name: buyer?.name,
                                email: buyer?.email,
                                image: buyer?.image
                            }
                            ]}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            item.image ? <Avatar src={item.image}/> :
                                                <Avatar icon={<UserOutlined/>}/>
                                        }
                                        title={<a href="#">{item.name}</a>}
                                        description={item.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>


                </Row>

                <div className='chatfullCtr'>
                    <div className='ant-comment'>
                        {
                            message.type === 'contact' ? (
                                <>
                                    <Typography.Title level={5}>Message</Typography.Title>
                                    <p style={{padding: '10px'}}>
                                        {message?.message}
                                    </p>
                                </>
                            ) : (message?.details?.map((item, index) => {
                                const side = message?.seller_id === item.from_id ? 'leftSideComment' : 'rightSideComment';
                                const user = message?.seller_id === item.from_id ? message.seller : message.buyer;

                                return (
                                    <div className={`ant-comment-inner ${side}`}>
                                        <div className="ant-comment-avatar">
                                            <span className="ant-avatar ant-avatar-circle ant-avatar-image">
                                              {user.image ? <Avatar src={user.image}/> :
                                                  <Avatar icon={<UserOutlined/>}/>}
                                            </span>
                                        </div>
                                        <div className='ant-comment-content'>
                                            <div className="ant-comment-content-author">
                                                <span
                                                    className="ant-comment-content-author-name"><a>{user.name}</a></span>
                                                <span
                                                    className="ant-comment-content-author-time"><span>{displayTimeText(item.created_at)}</span>
                                                </span>
                                            </div>
                                            <div className='ant-comment-content-detail'>
                                                <p dangerouslySetInnerHTML={{__html: item?.message}}></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }))
                        }
                    </div>
                </div>
            </Spin>
        </Layout.Content>
    )
}

export default ChatBoxView
