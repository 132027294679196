import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Table, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListBuyerQuery,
} from '../../redux/api/services/buyer';
import ViewWidget from "../../widgets/ViewWidget";
import DeleteWidget from "../../widgets/DeleteWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {confirmAlert, convertTableParams, successAlert} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";

const Customer = () => {

    const navigate = useNavigate();
    const [buyers, setBuyers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['name', 'email', 'mobile'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchBuyers
    } = useListBuyerQuery(convertTableParams(tableParams));


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.image ? <Avatar src={record?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.name}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <ViewWidget onClick={() => {
                            navigate(`/customer/${record.id}`)
                        }}/>
                    </Col>
                </Row>
            ),
        },
    ];


    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);


    useEffect(() => {
        if (data) {
            setBuyers(data.data.buyers);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchBuyers(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Customers</Typography.Title>

            <Row>
                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL CUSTOMER COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>
            </Row>


            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={buyers}
                        columns={columns} rowKey="id"/>

                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Customer;
