import React, {useState, useEffect} from "react";
import {Typography, Layout, Col, Tabs, Row, Spin} from "antd";
import {useParams} from "react-router-dom";


import AddressView from "../address/view";
import {useGetBuyerQuery} from "../../redux/api/services/buyer";
import AccountInformation from "./account";


const CustomerOverview = () => {

    const params = useParams();

    const [buyer, setBuyer] = useState(null);


    const {data, isLoading, error} = useGetBuyerQuery({id: params.id});

    useEffect(() => {
        if (data) {
            setBuyer(data.data);
        }
    } , [data])



    return (
        <Layout.Content className="container">
            <Typography.Title level={3}>Customer </Typography.Title>
            <Spin spinning={isLoading}>
                <Row gutter={24} style={{ marginTop: 20 }}>
                    <Col span={24} className="customerTabSection">
                        <Tabs tabPosition={'left'} type="card" items={[
                            {
                                key: '1',
                                label: 'Account Information',
                                children: <AccountInformation data={buyer} type='buyer' />
                            },
                            {
                                key: '2',
                                label: 'Address',
                                children: <AddressView id={params.id} type='buyer' />
                            }
                        ]} />
                    </Col>
                </Row>
            </Spin>

        </Layout.Content>
    );
}

export default CustomerOverview;
