import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Select, Skeleton, Table, Typography, DatePicker, Tag} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";


import {useListOrderQuery} from '../../redux/api/services/order';
import {useListSellerQuery} from "../../redux/api/services/seller";

import SearchWidget from "../../widgets/SearchWidget";
import {
    convertDate,
    convertTableParams,
    formatPrice,
    lastRangePresetsArray,
    displayDateFormat,
    convertDayjsToDate, ucFirst
} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";
import EyeWidget from '../../widgets/EyeWidget'
import {orderPaidStatus, orderStatus} from "../../utils/enums";


const {RangePicker} = DatePicker;

const Order = () => {

    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [sellerOptions, setSellerOptions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [date, setDate] = useState(null);

    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['seller.name', 'seller.email', 'buyer.name', 'buyer.email'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchOrders
    } = useListOrderQuery(convertTableParams(tableParams));


    const {
        data: sellers, isLoading: loadingSellers, error: errorSellers, isFetching: fetchingSellers,
    } = useListSellerQuery();


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (v) => (
                <span>{convertDate(v)}</span>
            )
        },
        {
            title: 'Buyer',
            dataIndex: ["buyer", "name"],
            key: "buyer.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.buyer?.image ? <Avatar src={record?.buyer?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.buyer?.name}</p>
                        <p>{record?.buyer?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Seller',
            dataIndex: ["seller", "name"],
            key: "seller.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.seller?.image ? <Avatar src={record?.seller?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.seller?.name}</p>
                        <p>{record?.seller?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Price',
            dataIndex: 'grand_total',
            key: 'grand_total',
            render: (v) => (
                <span>{formatPrice(v)}</span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: orderStatus,
            render: (v) => (
               ucFirst(v)
            )
        },
        {
            title: 'Paid Status',
            dataIndex: 'paid_status',
            key: 'paid_status',
            filters: orderPaidStatus,
            render: (v) => (
                <>{v === "paid" ? <Tag color={'green'}>{ucFirst(v)}</Tag> : <Tag color={'red'}>{ucFirst(v)}</Tag>}</>
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EyeWidget onClick={() => navigate(`/order/${record.id}`)}/>
                    </Col>
                </Row>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const changeSeller = (value) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            filters: {
                seller_id: value
            },
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setOrders(data.data.orders);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (sellers) {
            setSellerOptions(sellers.data.sellers.map(seller => ({
                label: seller.name,
                value: seller.id
            })));
        }
    }, [sellers]);

    useEffect(() => {

        const filterBW = {};
        if (date) {
            const termStartTimestamp = [];
            date.forEach((item, index) => {
                termStartTimestamp[index] = convertDayjsToDate(item);
            });
            filterBW['date'] = termStartTimestamp;
        }


        setTableParams({
            ...tableParams,
            callApi: true,
            filtersBW: filterBW,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });


    }, [date]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchOrders(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Orders</Typography.Title>

            <Row>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'seller'} label={'Select Seller'}>
                        {
                            loadingSellers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select showSearch optionFilterProp="label"
                                        placeholder="Seller Name" allowClear options={sellerOptions}
                                        onChange={changeSeller}/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Date"}>
                        <RangePicker presets={lastRangePresetsArray} value={date}
                                     onChange={(v) => setDate(v)}
                                     format={displayDateFormat}
                                     disabledDate={(current) => current && current > dayjs().endOf('day')}
                        />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL ORDER COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>



            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={orders}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default Order;
