import {api} from "../index";

const path = "banners";

const BannerService = api.injectEndpoints({
    endpoints: (builder) => ({
        listBanner: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Banner']
        }),
        getBanner: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`
        }),
        createBanner: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Banner']
        }),
        updateBanner: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Banner']
        }),
        patchBanner: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Banner']
        }),
        deleteBanner: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Banner']
        }),
    }),
});


export const {
    useListBannerQuery,
    useGetBannerQuery,
    useCreateBannerMutation,
    useUpdateBannerMutation,
    usePatchBannerMutation,
    useDeleteBannerMutation,
} = BannerService;



