import {Button, Col, Row, Space} from "antd";
import React from "react";


const FormActionWidget = ({loading, onCancelClick, onSaveClick, label = 'Save'}) => {

    return (
        <Row style={{textAlign : 'center'}}>
            <Col span={24}>
                <Space>
                    <Button key="sub"
                            type="primary"
                            loading={loading}
                            onClick={onSaveClick}>
                        {label}
                    </Button>

                    <Button key="back" type="primary" onClick={onCancelClick}>
                        Cancel
                    </Button>
                </Space>
            </Col>
        </Row>
    )
}

export default FormActionWidget;
