import {api} from "../index";

const path = "/contact-us";

const ContactUsService = api.injectEndpoints({
    endpoints: (builder) => ({
        listContactUs: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getContactUs: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
    }),
});


export const {
    useListContactUsQuery,
    useGetContactUsQuery,
} = ContactUsService;



