import {api} from "../index";


const CountryService = api.injectEndpoints({
    endpoints: (builder) => ({
        listCountry: builder.query({
            query: (filters = '') => `/countries?${filters}`
        }),
        getCountry: builder.query({
            query: (id, filters = '') => `countries/${id}?${filters}`,
        }),
        listState: builder.query({
            query: ({id, filters = ''}) => {
                if (id === null) throw new Error('id is required');
                return `countries/${id}/states?${filters}`;
            }
        }),
        listCity: builder.query({
            query: ({id, filters = ''}) => {
                if (id === null) throw new Error('id is required');
                return `states/${id}/cities?${filters}`;
            }
        }),
    }),
});


export const {
    useListCountryQuery,
    useGetCountryQuery,
    useListStateQuery,
    useListCityQuery,
} = CountryService;



