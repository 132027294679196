import React, {useState, useEffect} from 'react'
import {Button, Col, Form, Image, Layout, Row, Table, Tooltip, Typography} from 'antd'
import {
    useListCategoryQuery,
    useDeleteCategoryMutation,
} from '../../redux/api/services/category';
import CategoryAction from "./action";
import EditWidget from "../../widgets/EditWidget";
import DeleteWidget from "../../widgets/DeleteWidget";
import AddWidget from "../../widgets/AddWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {confirmAlert, convertTableParams, successAlert} from "../../utils/helper";
import {UnorderedListOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

const Category = () => {

    const navigate = useNavigate();


    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [action, setAction] = useState(null);
    const [showModel, setShowModel] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [visible, setVisible] = useState([]);


    const {data, isLoading, error, isFetching} = useListCategoryQuery(
        convertTableParams({include: 'detailImages'})
    );
    const [remove, {isLoading: loading}] = useDeleteCategoryMutation();


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'List of Categories',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Image',
            dataIndex: 'detail_images',
            key: 'detail_images',
            align: "center",
            render: (_, record) => (
                <>
                    {
                        record?.detail_images && record?.detail_images.length > 0 && (
                            <Image preview={{
                                visible: false,
                            }} width={100} height={100} src={record?.detail_images[0]?.image}
                                   onClick={
                                       () => {
                                           const id = record.id;
                                           setVisible({...visible, [id]: true});
                                       }

                                   }
                            />
                        )
                    }
                    <div
                        style={{
                            display: 'none',
                        }}>
                        <Image.PreviewGroup
                            preview={{
                                visible: visible[record.id] ?? false,
                                onVisibleChange: (vis) => {
                                    const id = record.id;
                                    setVisible({...visible, [id]: vis});
                                },
                            }}>
                            {
                                record?.detail_images && record?.detail_images.map((item, index) => {
                                    return (
                                        <Image
                                            preview={{
                                                visible: visible[record.id] ?? false,
                                            }}
                                            width={200} src={item.image} onClick={() => {
                                            const id = record.id;
                                            setVisible({...visible, [id]: true});
                                        }}
                                        />
                                    )
                                })
                            }
                        </Image.PreviewGroup>
                    </div>

                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <Tooltip title="Detail">
                            <Button loading={loading} type="link"
                                    onClick={() => navigate(`/category/${record.id}/details`)}
                                    icon={<UnorderedListOutlined className="icon"/>}/>
                        </Tooltip>
                    </Col>
                    {/*<Col>*/}
                    {/*    <EditWidget onClick={() => saveAction('edit', record)}/>*/}
                    {/*</Col>*/}
                    {/*<Col>*/}
                    {/*    <DeleteWidget loading={loadingDelete[record.id] ?? false}*/}
                    {/*                  onClick={() => deleteCategory(record.id)}/>*/}
                    {/*</Col>*/}

                </Row>
            ),
        },
    ];


    const deleteCategory = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    successAlert('Category deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    function saveAction(action, category = null) {
        setCategory(category);
        setAction(action);
    }

    function resetAction(c) {
        setCategory(null);
        setAction(null);
        setShowModel(c);
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        if (value) {
            setCategories(categories.filter(c => c.name.toLowerCase().includes(value.toLowerCase())));
        } else {
            setCategories(data.data.categories);
        }
    }


    useEffect(() => {
        if (action)
            setShowModel(true);
        else
            setShowModel(false);
    }, [action]);


    useEffect(() => {
        if (data) {
            setCategories(data.data.categories);
        }
    }, [data]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Categories</Typography.Title>

            <Row>
                <Col span={6}>
                    {/*<AddWidget onClick={() => saveAction('add')} label='Add Category'/>*/}
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={handleSearch}/>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table loading={isLoading || isFetching} pagination={{hideOnSinglePage: true}}
                           dataSource={categories}
                           columns={columns} rowKey="id"/>

                    {action !== null &&
                        <CategoryAction open={showModel} action={action} category={category} onClose={resetAction}/>}

                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Category
