import {Button, Tooltip} from "antd"
import {EyeOutlined} from "@ant-design/icons"
import React from "react"

const EyeWidget = ({onClick, loading = false}) => {
    return (
        <Tooltip title="View">
            <Button
                loading={loading}
                type="link"
                onClick={onClick}
                icon={<EyeOutlined className="icon" />}
            />
        </Tooltip>
    )
}

export default EyeWidget
