import React, {useState, useEffect, useCallback} from 'react'
import {Col, Layout, Row, Table, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListDontSellQuery,
} from '../../redux/api/services/dont-sell';


import SearchWidget from "../../widgets/SearchWidget";
import {
    convertDate,
    convertTableParams,
} from "../../utils/helper";
import EyeWidget from '../../widgets/EyeWidget';

const DontSellInfo = () => {

    const navigate = useNavigate();
    const [dontSell, setDontSell] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['first_name', 'email', 'last_name'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchDontSell,
    } = useListDontSellQuery(convertTableParams(tableParams));



    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (v) => (
                <span>{convertDate(v)}</span>
            )
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name'
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name'
        },
        {
            title: 'Mobile',
            dataIndex: 'phone_no',
            key: 'phone_no'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'Request',
            dataIndex: 'request',
            key: 'request',
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (

                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EyeWidget onClick={() => navigate(`/dont-sell-info/${record.id}`)}/>
                    </Col>
                </Row>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setDontSell(data.data.information_request);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);


    useEffect(() => {
        if (tableParams.callApi)
            fetchDontSell();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Dont Sell Info</Typography.Title>
            <Row>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL DONT SELL INFO COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={dontSell}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default DontSellInfo;
