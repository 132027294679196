import {api} from "../index";

const path = "pages";

const PageService = api.injectEndpoints({
    endpoints: (builder) => ({
        listPage: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Page']
        }),
        getPage: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`,
            providesTags : ['Page']
        }),
        createPage: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Page']
        }),
        updatePage: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Page']
        }),
        patchPage: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Page']
        }),
        deletePage: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Page']
        }),
        createPageDetail: builder.mutation({
            query: (data) => ({
                url: `${path}/${data.page_id}/details`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Page']
        }),
        updatePageDetail: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${data.page_id}/details/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Page']
        }),
        deletePageDetail: builder.mutation({
            query: ({id, pageId}) => ({
                url: `${path}/${pageId}/details/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Page']
        }),
    }),
});


export const {
    useListPageQuery,
    useGetPageQuery,
    useCreatePageMutation,
    useUpdatePageMutation,
    usePatchPageMutation,
    useDeletePageMutation,
    useCreatePageDetailMutation,
    useUpdatePageDetailMutation,
    useDeletePageDetailMutation,
} = PageService;



