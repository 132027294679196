import {Layout, Menu, theme, Tooltip} from "antd"
import React from "react"
import {NavLink, Outlet, useNavigate} from "react-router-dom"
import {LogoutOutlined} from "@ant-design/icons"
import Logo from "../assets/logo.png"
import {useLogoutMutation} from "../redux/api/services/auth"
import {useState} from "react"
import {errorAlert, successAlert} from "../utils/helper"
import {logOut} from "../redux/slice/authSlice"
import {useDispatch} from "react-redux";


const {Header, Content, Sider} = Layout

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    }
}

const items = [
    getItem(<NavLink to="/dashboard">Dashboard</NavLink>, "/dashboard", null),
    getItem(<NavLink to="/listing">Listings</NavLink>, "/listing", null),
    getItem(<NavLink to="/direct-sold">Direct Sold</NavLink>, "/direct-sold", null),
    getItem("Sales", "sales", null, [
        getItem(<NavLink to="/order">Orders</NavLink>, "/sales"),
    ]),
    getItem("Customers", "customers", null, [
        getItem(<NavLink to="/customer">Buyers</NavLink>, "customer"),
        getItem(<NavLink to="/seller">Sellers</NavLink>, "seller"),
    ]),
    getItem("Payments", "payments", null, [
        getItem(<NavLink to="/payment/plan">Plan</NavLink>, "/payment/plan"),
        getItem(<NavLink to="/payment/shipping">Shipping</NavLink>, "/payment/shipping"),
        getItem(<NavLink to="/payment/order">Custom Orders</NavLink>, "/payment/order"),
    ]),
    getItem(<NavLink to="/inventory">Inventory</NavLink>, "/inventory", null),
    getItem(<NavLink to="/category">Categories</NavLink>, "/category", null),
    getItem(<NavLink to="/plan">Plans</NavLink>, "/plan", null),
    getItem(<NavLink to="/slider">Sliders</NavLink>, "/slider", null),
    getItem(<NavLink to="/banner">Mobile Banner</NavLink>, "/banner", null),
    getItem(<NavLink to="/testimonial">Testimonial</NavLink>, "/testimonial", null),
    getItem(<NavLink to="/page">Pages</NavLink>, "/page", null),
    getItem(<NavLink to="/chat-box">Chat Box</NavLink>, "/chat-box", null),
    getItem(<NavLink to="/feedback">Feedback</NavLink>, "/feedback", null),
    getItem(<NavLink to="/news-letter">NewsLetter</NavLink>, "/news-letter", null),
    getItem(<NavLink to="/contact-us">ContactUs</NavLink>, "/contact-us", null),
    getItem(<NavLink to="/popular-search">Popular Search</NavLink>, "/popular-search", null),
    getItem(<NavLink to="/dont-sell-info">Dont Sell Info</NavLink>, "/dont-sell-info", null),
    getItem("Masters", "masters", null, [
        getItem(<NavLink to="/master/size">Size</NavLink>, "master/size"),
        getItem(<NavLink to="/master/measurement">Measurements</NavLink>, "master/measurement"),
        getItem(<NavLink to="/master/appearance">Appearance</NavLink>, "master/appearance"),
        getItem(<NavLink to="/master/style">Style</NavLink>, "master/style"),
        getItem(<NavLink to="/master/designer">Designer</NavLink>, "master/designer"),
    ]),
    getItem("Reports", "reports", null, [
        getItem(<NavLink to="/reports/seller-listing">Seller Report</NavLink>, "reports/seller-listing"),
        getItem(<NavLink to="/reports/top-seller">Top Sellers</NavLink>, "reports/top-seller"),
        getItem(<NavLink to="/reports/top-customer">Top Customers</NavLink>, "reports/top-customer"),
    ]),
]

const Layouts = () => {
    const [modalShow, setModalShow] = useState(false)
    const navigate = useNavigate()
    const [logout] = useLogoutMutation()
    const dispatch = useDispatch()


    return (
        <Layout
            style={{
                height: "100%",
                width: "100%",
            }}>
            <Header className="header">
                <img className="logo" src={Logo} alt=""/>
                <Tooltip title="Logout">
                    <LogoutOutlined
                        onClick={() => {
                            logout()
                                .unwrap()
                                .then((res) => {
                                    dispatch(logOut())
                                    successAlert(res?.message)
                                    navigate("/login")
                                })
                                .catch((err) => errorAlert(err?.data?.error))
                        }}
                        style={{
                            fontSize: "35px",
                            color: "#CDA96D",
                            cursor: "pointer",
                        }}
                    />
                </Tooltip>
            </Header>
            <Layout>
                <Sider width={200} className="sidebar_sider">
                    <Menu mode="inline" className="sidebar_menu" items={items}/>
                </Sider>
                <Layout>
                    <Content className="outlet">
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}
export default Layouts
