import React from "react";
import {Col, Row} from "antd";
import {CloseCircleOutlined, SwapLeftOutlined} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";


const OnboardError = () => {

    const query = new URLSearchParams(window.location.search);

    const isEmailConfirmed = query.get('isEmailConfirmed');
    const paymentsReceivable = query.get('paymentsReceivable');


    return (
        <section className="page-section page-onboard-cancel">
            <Row className="align-items-start">
                <Col md={{span: 6, offset: 3}} lg={{span: 12, offset: 6}} className="listingPopup">
                    <section className="paypal-status-failed">
                        <CloseCircleOutlined/>

                        {
                            isEmailConfirmed === '0' && (
                                <h4>
                                    Attention: Please confirm your email address on &nbsp;
                                    <a href="https://www.paypal.com/businessprofile/settings"
                                       target="_blank">https://www.paypal.com/businessprofile/settings </a>
                                    in order to receive payments! You currently cannot receive payments.
                                </h4>
                            )
                        }
                        {
                            paymentsReceivable === '0' && (
                                <h4>
                                    Attention: You currently cannot receive payments due to restriction on your
                                    PayPal account. Please reach out to PayPal Customer Support or connect to
                                    <a href="https://www.paypal.com"
                                       target="_blank"> https://www.paypal.com </a>
                                    for more information.
                                </h4>
                            )
                        }

                        {
                            !isEmailConfirmed && !paymentsReceivable && (
                                <h3>Due to your cancellation, your account cannot be linked.</h3>
                            )
                        }
                        <br/>
                        <Link to="/seller" className='nav-link-filled'><SwapLeftOutlined/> Back to Seller </Link>
                    </section>
                </Col>
            </Row>
        </section>
    );
}

export default OnboardError;
