import {Avatar, Button, Col, Form, Input, Layout, Row} from "antd"
import {useNavigate, Navigate} from "react-router-dom"
import Logo from "../assets/Weddinggown.png"
import {useLoginMutation} from "../redux/api/services/auth"
import {errorAlert, successAlert} from "../utils/helper"
import {useDispatch, useSelector} from "react-redux"
import {setUser} from "../redux/slice/authSlice"

const Login = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useSelector((state) => state.auth.token);

    const [login, {isLoading: loginLoading}] = useLoginMutation();

    if(token) {
        return <Navigate to="/dashboard"/>
    }

    const handleSubmit = (values) => {
        login(values)
            .unwrap()
            .then((res) => {
                dispatch(setUser({token: res.token}))
                successAlert(res?.message)
                navigate("/dashboard")
            })
            .catch((err) => {
                errorAlert(err?.data?.error)
            })
    }
    return (
        <Layout
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <Form form={form} onFinish={handleSubmit}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <Avatar
                        src={Logo}
                        style={{
                            height: "154px",
                            width: "223px",
                        }}
                    />
                </div>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message : "Enter the email address"
                        },
                        {
                            type: "email",
                            message : "Please enter valid Email"
                        }
                    ]}
                    name="email">
                    <Input type="email"  className="login-input" placeholder="Email" />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message : "Enter the password"
                        },
                    ]}
                    name="password">
                    <Input.Password autoComplete="off" className="login-input" placeholder="Password" />
                </Form.Item>
                <Form.Item>
                    <Row align={"middle"} justify="center">
                        <Col span={12}>
                            <Button
                                loading={loginLoading}
                                style={{
                                    width: "100%",
                                }}
                                htmlType="submit"
                                type="primary">
                                Login
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Layout>
    )
}
export default Login
