import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Select, Skeleton, Table, Typography, Space, Tag, Button} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useDeleteListingMutation,
    useListListingQuery, usePatchListingMutation, usePayListingMutation
} from '../../redux/api/services/listing';
import {useListSellerQuery} from "../../redux/api/services/seller";
import {useListPlanQuery} from "../../redux/api/services/plan";

import AddWidget from "../../widgets/AddWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {
    confirmAlert,
    convertTableParams,
    formatPrice,
    successAlert,
} from "../../utils/helper";
import {UserOutlined} from "@ant-design/icons";
import DeleteWidget from '../../widgets/DeleteWidget'
import EyeWidget from '../../widgets/EyeWidget'

const DirectSold = () => {

    const navigate = useNavigate();
    const [listings, setListings] = useState([]);
    const [sellerOptions, setSellerOptions] = useState([]);
    const [planOptions, setPlanOptions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        include: 'seller;plan',
        searchByOr: ['name', 'code', 'seller.name', 'seller.email', 'plan.name'],
        filters: {
            is_direct_sold: 1,
        },
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchListings
    } = useListListingQuery(convertTableParams(tableParams));

    const [remove, {isLoading: removeLoading}] = useDeleteListingMutation()

    const {
        data: sellers, isLoading: loadingSellers
    } = useListSellerQuery('select=id,name,email');

    const {
        data: plans, isLoading: loadingPlans
    } = useListPlanQuery();


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Seller Name',
            dataIndex: ["seller", "name"],
            key: "seller.name",
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.seller?.image ? <Avatar src={record?.seller?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.seller?.name}</p>
                        <p>{record?.seller?.email}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Plan',
            dataIndex: ["plan", "name"],
            key: 'plan.name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (v) => (
                <span>{formatPrice(v)}</span>
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            width: "10%",
            render: (_, record) => (
                <Space>
                    <EyeWidget onClick={() => navigate(`/listing/view/${record.id}`)}/>
                    {record.status !== "sold" ? <DeleteWidget loading={loadingDelete[record.id] ?? false}
                                                              onClick={() => deleteListing(record.id)}/> : null}
                </Space>
            ),
        },
    ];

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {

        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters: {
                ...tableParams.filters,
                ...filters
            },
            ...sorter,
        });
    };


    const changeSeller = (value) => {

        const listParams = {
            ...tableParams,
            callApi: true,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        }
        listParams.filters = {
            ...listParams.filters,
            seller_id: value
        }

        setTableParams(listParams);
    }

    const changePlan = (value) => {

        const listParams = {
            ...tableParams,
            callApi: true,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        }
        listParams.filters = {
            ...listParams.filters,
            plan_id: value
        }

        setTableParams(listParams);
    }


    const deleteListing = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    successAlert('Listing deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setListings(data.data.listings);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (sellers) {
            setSellerOptions(sellers.data.sellers.map(seller => ({
                label: seller.name+" ("+seller.email+")",
                value: seller.id
            })));
        }
    }, [sellers]);

    useEffect(() => {

        if (plans) {
            setPlanOptions(plans.data.plans.map(plan => ({
                label: plan.name,
                value: plan.id
            })));
        }

    }, [plans])

    useEffect(() => {
        if (tableParams.callApi)
            fetchListings(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Direct Sold</Typography.Title>


            <Row>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'seller'} label={'Seller'}>
                        {
                            loadingSellers ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select placeholder="Seller Name" showSearch allowClear options={sellerOptions}
                                        optionFilterProp="label"
                                        onChange={changeSeller}/>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item wrapperCol={{
                        span: 15
                    }} name={'plan'} label={'Plan'}>
                        {
                            loadingPlans ? <Skeleton.Input active style={{width: "100%"}}/> :
                                <Select placeholder="Plan" showSearch allowClear options={planOptions}
                                        optionFilterProp="label"
                                        onChange={changePlan}/>
                        }
                    </Form.Item>

                </Col>

                <Col span={24}>
                    <AddWidget onClick={() => navigate(`/direct-sold/add`)} label='Add Listing'/>
                </Col>

                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL LISTING COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={listings}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>

        </Layout.Content>
    )
}

export default DirectSold;
