import React, {useState, useEffect, useCallback} from 'react'
import {Avatar, Col, Form, Layout, Row, Table, Tag, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListSellerQuery,
    useDeleteSellerMutation,
} from '../../redux/api/services/seller';
import DeleteWidget from "../../widgets/DeleteWidget";
import AddWidget from "../../widgets/AddWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {confirmAlert, convertTableParams, successAlert} from "../../utils/helper";
import EyeWidget from "../../widgets/EyeWidget";
import ViewWidget from "../../widgets/ViewWidget";
import SellerAction from "./action";
import {UserOutlined} from "@ant-design/icons";

const Seller = () => {

    const navigate = useNavigate();

    const [action, setAction] = useState(null);
    const [showModel, setShowModel] = useState(false);
    const [seller, setSeller] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        withCount: ['listings'],
        searchByOr: ['name', 'email', 'mobile'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchSellers
    } = useListSellerQuery(convertTableParams(tableParams));
    const [remove, {isLoading: loading}] = useDeleteSellerMutation();


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <Row align={'middle'} gutter={[8, 10]}>
                    <Col>
                        {record?.image ? <Avatar src={record?.image} size={40}/> :
                            <Avatar icon={<UserOutlined/>} size={40}/>}
                    </Col>
                    <Col>
                        <p>{record?.name}</p>
                    </Col>
                </Row>
            }
        },
        {
            title: 'Mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Paypal Status',
            dataIndex: 'paypal_merchant_status',
            key: 'paypal_merchant_status',
            render: (v, record) => (
                <>{
                    v === "verified" ?
                        <Tag color={'green'}>{v}</Tag> :
                        (v != null ? <Tag color={'red'}>{v}</Tag> :
                            <Tag color="red">Unverified</Tag>)
                }
                </>
            )
        },
        {
            title: 'Total Listings',
            dataIndex: 'listings_count',
            key: 'listings_count',
            align: "center",
            render: (v, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col style={{marginTop: 5}}>
                        {v > 0 ? <Typography.Text>{v}</Typography.Text> : null}
                    </Col>
                    <Col>
                        {v > 0 ? <ViewWidget onClick={() => navigate(`/seller/${record.id}/listing`)}/> : null}
                    </Col>
                </Row>
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EyeWidget onClick={() => navigate(`/seller/view/${record.id}`)}/>
                    </Col>
                    <Col>
                        <DeleteWidget loading={loadingDelete[record.id] ?? false}
                                      onClick={() => deleteSeller(record.id)}/>
                    </Col>

                </Row>
            ),
        },
    ];


    const deleteSeller = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    successAlert('Seller deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);

    function saveAction(action, seller = null) {
        setSeller(seller);
        setAction(action);
    }

    function resetAction(c) {
        if (c && action === 'add') {
            setTotalRecords(totalRecords + 1);
        }
        setShowModel(c);
        setSeller(null);
        setAction(null);
    }


    useEffect(() => {
        if (action)
            setShowModel(true);
        else
            setShowModel(false);
    }, [action]);


    useEffect(() => {
        if (data) {
            setSellers(data.data.sellers);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchSellers(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Sellers</Typography.Title>

            <Row>
                <Col span={24} >
                    <AddWidget onClick={() => saveAction('add')} label='Add New'/>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL SELLER COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>
            </Row>


            <Row gutter={[10, 15]} align='middle' justify="space-between">

                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={sellers}
                        columns={columns} rowKey="id"/>


                    {action !== null &&
                        <SellerAction open={showModel} action={action} seller={seller} onClose={resetAction}/>}

                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Seller;
