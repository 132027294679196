import {api} from "../index";

const path = "/shipping-transactions";

const ShippingTransactionService = api.injectEndpoints({
    endpoints: (builder) => ({
        listShippingTransaction: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getShippingTransaction: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
    }),
});


export const {
    useListShippingTransactionQuery,
    useGetShippingTransactionQuery,
} = ShippingTransactionService;



