import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux"
import {PersistGate} from "redux-persist/integration/react"

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import {store, persistor} from "./redux/store"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <ConfigProvider
                    theme={{
                        token: {
                            // colorBgLayout: "#e4e5e"
                            // colorPrimaryBg: "#33383f",
                            // colorPrimaryText : "red",
                            colorPrimary: "rgba(205, 169, 109, 0.35)",
                            // colorBgLayout: "#fff",
                            colorFillQuaternary: "#CDA96D",
                            colorFillContentHover: "light-gray",
                            fontFamily: 'Inter'
                            // borderRadius : "30px"
                        },
                        components: {
                            // Layout: {
                            //   colorBgHeader: "#22272f",
                            // },
                            Layout: {
                                colorBgLayout: "rgba(205, 169, 109, 0.35)",
                                colorBgHeader: "#EEE8DD",
                            },
                            Form: {
                                // lineHeightHeading1: "100px",
                                fontSize : "18px"
                            },
                            Input: {
                                // lineHeight: "40px",
                                // padding: "10px",

                                //   controlHeight : "280px"
                            },
                            Typography: {
                                colorTextHeading: "#CDA96D",
                                // colorText: 'rgba(205, 169, 109, 0.35)'
                            }
                            // Button: {
                            //   colorText: "#f15733",
                            //   colorBorder: "#f15733",
                            //   // colorBgContainer: "#f5f5f5",
                            //   borderRadius: "30px"
                            // }
                        },
                    }}>
                    <App/>
                </ConfigProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

