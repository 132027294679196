import {Switch} from "antd";



const StatusWidget = ({status, loading= false, disabled = false, onChange}) => {

        return (
            <Switch loading={loading} checked={status} checkedChildren="Active" unCheckedChildren="Inactive"
                    disabled={disabled}
                    onChange={onChange}/>
        )
}

export default StatusWidget;
