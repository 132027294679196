import {api} from "../index";

const path = "masters/measurements";

const MeasurementService = api.injectEndpoints({
    endpoints: (builder) => ({
        listMeasurement: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Measurement']
        }),
        getMeasurement: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`
        }),
        createMeasurement: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Measurement']
        }),
        updateMeasurement: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Measurement']
        }),
        patchMeasurement: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Measurement']
        }),
        deleteMeasurement: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Measurement']
        }),
    }),
});


export const {
    useListMeasurementQuery,
    useGetMeasurementQuery,
    useCreateMeasurementMutation,
    useUpdateMeasurementMutation,
    usePatchMeasurementMutation,
    useDeleteMeasurementMutation,
} = MeasurementService;



