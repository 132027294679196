import React, {useEffect, useState} from "react";
import {Col, Form, Input, Modal} from "antd";
import {Editor} from '@tinymce/tinymce-react';


import {successAlert, errorAlert, confirmAlert} from "../../utils/helper";
import ModalFooterWidget from "../../widgets/ModalFooterWidget";
import {
    useCreatePageDetailMutation,
    useUpdatePageDetailMutation
} from '../../redux/api/services/page';


const DetailAction = ({pageId, open, action, detail, onClose}) => {

    const [form] = Form.useForm();
    const tinymce_api_key = process.env.REACT_APP_TINYMCE_API_KEY;
    const [description, setDescription] = useState(null);

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreatePageDetailMutation();
    const [update, {isUpdateLoading}] = useUpdatePageDetailMutation();


    const onFinish = async (data) => {

        try {
            data.page_id = pageId;
            data.description = description;
            let response = null;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: detail.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
            errorAlert(e);
        }
    }

    useEffect(() => {
        if (detail) {
            setDescription(detail.description ? detail.description : null);
        }
    }, [detail]);


    return (
        <Modal title="Detail" closable={false} centered={true} open={open}
               width={800}
               footer={[
                   <ModalFooterWidget key="footer" loading={isLoading || isUpdateLoading}
                                      onCancelClick={() => onClose(false)}
                                      onOkClick={handleOk}/>
               ]}>

            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={detail}>
                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="title" label="Title" rules={[{required: true, message: 'Required!'}]}>
                    <Input placeholder="Title"/>
                </Form.Item>

                <Form.Item label="Description">
                    <Editor
                        value={description}
                        apiKey={tinymce_api_key}
                        init={{
                            height: 300,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist | ',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                        onEditorChange={(content, editor) => {
                            setDescription(content)
                        }}
                    />
                </Form.Item>

                {/*<Form.Item name="description" label="Description" rules={[{required: true, message: 'Required!'}]}>*/}
                {/*    <Input.TextArea placeholder="Description" autoSize={{minRows: 10}}/>*/}
                {/*</Form.Item>*/}

            </Form>

        </Modal>
    );
}

export default DetailAction;
