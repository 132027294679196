import React, {useState, useEffect, useCallback} from 'react'
import {Col, Form, Layout, Row, Table, Typography} from 'antd'
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";


import {
    useListPageQuery,
} from '../../redux/api/services/page';
import EditWidget from "../../widgets/EditWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {convertTableParams} from "../../utils/helper";

const Page = () => {

    const navigate = useNavigate();
    const [pages, setPages] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['name', 'slug'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'ascend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchPage
    } = useListPageQuery(convertTableParams(tableParams));


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EditWidget onClick={() => navigate(`/page/edit/${record.id}`)}/>
                    </Col>
                </Row>
            ),
        },
    ];


    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);


    useEffect(() => {
        if (data) {
            setPages(data.data.pages);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchPage(convertTableParams(tableParams));
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Pages</Typography.Title>
            <Row gutter={[5, 5]} align='middle' justify="space-between">
                <Col span={12} offset={12}>
                    <Form.Item>
                        <SearchWidget onSearch={debouncedOnSearch}/>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={pages}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>

        </Layout.Content>
    )
}

export default Page;
