import React, {useEffect, useState} from "react";
import {Card, Row, Col, Button} from "antd";
import AddWidget from "../../widgets/AddWidget";
import AddressAction from "./action";
import {useListAddressQuery, useDeleteAddressMutation} from "../../redux/api/services/address";
import {confirmAlert, successAlert} from "../../utils/helper";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";


const AddressView = ({id, type}) => {

    const [action, setAction] = useState(null);
    const [address, setAddress] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [contactId, setContactId] = useState(id);
    const [showModel, setShowModel] = useState(false);

    const {data, isLoading, error} = useListAddressQuery({
        id: id,
        filters: "include=country:id,name;state:id,name"
    })
    const [remove, {deleteLoading: loading}] = useDeleteAddressMutation();


    function saveAction(action, address = null) {
        setAddress(address);
        setAction(action);
    }

    function resetAction(c) {
        setShowModel(c);
        setAddress(null);
        setAction(null);
    }

    const deleteAddress = (address) => {

        confirmAlert(
            async () => {
                try {
                    await remove({
                        id: address.contact_id,
                        addressId: address.id
                    });
                    successAlert('Address deleted successfully');
                } catch (e) {
                    console.log(e);
                }

            }
        )
    };


    useEffect(() => {
        if (action)
            setShowModel(true);
        else
            setShowModel(false);
    }, [action]);


    useEffect(() => {
        if (data) {
            setAddresses(data.data.addresses);
        }
    }, [data])


    return (<>

            {type === 'seller' && <div style={{marginBottom: 16}}>
                <AddWidget onClick={() => saveAction('add')} label='Add Address'/>
            </div>
            }

            <Row gutter={[10, 16]}>

                {isLoading && <Col span={12}>
                    <Card loading={isLoading}/>
                </Col>
                }

                {!isLoading && addresses.length === 0 && <Col span={24}>
                    <Card>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <h3>No Address Found</h3>
                            </div>
                            <div>

                            </div>
                        </div>
                    </Card>
                </Col>}

                {addresses.map((address, i) => {
                    return (
                        <Col span={6}>
                            <div className={address.is_default === 1 ? "addressCtr-default" : "addressCtr-common"}
                                 key={address.id}>
                                {address.is_default === 1 &&
                                    <div className="ribbon"><span>Default</span></div>
                                }
                                <h3>Address {i + 1}</h3>
                                <div className="addressCityCtr">
                                    <p>{address.address}</p>
                                    <p>{address?.city && address?.city + ','}</p>
                                    <p>{address?.state?.name && address?.state?.name + ','}</p>
                                    <p>{address?.country?.name && address?.country?.name}</p>
                                    <p>{address.zip_code}</p>
                                </div>
                                {
                                    type === 'seller' && <div className="addressBtns">
                                        <Button onClick={() => saveAction('edit', address)}
                                                icon={<EditOutlined/>}>Edit</Button>
                                        <Button onClick={() => deleteAddress(address)}
                                                icon={<DeleteOutlined/>}>Delete</Button>
                                    </div>
                                }

                            </div>
                        </Col>
                    )
                })}

            </Row>

            {
                action !== null &&
                <AddressAction open={showModel} action={action} id={contactId} address={address}
                               onClose={resetAction}/>
            }

        </>

    )
        ;
}

export default AddressView;
