import React, { useEffect, useState } from "react";
import { Card, Col } from "antd";
import Chart from "react-apexcharts";

import { useSalesReportQuery } from "../../redux/api/services/dashboard";
import DashboardFilterWidget from "../../widgets/DashboardFilterWidget";

const SalesReport = () => {

    const [data, setData] = useState([]);
    const [type, setType] = useState('last7days');
    const { data: reports, isLoading, isFetching, error, refetch } = useSalesReportQuery(`type=${type}`);


    useEffect(() => {
        if (reports) {
            const result = reports.data;
            setData(result);
        }
    }, [reports]);

    useEffect(() => {
        refetch();
    }, [type]);

    return (
        <Col span={24}>
            <Card loading={isLoading || isFetching}
                title={'Sales report'}
                bordered={false}
                extra={[
                    <DashboardFilterWidget key='1' onSearch={
                        (dateType) => {
                            setType(dateType);
                        }} />
                ]}>

                <Chart
                    height={300}
                    options={{
                        chart: {
                            id: "basic-bar",
                            toolbar: {
                                show: false
                            }
                        },
                        xaxis: {
                            categories: data.map((item) => {
                                if (item?.name === 'Total No. of Sales') {
                                    return ['Total No. of', 'Sales']
                                }
                                else if (item?.name === 'Direct Sale Count') {
                                    return ['Direct Sale', 'Count']
                                }
                                else if (item?.name === 'BidSale Count') {
                                    return ['BidSale', 'Count']
                                }
                                else if (item?.name === 'Sales Pending') {
                                    return ['Sales', 'Pending']
                                }
                                return item.name;
                            }),
                            labels: {
                                style: {
                                    fontWeight: 700
                                },
                            }
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: 30
                            }
                        },
                        colors: ['#CDA96D'],
                        toolbar: {
                            show: false,
                        },
                        grid: {
                            show: true,
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: true
                                }
                            },
                        },
                    }}

                    series={[
                        {
                            name: "",
                            data: data.map((item) => {
                                return item.count;
                            })
                        }
                    ]}
                    type="bar"
                    width="100%"
                />

            </Card>
        </Col>

    );
}

export default SalesReport;
