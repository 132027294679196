import React, {useState, useEffect} from "react"

import {useUploadFileMutation} from "../redux/api/services/file"
import {errorAlert} from "../utils/helper"
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons"
import {Modal, Upload} from "antd"

const VideoMultiUpload = ({label = "", className = "", onChange, maxCount, url = []}) => {
    const [fileList, setFileList] = useState([])

    const [loading, setLoading] = useState(false)
    const [previewFile, setPreviewFile] = useState({open: false, title: "", url: "", type: ""})

    const [uploadFile] = useUploadFileMutation()

    const handleUpload = async (options) => {
        const {onSuccess, onError, file} = options

        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("file", file)
            formData.append("type", "video")
            const result = await uploadFile(formData).unwrap()
            const response = result?.data;

            let videoObject = {
                uid: response?.name,
                name: response?.name,
                status: "done",
                url: response?.path,
                is_default: false,
                video: response?.name,
            }

            setFileList((state) => {
                if (state.length === 0) {
                    videoObject.is_default = true;
                }
                return [
                    ...state,
                    videoObject
                ];
            });
            onSuccess("Ok")
            onChange(videoObject, 'upload')
        } catch (e) {
            onError(e)
            errorAlert(e.response ? e : "Invalid file")
        } finally {
            setLoading(false)
        }
    }

    const beforeUpload = (file) => {
        const isVideo = file.type.split("/")[0] === "video"
        console.log(file.type.split("/"))
        if (!isVideo) {
            errorAlert("File type not supported")
        }
        const isLt10M = file.size / 1024 / 1024 < 10
        if (!isLt10M) {
            errorAlert("Video must smaller than 10MB!")
        }
        return isVideo && isLt10M
    }

    const handlePreview = async (file) => {

        console.log(file.type)

        setPreviewFile({
            open: true,
            title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
            url: file.url,
            type: file.type,
        })
    }

    const handleDelete = async (file) => {
        try {
            setFileList((state) => {
                return state.filter((item) => item.name !== file.name)
            })
            onChange(file.name, "delete")
        } catch (e) {
            errorAlert(e)
        }
    }

    const handleCancel = () => {
        setPreviewFile({
            open: false,
            title: "",
            url: "",
            type: "",
        })
    }

    const props = {
        name: "avatar",
        listType: label ? label : "picture-circle",
        className: "avatar-uploader",
        maxCount: maxCount,
        customRequest: handleUpload,
        onPreview: handlePreview,
        onRemove: handleDelete,
        beforeUpload: beforeUpload,
        fileList,
    }

    useEffect(() => {
        if (url?.length >= 1) {
            const array = url?.map((item) => {
                return {
                    uid: item.video,
                    name: item.video,
                    status: "done",
                    url: item.video,
                }
            })
            setFileList(array)
        }
    }, [url])

    return (
        <>
            <Upload {...props} className={className}>
                {fileList.length >= maxCount ? null : (
                    <div>
                        {loading ? (
                            <LoadingOutlined
                                style={{
                                    color: "white",
                                }}
                            />
                        ) : (
                            <UploadOutlined
                                style={{
                                    color: "white",
                                }}
                            />
                        )}
                        <div style={{marginTop: 0, color: "white"}}>
                            {loading ? "Uploading" : "Upload"}
                        </div>
                    </div>
                )}
            </Upload>
            <Modal open={previewFile.open} footer={null} onCancel={handleCancel}>
                <video
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                    controls>
                    <source src={previewFile.url} type={previewFile.type}/>
                </video>
            </Modal>
        </>
    )
}

export default VideoMultiUpload
