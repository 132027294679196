import React, { useState, useEffect } from "react";
import { Card, Col } from "antd";
import Chart from "react-apexcharts";


import { useSellerReportQuery } from "../../redux/api/services/dashboard";
import DashboardFilterWidget from "../../widgets/DashboardFilterWidget";

const SellerReport = () => {

    const [data, setData] = useState([]);
    const [type, setType] = useState('last7days');
    const { data: reports, isLoading, isFetching, error, refetch } = useSellerReportQuery(`type=${type}`);


    useEffect(() => {
        if (reports) {
            const result = reports.data;
            setData(result);
        }
    }, [reports]);

    useEffect(() => {
        refetch();
    }, [type]);


    return (
        <Col span={24}>

            <Card loading={isLoading || isFetching}
                title={'Seller report'}
                bordered={false}
                extra={[
                    <DashboardFilterWidget key='1' onSearch={
                        (dateType) => {
                            setType(dateType);
                        }} />
                ]}>

                <Chart
                    height={300}
                    options={{
                        chart: {
                            id: "basic-bar",
                            toolbar: {
                                show: false
                            }
                        },
                        xaxis: {
                            categories: data.map((item) => {
                                if (item?.name === 'Total No. of Sellers') {
                                    return ['Total No. of', 'Sellers']
                                }
                                return item.name;
                            }),
                            labels: {
                                style: {
                                    fontWeight: 700
                                },
                            }
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: 30
                            }
                        },
                        colors: ['#CDA96D'],
                        grid: {
                            show: true,
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: true
                                }
                            },
                        },
                    }}
                    series={[
                        {
                            name: "",
                            data: data.map((item) => {
                                return item.count;
                            })
                        }
                    ]}
                    type="bar"
                />

            </Card>
        </Col>

    );
}

export default SellerReport;
