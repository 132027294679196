import React, {useState, useEffect, useCallback} from 'react'
import {Col, Layout, Row, Table, Typography} from 'antd'
import {debounce} from "lodash";


import {
    useSellerDetailReportQuery, useDownloadSellerListingMutation
} from '../../redux/api/services/dashboard';
import SearchWidget from "../../widgets/SearchWidget";
import {convertTableParams, errorAlert} from "../../utils/helper";
import AddWidget from "../../widgets/AddWidget";

const SellerListingReport = () => {

    const [sellers, setSellers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        searchOne: null,
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'ascend',
        field: 'id'
    });

    const [downloadSellerListing] = useDownloadSellerListingMutation();

    const {
        data, isLoading, error, isFetching,
        refetch: fetchSellers
    } = useSellerDetailReportQuery(convertTableParams(tableParams));


    const columns = [
        {
            title: 'Seller Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'seller',
            key: 'seller',
        },
        {
            title: 'Total Listing',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (_, record) => {
                return `${parseInt(record?.custom_count) +parseInt(record?.standard_count) + parseInt(record?.deluxe_count) + parseInt(record?.platinum_count)}`
            }
        },
        {
            title: 'Standard Plan',
            dataIndex: 'standard_count',
            key: 'standard_count',
            align: "center",
        },
        {
            title: 'Deluxe Plan',
            dataIndex: 'deluxe_count',
            align: "center",
            key: 'deluxe_count',
        },
        {
            title: 'Platinum Plan',
            dataIndex: 'platinum_count',
            align: "center",
            key: 'platinum_count',
        },
        {
            title: 'Custom Plan',
            dataIndex: 'custom_count',
            align: "center",
            key: 'custom_count',
        },
        {
            title: 'Listing Sold',
            dataIndex: 'sold_count',
            align: "center",
            key: 'sold_count',
        },

    ];

    const downloadCSV = () => {

        let data = {};
        let search = tableParams.searchOne;
        if (search !== null) {
            data.search = search;
        }

        downloadSellerListing(data)
            .unwrap()
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'seller-listing.csv');
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                errorAlert(err?.data?.error)
            })
    }

    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            searchOne: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };

    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);


    useEffect(() => {
        if (data) {
            setSellers(data.data.sellers);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchSellers();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Seller Report</Typography.Title>
            <Row>
                <Col span={6}>
                    <AddWidget onClick={() => downloadCSV()} label='Download CSV'/>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>
            </Row>


            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={sellers}
                        columns={columns} rowKey="id"/>

                </Col>
            </Row>

        </Layout.Content>
    )
}

export default SellerListingReport;
