import React, {useState, useEffect} from 'react'
import {Col, Image, Row, Table, Typography, Layout, Tag} from 'antd'
import {
    useListCategoryDetailQuery,
    useDeleteCategoryDetailMutation,
} from '../../redux/api/services/category-detail';

import {useGetCategoryQuery} from "../../redux/api/services/category";


import EditWidget from "../../widgets/EditWidget";
import DeleteWidget from "../../widgets/DeleteWidget";
import AddWidget from "../../widgets/AddWidget";
import SearchWidget from "../../widgets/SearchWidget";
import {confirmAlert, successAlert, ucFirst} from "../../utils/helper";
import {useParams} from "react-router-dom";
import CategoryDetailAction from "./detail-action";

const CategoryDetail = () => {

    const params = useParams();

    const [details, setDetails] = useState([]);
    const [detail, setDetail] = useState(null);
    const [action, setAction] = useState(null);
    const [showModel, setShowModel] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState([]);
    const [name, setName] = useState(null);

    const filters = 'include=listing:id,name,status&filter[category_id]=' + params.id + '&sortByDesc=id';
    const {data, isLoading, error} = useListCategoryDetailQuery(filters);
    const [remove, {isLoading: loading}] = useDeleteCategoryDetailMutation();

    const {data: category} = useGetCategoryQuery(params.id);


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: ["listing", "name"],
            key: "listing.name",
        },

        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => (
                <Image width={100} height={100}  src={image}/>
            )

        },
        {
            title: 'Status',
            dataIndex: ["listing", "status"],
            key: "listing.status",
            render: (v) => (
                <>{v === "active" ? <Tag color={'green'}>{ucFirst(v)}</Tag> : <Tag color={'red'}>{ucFirst(v)}</Tag>}</>
            )
        },
        {
            title: 'Order',
            dataIndex: 'level',
            key: 'level',
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            render: (_, record) => (
                <Row gutter={[20, 15]} justify="center">
                    <Col>
                        <EditWidget onClick={() => saveAction('edit', record)}/>
                    </Col>
                    <Col>
                        <DeleteWidget loading={loadingDelete[record.id] ?? false}
                                      onClick={() => deleteDetail(record.id)}/>
                    </Col>

                </Row>
            ),
        },
    ];


    const deleteDetail = (id) => {
        confirmAlert(
            async () => {
                setLoadingDelete({...loadingDelete, [id]: true});
                try {
                    await remove(id);
                    setDetails(details.filter(c => c.id !== id));
                    successAlert('Deleted successfully');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingDelete({...loadingDelete, [id]: false});
                }

            }
        )
    };

    function saveAction(action, dom = null) {
        setDetail(dom);
        setAction(action);
    }

    function resetAction(c) {
        setDetail(null);
        setAction(null);
        setShowModel(c);
    }


    const handleSearch = (e) => {
        const value = e.target.value;
        if (value) {
            setDetails(details.filter(c => c.listing.name.toLowerCase().includes(value.toLowerCase())));
        } else {
            setDetails(data.data.details);
        }
    }

    useEffect(() => {
        if (action)
            setShowModel(true);
        else
            setShowModel(false);
    }, [action]);



    useEffect(() => {
        if (data) {
            setDetails(data.data.details);
        }
    }, [data]);

    useEffect(() => {
        if (category) {
            setName(category.data.name);
        }
    } , [category]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>{name}</Typography.Title>

            <Row>
                <Col span={24} >
                    <AddWidget onClick={() => saveAction('add')} label='Add New'/>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL COUNT ({details.length})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={handleSearch}/>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table loading={isLoading} pagination={{hideOnSinglePage: true}} dataSource={details}
                           columns={columns} rowKey="id"/>

                    {action !== null &&
                        <CategoryDetailAction open={showModel} action={action} category_id={params.id} detail={detail} onClose={resetAction}/>}
                </Col>
            </Row>

        </Layout.Content>
    )
}

export default CategoryDetail
