import React, {useState} from "react";
import {Form, Input, Modal, Select} from "antd";

import {successAlert, errorAlert} from "../../../utils/helper";
import ModalFooterWidget from "../../../widgets/ModalFooterWidget";
import {
    useCreateMeasurementMutation,
    useUpdateMeasurementMutation,
} from '../../../redux/api/services/measurement';
import {MeasurementTag} from "../../../utils/enums";
import StatusWidget from "../../../widgets/StatusWidget";


const MeasurementAction = ({open, action, measurement, onClose}) => {

    const [form] = Form.useForm();
    const [status, setStatus] = useState(
        action === 'add' ? true : measurement.status
    );

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreateMeasurementMutation();
    const [update] = useUpdateMeasurementMutation();


    const onFinish = async (data) => {

        try {
            data.status = status;
            let response = null;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: measurement.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
           errorAlert(e);
        }
    }

    if(action === 'add')
        form.setFieldsValue({status: true});


    return (
        <Modal title="Measurement" closable={false} centered={true} open={open}
               footer={[
                   <ModalFooterWidget key="footer" loading={isLoading} onCancelClick={() => onClose(false)}
                                      onOkClick={handleOk}/>
               ]}>

            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={measurement}>

                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="name" label="Name" rules={[{required: true, message: 'Required!'}]}>
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item name="tag" label="Tag" rules={[{required: true, message: 'Required!'}]}>
                    <Select fieldNames={{label: 'text', value: 'value'}} allowClear={true} options={MeasurementTag}/>
                </Form.Item>
                <Form.Item name="status" label="Status" rules={[{required: true, message: 'Required!'}]}>
                    <StatusWidget status={status} onChange={(v) => setStatus(v)}/>
                </Form.Item>

            </Form>

        </Modal>
    );
}

export default MeasurementAction;
