import React from "react";
import {Card, Col, Row, Skeleton} from "antd";
import {useNavigate} from "react-router-dom";
import {formatPrice} from "../../utils/helper";

const TotalCard = ({data, loading}) => {

    const navigate = useNavigate();

    return (
        <Row>
            {
                Object.keys(data).map((key, index) => {
                    return (
                        <Col span={6} key={index} className={'totalCard'} style={{
                            paddingLeft: 20,
                        }}>
                            {
                                loading ? (
                                    <Skeleton.Node active style={{width: '100%'}}>
                                        <div>&nbsp;</div>
                                    </Skeleton.Node>) : (
                                    <Card style={{
                                        cursor: 'pointer',
                                        border: '1px solid #d9d9d9'
                                    }} onClick={() => {
                                        navigate(data[key].link)
                                    }}>
                                        <p>{data[key].label}</p>
                                        <div  className={'cardDiv'}>
                                            {data[key].amount ?  <b>{formatPrice(data[key].total)}</b> : <b>{data[key].total}</b> }
                                        </div>
                                    </Card>
                                )
                            }
                        </Col>
                    )
                })
            }
        </Row>
    );
}

export default TotalCard;
