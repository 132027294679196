import React, {useState, useEffect, useCallback} from 'react'
import {Col, Layout, Row, Table, Typography, Tooltip} from 'antd'
import {debounce} from "lodash";


import {
    useListContactUsQuery
} from '../../redux/api/services/contact-us';


import SearchWidget from "../../widgets/SearchWidget";
import {
    convertDate,
    convertTableParams
} from "../../utils/helper";

const ContactUs = () => {

    const [contactus, setContactus] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableParams, setTableParams] = useState({
        callApi: false,
        search: null,
        searchByOr: ['email', 'name', 'mobile'],
        pagination: {
            current: 1,
            pageSize: 10
        },
        order: 'descend',
        field: 'id'
    });


    const {
        data, isLoading, error, isFetching,
        refetch: fetchContactUs,
    } = useListContactUsQuery(convertTableParams(tableParams));


    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (v) => (
                <span>{convertDate(v)}</span>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            render: (v) => (
                v.length > 50 ? <Tooltip title={v}>
                    {v.substring(0, 50) + "..."}
                </Tooltip> : v
            )
        }
    ];


    const onSearch = (e) => {
        setTableParams({
            ...tableParams,
            callApi: true,
            search: e.target.value,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            callApi: false,
            pagination,
            filters,
            ...sorter,
        });
    };


    const debouncedOnSearch = useCallback(debounce(onSearch, 1000), [tableParams]);


    useEffect(() => {
        if (data) {
            setContactus(data.data.contact_us);
            if (tableParams.pagination.current === 1) {
                setTotalRecords(data.data.total_count ?? 0);
            }
        }
    }, [data]);

    useEffect(() => {
        if (tableParams.callApi)
            fetchContactUs();
    }, [JSON.stringify(tableParams)]);

    return (
        <Layout.Content className='container'>
            <Typography.Title level={3}>Contact Us</Typography.Title>
            <Row>
                <Col span={6}>
                    <div className="totalRecords">
                        TOTAL CONTACT-US COUNT ({totalRecords})
                    </div>
                </Col>
                <Col span={6} offset={12} style={{marginBottom: 10}}>
                    <SearchWidget onSearch={debouncedOnSearch}/>
                </Col>

            </Row>

            <Row>
                <Col span={24}>
                    <Table
                        pagination={{
                            ...tableParams.pagination,
                            total: totalRecords,
                            showSizeChanger: false,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                        loading={isLoading || isFetching} dataSource={contactus}
                        columns={columns} rowKey="id"/>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default ContactUs;
