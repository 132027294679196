import {Modal, message, notification} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import Bad from "../../src/assets/smiley/bad.png"
import Good from "../../src/assets/smiley/good.png"
import Great from "../../src/assets/smiley/great.png"
import Okay from "../../src/assets/smiley/okay.png"
import Terrible from "../../src/assets/smiley/terrible.png"

dayjs.extend(utc);


const {confirm} = Modal;


function confirmAlert(
    callback,
    title = 'Are you sure?',
    message = 'Do you want to delete?',
) {

    confirm({
        title: title ? title : 'Are you sure?',
        icon: <ExclamationCircleOutlined/>,
        content: message,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
            await callback();
        },
        onCancel() {

        }
    });
}

function successAlert(msg) {
    message.success(msg);
}

function errorAlert(msg) {
    if (typeof msg === 'object') {
        const code = msg.status;
        if (code === 400 || code === 404) {
            if (msg.data?.error) {
                msg = msg.data.error;
            } else {
                msg = msg.data.message;
            }
        } else if (msg?.success === false) {
            if (msg?.error) {
                msg = msg.error;
            } else {
                msg = msg.message;
            }
        }

        if (Array.isArray(msg)) {
            msg.forEach((e) => {
                message.error(e);
            });
        } else if (typeof msg === 'object') {
            Object.keys(msg).forEach((key) => {
                message.error(msg[key]);
            });
        } else if (typeof msg === 'string') {
            message.error(msg);
            if (code === 404) {
                setTimeout(() => {
                    window.location.href = '/404';
                }, 500);
            }
        } else {
            message.error('Something went wrong');
        }

    } else {
        message.error(msg);
    }

}

function warning(msg) {
    message.warning(msg);
}

function notify(type, msg) {
    switch (type) {
        case 'success':
            successAlert(msg);
            break;
        case 'error':
            notification['error']({
                message: 'Error',
                description: msg,
            });
            break;
        case 'warning':
            warning(msg);
            break;
        default:
            successAlert(msg);
            break;
    }
}

function ucFirst(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatPrice(price, currency = 'USD') {

    if (price === null || price === undefined) return '-';
    price = Number(price);
    return price.toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
    });
}

function changePhoneFormat(phoneNumber) {
    if (!phoneNumber) return '';
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}


function displayTimeText(time) {
    if (!time) return '-';

    let diff = Math.abs(new Date() - new Date(time));
    let minutes = Math.floor(diff / 1000 / 60);
    let hours = Math.floor(minutes / 60);

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    let isToday = dayjs(time).isSame(today, 'day');
    let isYesterday = dayjs(time).isSame(yesterday, 'day');

    if (minutes === 0) {
        return 'a few seconds ago';
    } else {
        if (hours === 0) {
            return minutes + ' mins ago';
        } else if (hours < 6) {
            return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`
        } else if (isToday) {
            return 'Today' + ' ' + dayjs(time).format('hh:mm A');
        } else if (isYesterday) {
            return 'Yesterday' + ' ' + dayjs(time).format('hh:mm A');
        }
    }
    return convertDateTime(time);
}

function convertTimestampToDayjs(timestamp) {
    if (!timestamp) return null;
    let date = new Date(timestamp);
    return dayjs(date);
}

function convertDayjsToDate(date) {
    if (!date) return null;
    return dayjs(date).format('YYYY-MM-DD');
}


function convertDateTime(timestamp) {

    if (!timestamp) return '-';

    let date = new Date(timestamp);
    date = dayjs(date).format(displayDateTimeFormat);
    return date;
}

function convertDate(timestamp) {

    if (!timestamp) return '-';

    let date = new Date(timestamp);
    date = dayjs(date).format(displayDateFormat);
    return date;
}

function convertDateToUTC(date) {
    return dayjs.utc(date).format();
}

function validityExpiredText(billDate) {

    let date = new Date(billDate);
    let today = new Date();
    let diff = date.getTime() - today.getTime();
    let days = Math.ceil(diff / (1000 * 3600 * 24));

    if (days === 1) {
        return 'Expires tomorrow';
    } else if (days === 0) {
        return 'Expires today';
    } else if (days < 10) {
        return `Expires in ${days} days`;
    }
    return `Expires on ${ucFirst(date.toLocaleString('default', {
        month: 'short',
    }))} ${date.getDate()}, ${date.getFullYear()}`;
}

const lastRangePresetsArray = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
];


const nextRangePresetsArray = [
    {
        label: 'Next 7 Days',
        value: [dayjs(), dayjs().add(7, 'd')],
    },
    {
        label: 'Next 14 Days',
        value: [dayjs(), dayjs().add(14, 'd')],
    },
    {
        label: 'Next 30 Days',
        value: [dayjs(), dayjs().add(30, 'd')],
    },
    {
        label: 'Next 90 Days',
        value: [dayjs(), dayjs().add(90, 'd')],
    },
];


const displayDateFormat = "DD-MM-YYYY";
const displayDateTimeFormat = "DD-MM-YYYY hh:mm A";


const convertTableParams = (data) => {

    let {
        pagination,
        order,
        field,
        filters,
        filtersNot,
        filtersBW,
        search,
        searchOne,
        searchByOr,
        select,
        include,
        withCount
    } = data;

    let queryParams = '';

    if (select) {
        queryParams += `&select=${select}&`;
    }
    if (include) {
        queryParams += `&include=${include}&`;
    }

    if (pagination) {
        let page = pagination.current ?? 1;
        let limit = pagination.pageSize ?? 10;
        queryParams += `page=${page}&limit=${limit}&`;
    }
    if (order && field) {
        if (order === 'ascend')
            queryParams += `sortByAsc=${field}&`;
        else
            queryParams += `sortByDesc=${field}&`;
    }
    if (filters) {
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                if (Array.isArray(filters[key])) {
                    queryParams += `filter[${key}][in]=${filters[key].join(',')}&`;
                } else {
                    queryParams += `filter[${key}]=${filters[key]}&`;
                }
            }
        });
    }

    if (filtersNot) {
        Object.keys(filtersNot).forEach((key) => {
            if (filtersNot[key]) {
                if (Array.isArray(filtersNot[key])) {
                    queryParams += `filter[${key}][in][not]=${filtersNot[key].join(',')}&`;
                } else {
                    queryParams += `filter[${key}][eq][not]=${filtersNot[key]}&`;
                }
            }
        });
    }
    if (filtersBW) {
        Object.keys(filtersBW).forEach((key) => {
            if (filtersBW[key]) {
                if (Array.isArray(filtersBW[key])) {
                    queryParams += `filter[${key}][bt]=${filtersBW[key].join(',')}&`;
                } else {
                    queryParams += `filter[${key}][bt]=${filtersBW[key]}&`;
                }
            }
        });
    }

    if (search) {
        if (searchByOr) {
            if (Array.isArray(searchByOr)) {
                searchByOr.forEach((searchBy) => {
                    queryParams += `searchByOr[${searchBy}]=${search}&`;
                });
            } else {
                queryParams += `searchByOr[${searchByOr}]=${search}&`;
            }
        }
    }
    if (searchOne) {
        queryParams += `search=${searchOne}&`;
    }

    if (withCount) {
        if (Array.isArray(withCount)) {
            withCount = withCount.join(',');
        }
        queryParams += `withCount=${withCount}&`;
    }

    return queryParams.slice(0, -1);
}


const feedbackSmiley = (no) => {

    if (no === 1) {
        return <img src={Bad} alt=""/>
    } else if (no === 2) {
        return <img src={Good} alt=""/>
    } else if (no === 3) {
        return <img src={Great} alt=""/>
    } else if (no === 4) {
        return <img src={Okay} alt="" width={40}/>
    } else if (no === 5) {
        return <img src={Terrible} alt=""/>
    }
}

const checkFileTypes = (file) => {

    let ext = file.split('.').pop();
    let type = 'image';

    let videoExt = [
        'mp4', 'avi', 'flv', 'wmv', 'ogx', 'oga', 'ogv', 'ogg', 'webm', 'mov'
    ];

    if (videoExt.includes(ext)) {
        type = 'video';
    }

    return type;

}

const dimensionsText = (tag) => {
    if (!tag) return '';
    let width = '';
    let height = '';
    if (tag === 'listing') {
        width = 500;
        height = 660;
    } else if (tag === 'profile') {

    } else if (tag === 'style') {
        width = 160;
        height = 160;
    } else if (tag === 'slider') {
        width = 700;
        height = 340;
    } else if (tag === '1') {
        // Ethnic Dresses
        width = 280;
        height = 200;
    } else if (tag === '2') {
        //Handmade Collections
        width = 160;
        height = 260;
    } else if (tag === '3') {
        //Designer of the Month
        width = 500;
        height = 660;
    } else if (tag === 'testimonial') {
        width = 130;
        height = 130;
    } else if (tag === 'banner') {
        width = 800;
        height = 600;
    }
if (width && height)
    return `Dimensions of the Image (${width} x ${height})`;
return '';

}

const onlyNumber = (e) => {
    let regex = new RegExp("^[0-9.]+$");
    let key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
        e.preventDefault();
        return false;
    }
    return true;
}

export {
    onlyNumber,
    dimensionsText,
    confirmAlert,
    successAlert,
    errorAlert,
    warning,
    notify,
    ucFirst,
    formatPrice,
    convertTableParams,
    convertDateTime,
    convertDate,
    convertDateToUTC,
    displayDateFormat,
    displayDateTimeFormat,
    lastRangePresetsArray,
    nextRangePresetsArray,
    validityExpiredText,
    convertTimestampToDayjs,
    convertDayjsToDate,
    displayTimeText,
    changePhoneFormat,
    feedbackSmiley,
    checkFileTypes
}

