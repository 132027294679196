import {api} from "../index";

const path = "masters/sizes";

const SizeService = api.injectEndpoints({
    endpoints: (builder) => ({
        listSize: builder.query({
            query: (filters = '') => `${path}?${filters}`,
            providesTags : ['Size']
        }),
        getSize: builder.query({
            query: (id, filters = '') => `${path}/${id}?${filters}`
        }),
        createSize: builder.mutation({
            query: (data) => ({
                url: `${path}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Size']
        }),
        updateSize: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Size']
        }),
        patchSize: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Size']
        }),
        deleteSize: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Size']
        }),
    }),
});


export const {
    useListSizeQuery,
    useGetSizeQuery,
    useCreateSizeMutation,
    useUpdateSizeMutation,
    usePatchSizeMutation,
    useDeleteSizeMutation,
} = SizeService;



