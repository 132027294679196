import {api} from "../index";

const path = "/orders";

const OrderService = api.injectEndpoints({
    endpoints: (builder) => ({
        listOrder: builder.query({
            query: (filters = '') => `${path}?${filters}`
        }),
        getOrder: builder.query({
            query: ({id, filters = ''}) => `${path}/${id}?${filters}`,
        }),
        updateStatusOrder: builder.mutation({
            query: ({id, data}) => ({
                url: `${path}/${id}`,
                method: 'PATCH',
                body: data,
            })
        }),
        downloadOrderLabel: builder.mutation({
            query: (id) => ({
                url: `${path}/${id}/label`,
                method: 'POST',
                body: {},
                responseHandler: async (res) => {
                    if (res.status === 200) {
                        return await res.blob();
                    } else {
                        return await res.json();
                    }
                },
            })
        }),
    }),
});


export const {
    useListOrderQuery,
    useGetOrderQuery,
    useDownloadOrderLabelMutation,
    useUpdateStatusOrderMutation,
} = OrderService;



