import React, {useState} from "react";
import {Form, Input, Modal, Typography} from "antd";

import {successAlert, errorAlert, dimensionsText} from "../../utils/helper";
import ModalFooterWidget from "../../widgets/ModalFooterWidget";
import {
    useCreateTestimonialMutation,
    useUpdateTestimonialMutation,
} from '../../redux/api/services/testimonial';
import ImageUploadComponent from "../../components/ImageUploadComponent";

const {TextArea} = Input;



const TestimonialAction = ({open, action, testimonial, onClose}) => {

    const [form] = Form.useForm();

    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreateTestimonialMutation();
    const [update] = useUpdateTestimonialMutation();


    const onFinish = async (data) => {

        try {
            let response = null;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: testimonial.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
           errorAlert(e);
        }
    }


    return (
        <Modal title="Testimonial" closable={false} centered={true} open={open}
               footer={[
                   <ModalFooterWidget key="footer" loading={isLoading} onCancelClick={() => onClose(false)}
                                      onOkClick={handleOk}/>
               ]}>

            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={testimonial}>

                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>

                <Form.Item name="image" >
                    <span>
                          <Typography.Text>{dimensionsText('testimonial')}</Typography.Text>
                      </span>
                    <ImageUploadComponent
                        tag={'testimonial'}
                        url={testimonial?.image}
                        label={'picture-card'}
                        onChange={(v) => {
                            form.setFieldValue('image', v);
                        }}/>
                </Form.Item>

                <Form.Item name="name" label="Name" rules={[{required: true, message: 'Required!'}]}>
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item name="description" label="Description" rules={[{required: true, message: 'Required!'}]}>
                    <TextArea
                        placeholder="Description"
                        autoSize={{
                            minRows: 6,
                            maxRows: 10,
                        }}
                    />
                </Form.Item>

            </Form>

        </Modal>
    );
}

export default TestimonialAction;
