import React, {useEffect, useState} from "react";
import {Form, Input, Modal, InputNumber, Skeleton, Select, Typography} from "antd";

import {successAlert, errorAlert, dimensionsText} from "../../utils/helper";
import ModalFooterWidget from "../../widgets/ModalFooterWidget";
import {
    useCreateCategoryDetailMutation,
    useUpdateCategoryDetailMutation,
} from '../../redux/api/services/category-detail';
import {useListListingQuery} from "../../redux/api/services/listing";
import ImageUploadComponent from "../../components/ImageUploadComponent";


const CategoryDetailAction = ({category_id, open, action, detail, onClose}) => {

    const [form] = Form.useForm();
    const [listings, setListings] = useState([]);
    const handleOk = () => {
        form.submit();
    }

    const [create, {isLoading}] = useCreateCategoryDetailMutation();
    const [update] = useUpdateCategoryDetailMutation();
    const {data: listingData, isLoading: listingLoading} = useListListingQuery('select=id,name&filter[status]=active');


    const onFinish = async (data) => {

        try {
            let response = null;
            data.category_id = category_id;
            data.status = 1;
            if (action === 'add')
                response = await create(data).unwrap();
            if (action === 'edit')
                response = await update({id: detail.id, data}).unwrap();
            successAlert(response?.message);
            onClose(true);
        } catch (e) {
            errorAlert(e);
        }
    }


    useEffect(() => {
        if (listingData) {
            setListings(listingData.data.listings);
        }
    }, [listingData]);

    return (
        <Modal title="Category Details" closable={false} centered={true} open={open}
               footer={[
                   <ModalFooterWidget key="footer" loading={isLoading} onCancelClick={() => onClose(false)}
                                      onOkClick={handleOk}/>
               ]}>

            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={detail}>

                <Form.Item name="id" hidden={true}>
                    <Input/>
                </Form.Item>

                <Form.Item name="image">
                     <span>
                          <Typography.Text>{dimensionsText(category_id)}</Typography.Text>
                      </span>
                    <ImageUploadComponent
                        tag={category_id}
                        url={detail?.image}
                        label={'picture-card'}
                        onChange={(v) => {
                            form.setFieldValue('image', v);
                        }}/>
                </Form.Item>

                <Form.Item name="listing_id" label="Listing"
                           rules={[{required: true, message: 'Required!'}]}>
                    {listingLoading ? <Skeleton.Input active={true} style={{width: "100%"}}/>
                        : <Select showSearch
                                  fieldNames={{label: 'name', value: 'id'}} placeholder="Select Listing"
                                  allowClear={true} options={listings} optionFilterProp="name"/>}
                </Form.Item>

                <Form.Item name="level" rules={[{required: true, message: 'Required!'}]} label="Order"
                           style={{width: '100%'}}>
                    <InputNumber controls={false} placeholder="Order" style={{width: '100%'}}/>
                </Form.Item>


            </Form>

        </Modal>
    );
}

export default CategoryDetailAction;
